import * as React from "react";

import { FunctionComponent, useEffect, useRef } from "react";
import ActionModuleNavigation from "./ActionModuleNavigation";
import LanguageMenu from "./LanguageMenu";
import MainMenuHamburger from "./MainMenuHamburger";
import {
  TopHeaderVariant,
  Accommodation,
  Language,
  MainMenuItem,
  PageState,
  Picture,
  ColorScheme,
  ActionLinkItem,
} from "../types";
import ContactNav from "./ContactNav";
import PriorityNavigation from "./PriorityNavigation";
import HeaderLogo from "./HeaderLogo";

const isInHeaderNav = (topHeaderVariant: TopHeaderVariant) =>
  topHeaderVariant === "in-header-nav";

const isBelowHeaderNav = (topHeaderVariant: TopHeaderVariant) =>
  topHeaderVariant === "below-header-nav";

const isHamburgerNav = (topHeaderVariant: TopHeaderVariant) =>
  topHeaderVariant === "hamburger-nav";

interface Props {
  accommodation: Accommodation | undefined;
  actionLinks: ActionLinkItem[];
  activePagePath: string[];
  hasHeaderImage: boolean;
  isPreview: boolean;
  languageId: Language;
  topHeaderVariant: TopHeaderVariant;
  logo: Picture;
  mainPageUrl: string | undefined;
  pages: PageState;
  scheme: ColorScheme;
  topLevelMainMenuItems: MainMenuItem[];
  isStaticHeader: boolean;
  fallbackLanguageId: Language | undefined;
  pageId: string;
  onRef?: (el: HTMLDivElement) => void;
  onIntersectionTriggerRef: (el: HTMLElement) => void;
}

const HeaderBar: FunctionComponent<Props> = ({
  accommodation,
  actionLinks,
  activePagePath,
  hasHeaderImage,
  isPreview,
  languageId,
  topHeaderVariant,
  logo,
  mainPageUrl,
  pages,
  scheme,
  topLevelMainMenuItems,
  isStaticHeader,
  fallbackLanguageId,
  pageId,
  onRef,
  onIntersectionTriggerRef,
}) => {
  const topBlockElRef = useRef<HTMLDivElement>(null);
  const blockCElRef = useRef<HTMLDivElement>(null);

  const handleRef = () => {
    if (!topBlockElRef.current) return;
    (isBelowHeaderNav(topHeaderVariant) || isHamburgerNav(topHeaderVariant)) &&
      onIntersectionTriggerRef(topBlockElRef.current);
    onRef && onRef(topBlockElRef.current);
  };

  const handleBlockCRef = () => {
    blockCElRef.current &&
      isInHeaderNav(topHeaderVariant) &&
      onIntersectionTriggerRef(blockCElRef.current);
  };

  useEffect(() => {
    handleRef();
    handleBlockCRef();
  }, [topHeaderVariant]);

  const languageMenu = (
    <LanguageMenu
      className="LanguageMenu--all-in-header"
      isPreview={isPreview}
      languageId={languageId}
      scheme={scheme}
      pageId={pageId}
      variant="horizontal"
    />
  );

  return (
    <div ref={topBlockElRef} className="HeaderModule__TopBlock">
      <div className="HeaderModule__Language_and_MainMenu">
        {isStaticHeader && languageMenu}
        <MainMenuHamburger
          scheme={scheme}
          isPreview={isPreview}
          pages={pages}
          languageId={languageId}
          topLevelMainMenuItems={topLevelMainMenuItems}
          activePagePath={activePagePath}
          actionLinks={actionLinks}
          fallbackLanguageId={fallbackLanguageId}
        />
      </div>

      <HeaderLogo isPreview={isPreview} logo={logo} mainPageUrl={mainPageUrl} />

      <div className="HeaderModule__BlockF">
        <ActionModuleNavigation
          className="ActionModuleNavigation--block"
          isPreview={isPreview}
          actionLinks={actionLinks}
          scheme={scheme}
        />
        {isStaticHeader && (
          <div className="HeaderModule__BlockC" ref={blockCElRef}>
            {!isHamburgerNav(topHeaderVariant) && languageMenu}
            <ContactNav
              className="HeaderModule__Contact Nav"
              accommodation={accommodation}
              iconsOnly={false}
            />
          </div>
        )}
        {isInHeaderNav(topHeaderVariant) && (
          <div className="HeaderModule_BlockA">
            <PriorityNavigation
              className="MainMenu__Wrapper--in-header-nav MainMenu__Wrapper--horizontal"
              isPreview={isPreview}
              languageId={languageId}
              menuItems={topLevelMainMenuItems}
              pages={pages}
              scheme={scheme}
              showIcons={false}
              topHeaderVariant={topHeaderVariant}
              activePagePath={activePagePath}
              hasHeaderImage={hasHeaderImage}
              absolutePositioning={false}
              fallbackLanguageId={fallbackLanguageId}
            />

            <ActionModuleNavigation
              className="ActionModuleNavigation--in-header-nav"
              isPreview={isPreview}
              actionLinks={actionLinks}
              scheme={scheme}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default HeaderBar;
