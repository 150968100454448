import * as React from "react";
import { FunctionComponent } from "react";
import * as ClassNames from "classnames";
import { ColorScheme, AnchorAttrs, ButtonModuleSettings } from "../types";
import SmartLink from "./SmartLink";
import { style } from "typestyle";

type Styles = Partial<ButtonModuleSettings["global"]>;

interface Props extends Styles {
  className?: string;
  scheme: ColorScheme | undefined;
  anchorAttrs?: AnchorAttrs;
  onClick?: () => void;
  isPreview: boolean;
}

const Button: FunctionComponent<Props> = ({
  className = "",
  layout = "flat",
  type = "primary",
  corner = "square",
  scheme,
  anchorAttrs = {},
  children,
  onClick,
  isPreview,
}) => {
  const colors = scheme && scheme[type];
  const content = children || <>&nbsp;</>;

  const classNames = ClassNames("Button", className, {
    ["Button--" + layout]: true,
    ["Button--" + corner]: true,
    ["Button--" + type]: true,
  });

  const styleClass = colors
    ? style(
        layout === "hollow"
          ? {
              borderColor: colors.background,
              color: colors.background,
            }
          : {
              borderColor: colors.background,
              backgroundColor: colors.background,
              color: colors.text,
            }
      )
    : undefined;

  if (onClick || !anchorAttrs.href) {
    return (
      <button className={ClassNames(classNames, styleClass)} onClick={onClick}>
        {content}
      </button>
    );
  }

  return (
    <SmartLink
      isPreview={isPreview}
      anchorAttrs={{
        ...anchorAttrs,
        className: ClassNames(classNames, styleClass),
        children: content,
      }}
    />
  );
};

export default Button;
