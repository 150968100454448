import * as React from "react";
import { FunctionComponent } from "react";
import { TrustYou, TranslatedPartnerLogo, Language } from "../types";
import Icon from "./Icon";
import LazyImage from "./LazyImage";
import { getTranslations } from "../utils/utils";

interface Props extends TrustYou {
  logo: TranslatedPartnerLogo | undefined;
  languageId: Language;
}

const translations = {
  de: {
    excellent: "Ausgezeichnet",
    reviews: "Bewertungen",
  },
  it: {
    excellent: "Eccellente",
    reviews: "recensioni",
  },
  en: {
    excellent: "Excellent",
    reviews: "reviews",
  },
};

const TrustYou: FunctionComponent<Props> = ({
  logo,
  count,
  value,
  stars,
  languageId,
}) => {
  const i18n = getTranslations(languageId, translations);

  return (
    <section className="TrustYou">
      <div className="TrustYou__Grade">{i18n.excellent}</div>
      <div className="TrustYou__Content">
        <div className="TrustYou__Rating">{value}</div>
        <div className="TrustYou__Stars">
          {stars.map((star, index) => (
            <Icon key={index} glyph={star} />
          ))}
        </div>
        <div className="TrustYou__Ratings">
          {count} {i18n.reviews}
        </div>
        {logo && (
          <div className="TrustYou__Logo">
            <LazyImage src={logo.translation.file} />
          </div>
        )}
      </div>
    </section>
  );
};

export default TrustYou;
