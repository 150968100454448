import * as React from "react";
import { useEffect } from "react";
import {
  StoreState,
  Accommodation,
  FooterLayoutVariant,
  FooterModuleSettings,
  ColorScheme,
  Language,
  LegalNavId,
  PageState,
  BaseModuleProps,
  TranslatedPartnerLogo,
  Picture,
} from "../../types";
import { connect, ConnectedProps, MapStateToProps } from "react-redux";
import TrustYou from "../TrustYou";
import {
  getActiveColorScheme,
  getTranslations,
  getTranslatedPage,
  isLegalNavId,
} from "../../utils/utils";
import { getAccommodation } from "../../actions/Accommodation";
import InlineTerms from "../InlineTerms";
import LegalNavLink from "../LegalNavLink";
import JsonLd from "../JsonLd";
import { getPrimaryDomain, getActiveSite } from "../../selectors/sites";
import PartnerLogos from "../PartnerLogos";
import {
  getTranslatedPartnerLogos,
  getTranslatedPartner,
} from "../../selectors/partnerLogos";
import { getPictureById } from "../../selectors/pictures";
import RichEditorWrapper from "../RichEditorWrapper";

interface Props extends BaseModuleProps<FooterModuleSettings> {
  layoutVariant?: FooterLayoutVariant;
}

type I18n = typeof translations.en;

interface StateProps {
  accommodation: Accommodation | undefined;
  mainDescription: string | null;
  layoutVariant: FooterLayoutVariant;
  scheme: ColorScheme;
  legalNav: LegalNavId[];
  i18n: I18n;
  vatId?: string;
  domain: string;
  trustYouLogo: TranslatedPartnerLogo | undefined;
  partnerLogoCategories: TranslatedPartnerLogo[][];
  logo: Picture;
}

type ReduxProps = ConnectedProps<typeof connector>;

type AllProps = Props & ReduxProps;

const translations = {
  de: {
    vat: "MwSt.-Nr.",
    region: "Südtirol",
  },
  it: {
    vat: "Part. IVA",
    region: "Alto Adige",
  },
  en: {
    vat: "VAT-No.",
    region: "South Tyrol",
  },
  fr: {
    vat: "numéro de TVA",
    region: "Sud-Tyrol",
  },
};

const _isBig = (layoutVariant: FooterLayoutVariant) => layoutVariant === "big";

const FooterModule: React.FunctionComponent<AllProps> = ({
  accommodation,
  translatedModule,
  translatedModule: {
    siteId,
    translation: {
      languageId,
      settings: { title, description },
    },
    settings,
  },
  layoutVariant,
  scheme,
  legalNav,
  i18n,
  vatId,
  isPreview,
  queries,
  domain,
  mainDescription,
  trustYouLogo,
  partnerLogoCategories,
  logo,
  getAccommodation,
  pageId,
}) => {
  useEffect(() => {
    getAccommodation(siteId, languageId);
  }, [siteId, languageId]);

  const hotelPhoto =
    accommodation && accommodation.pictures[0]
      ? accommodation.pictures[0].url
      : null;

  const isBig = _isBig(layoutVariant);

  return (
    <footer
      className={`FooterModule FooterModule--${layoutVariant} Module`}
      style={{
        background: scheme.main.background,
        color: scheme.main.text,
      }}
      id={translatedModule.id}
    >
      <div className="Module__Wrapper">
        {isBig && (
          <div
            className="FooterModule__Top"
            style={{
              borderColor: scheme.primary.background,
            }}
          >
            <PartnerLogos
              partnerLogos={partnerLogoCategories[0]}
              logoSize={settings.logoSize}
            />
          </div>
        )}
        <div
          className="FooterModule__Main"
          style={{
            borderColor: scheme.primary.background,
          }}
        >
          <section className="FooterModule__Contact FooterModule__Section">
            {title && (
              <h4 className={`TextAlign--${settings.textAlign.title}`}>
                {title}
              </h4>
            )}
            {accommodation && (
              <div className={`TextAlign--${settings.textAlign.description}`}>
                {!description && (
                  <div className="RichTextCopySource">
                    {accommodation.name}
                    <br />
                    {accommodation.street}
                    <br />
                    {accommodation.zip} {accommodation.city}
                    <br />
                    Tel. {accommodation.phone}
                    <br />
                    <a href={"mailto:" + accommodation.email}>
                      {accommodation.email}
                    </a>
                  </div>
                )}

                <RichEditorWrapper
                  pageId={pageId}
                  languageId={languageId}
                  module={translatedModule}
                  isPreview={isPreview}
                  scheme={undefined}
                  maxCharacters={250}
                  className="FooterModule__TextContent"
                />

                <JsonLd
                  data={{
                    "@type": "Hotel",
                    description: mainDescription,
                    name: accommodation.name,
                    address: {
                      "@type": "PostalAddress",
                      addressCountry: "IT",
                      addressLocality: accommodation.city,
                      addressRegion: i18n.region,
                      postalCode: accommodation.zip,
                      streetAddress: accommodation.street,
                    },
                    logo: logo.url,
                    url: `https://${domain}/`,
                    photo: hotelPhoto,
                    image: hotelPhoto,
                    aggregateRating: accommodation.trustYou
                      ? {
                          "@type": "AggregateRating",
                          ratingCount: accommodation.trustYou.count,
                          ratingValue: accommodation.trustYou.value,
                          bestRating: 5,
                        }
                      : null,
                    telephone: accommodation.phone,
                    email: accommodation.email,
                    faxNumber: accommodation.fax,
                    vatID: vatId,
                    starRating: {
                      "@type": "Rating",
                      ratingValue: accommodation.stars,
                    },
                    checkInTime: accommodation.checkIn.from,
                    checkOutTime: accommodation.checkOut.to,
                    petsAllowed:
                      (accommodation.featureMap & 32768) +
                        (accommodation.featureMap & 33554432) >
                      0,
                    geo: {
                      "@type": "GeoCoordinates",
                      latitude: accommodation.latitude,
                      longitude: accommodation.longitude,
                    },
                    priceRange: accommodation.priceRange,
                    currenciesAccepted: "EUR",
                    amenityFeature: accommodation.features.map((feature) => ({
                      "@type": "LocationFeatureSpecification",
                      name: feature.title,
                      value: true,
                    })),
                  }}
                />
              </div>
            )}
          </section>
          {!isBig && (
            <PartnerLogos
              partnerLogos={partnerLogoCategories[0]}
              logoSize={settings.logoSize}
              className="PartnerLogos--compact-last"
            />
          )}
          {isBig && (
            <PartnerLogos
              partnerLogos={partnerLogoCategories[1]}
              logoSize={settings.logoSize}
              className="PartnerLogos--compact-last"
            />
          )}
          {settings.showTrustYou && accommodation && accommodation.trustYou && (
            <div className="FooterModule__Widgets">
              <TrustYou
                {...accommodation.trustYou}
                logo={trustYouLogo}
                languageId={languageId}
              />
            </div>
          )}
        </div>
        <div className="FooterModule__Sub">
          {!isBig && (
            <PartnerLogos
              partnerLogos={partnerLogoCategories[1]}
              logoSize={settings.logoSize}
              className="PartnerLogos--compact-first"
            />
          )}
          <ul className="FooterModule__Nav Nav">
            {vatId && (
              <li>
                {i18n.vat} {vatId}
              </li>
            )}
            {legalNav.map((id) => (
              <li key={id}>
                <LegalNavLink
                  legalNavId={id}
                  languageId={languageId}
                  isPreview={isPreview}
                />
              </li>
            ))}

            <li className="FooterModule__Hgv">
              Website by{" "}
              <a href="http://www.hgv.it/" target="_blank" rel="noopener">
                HGV
              </a>
            </li>
          </ul>
        </div>
        <InlineTerms
          siteId={siteId}
          languageId={languageId}
          colorScheme={scheme}
          isPreview={isPreview}
          isLayoutPreview={false}
          queries={queries}
          isActive={false}
          isFirstOnPage={false}
        />
      </div>
    </footer>
  );
};

const getMainPageDescription = (
  pages: PageState,
  languageId: Language
): string | null => {
  try {
    const { description } = getTranslatedPage(
      pages,
      pages.byParentId["null"][0],
      languageId
    ).translation;
    return description;
  } catch (error) {
    // This means the main page is not translated in
    // this language
    return null;
  }
};

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  state,
  {
    layoutVariant,
    translatedModule: {
      colorSchemeId,
      translation: { languageId },
      settings,
    },
  }
): StateProps => {
  const {
    pages,
    mediaLibrary: { logoId, pictures },
  } = state;
  const site = getActiveSite(state.sites);
  const trustYou = getTranslatedPartner(state, {
    partnerId: "trust-you",
    includeLogos: true,
    languageId,
  });
  const i18n: I18n = getTranslations(languageId, translations);
  const legalNav: LegalNavId[] = pages.systemPages
    .map((pageId) => pages.byId[pageId].alias)
    .filter(isLegalNavId);
  const description = getMainPageDescription(pages, languageId);

  return {
    logo: getPictureById(pictures, logoId, { height: 120 }),
    partnerLogoCategories: settings.logoCategories.map((logoIds) =>
      getTranslatedPartnerLogos(state, { logoIds, languageId })
    ),
    accommodation: state.accommodation[languageId],
    mainDescription: description,
    vatId: site.vatId || undefined,
    domain: getPrimaryDomain(site),
    layoutVariant: layoutVariant || settings.layoutVariant,
    i18n,
    scheme: getActiveColorScheme(state.colorSchemes, site, { colorSchemeId }),
    legalNav,
    trustYouLogo: trustYou?.logos[0],
  };
};

const mapDispatchToProps = {
  getAccommodation,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(FooterModule);
