import * as React from "react";
import { FunctionComponent } from "react";
import {
  AspectRatio,
  CroppingRect,
  Picture,
  ImageSettingsGlobalCropOnly,
  LazyImageProps,
  PictureQueryParams,
} from "../types";
import * as ClassNames from "classnames";
import { appendQueryToUrl, aspectRatioContainerClasses } from "../utils/utils";
import LazyImage from "./LazyImage";
import { isPlaceholder } from "../selectors/pictures";

interface Props {
  picture: Picture;
  aspectRatio: AspectRatio;
  width: number;
  imageSettings: ImageSettingsGlobalCropOnly | undefined;
  lazyLoad?: boolean;
  sizes: string;
}

const parseCropValue = (value: number | undefined, valueToIgnore: 1 | 0) =>
  value === valueToIgnore || value === undefined
    ? undefined
    : // Round to 4 decimals.
      // Note: Number.EPSILON is undefined in IE11, therefore the fallback to 0
      Math.round((value + (Number.EPSILON ?? 0)) * 10_000) / 10_000;

const getImageUrlParams = ({
  width,
  aspectRatio,
  croppingRect,
}: {
  width: number;
  aspectRatio: AspectRatio;
  croppingRect: CroppingRect | undefined;
}): PictureQueryParams => ({
  w: Math.round(width),
  h: Math.round(width * aspectRatio),
  m: 4,
  cropW: parseCropValue(croppingRect?.width, 1),
  cropH: parseCropValue(croppingRect?.height, 1),
  cropX: parseCropValue(croppingRect?.x, 0),
  cropY: parseCropValue(croppingRect?.y, 0),
});

const ImageWithCrop: FunctionComponent<Props> = ({
  aspectRatio,
  width,
  imageSettings,
  picture: { title, url, category },
  sizes,
  lazyLoad = true,
}) => {
  const isImagePlaceholder = isPlaceholder({ category });
  const croppingRect = imageSettings?.crop.croppingRect;
  const queryParams = getImageUrlParams({ width, aspectRatio, croppingRect });
  const src = appendQueryToUrl(url, queryParams);

  const srcSet = [320, 480, 800, 1280, 1920]
    .map((width) => {
      const queryParams = getImageUrlParams({
        width,
        aspectRatio,
        croppingRect,
      });
      return `${appendQueryToUrl(url, queryParams)} ${width}w`;
    })
    .join(",\n");

  const imageProps: LazyImageProps = {
    className: "FullImage AspectRatioContainer__Content",
    src,
    alt: title,
    sizes: isImagePlaceholder ? undefined : sizes,
    srcSet: isImagePlaceholder ? undefined : srcSet,
  };

  return (
    <div
      className={ClassNames(
        aspectRatioContainerClasses(aspectRatio),
        "FullImageContainer",
        {
          Placeholder: isImagePlaceholder,
        }
      )}
    >
      {!lazyLoad && <img {...imageProps} />}
      {lazyLoad && <LazyImage {...imageProps} />}
    </div>
  );
};

export default ImageWithCrop;
