import { PostLoginSuccessAction } from "./Login";
import { ThunkAction } from "../types";
import { copiedModuleClipboard } from "../utils/utils";

export type Action = PostLoginSuccessAction | { type: "LOGOUT_USER" };

export const logoutUser = (): ThunkAction<void> => (dispatch) => {
  copiedModuleClipboard.clear();
  dispatch({
    type: "LOGOUT_USER",
  });
};
