import * as React from "react";
import { FunctionComponent, RefObject } from "react";
import {
  TranslatedModule,
  ImagesModuleSettings,
  ContainerQueries,
  ColorScheme,
  Language,
  PageState,
  Accommodation,
  Picture,
  MainMenuItem,
  StoreState,
  ActionLinkItem,
  HeaderModuleSettings,
} from "../types";
import { ConnectedProps, connect, MapStateToProps } from "react-redux";
import { getActiveSite } from "../selectors/sites";
import {
  getFallbackLanguage,
  getTopLevelMainMenu,
  getActivePagePath,
  getImageModuleWithFixedAspectRatio,
} from "../utils/utils";
import { getPictureById } from "../selectors/pictures";
import ImagesModule from "./Modules/ImagesModule";
import HeaderLogo from "./HeaderLogo";
import LeftOverlayNav from "./LeftOverlayNav";
import * as ClassNames from "classnames";
import HeaderActionLinks from "./HeaderActionLinks";
import LanguageMenu from "./LanguageMenu";

interface Props {
  translatedModule: TranslatedModule<HeaderModuleSettings>;
  imagesModule: TranslatedModule<ImagesModuleSettings>;
  queries: ContainerQueries;
  scheme: ColorScheme;
  isPreview: boolean;
  pageId: string;
  mainPageUrl: string | undefined;
  languageId: Language;
  actionLinks: ActionLinkItem[];
  intersectionTriggerRef: RefObject<HTMLDivElement>;
}

type ReduxProps = ConnectedProps<typeof connector>;

interface StateProps {
  pages: PageState;
  accommodation: Accommodation | undefined;
  logo: Picture;
  activePagePath: string[];
  fallbackLanguageId: Language | undefined;
  menuItems: MainMenuItem[];
}

const HeaderWithLeftOverlayNav: FunctionComponent<Props & ReduxProps> = ({
  translatedModule: {
    settings: { leftOverlayNavIcons, showMenuSeparators },
  },
  imagesModule,
  queries,
  scheme,
  isPreview,
  pageId,
  mainPageUrl,
  languageId,
  pages,
  accommodation,
  logo,
  activePagePath,
  fallbackLanguageId,
  menuItems,
  actionLinks,
  intersectionTriggerRef,
}) => (
  <div
    className={ClassNames("HeaderModule__Inner", "HeaderModule__Inner--wide", {
      "HeaderModule__Inner--full-height": !isPreview,
    })}
  >
    <ImagesModule
      translatedModule={getImageModuleWithFixedAspectRatio(imagesModule)}
      isPreview={isPreview}
      isLayoutPreview={false}
      pageId={pageId}
      queries={queries}
      isActive={false}
      isInsideHeader={true}
      isFirstOnPage={false}
      parentScheme={scheme}
      activeModuleId={undefined}
      fullHeight={!isPreview}
      showArrows={true}
    />

    <div
      ref={intersectionTriggerRef}
      className={ClassNames("LeftHeaderOverlay", {
        "LeftHeaderOverlay--show-menu-separators": showMenuSeparators,
      })}
    >
      <LeftOverlayNav
        isPreview={isPreview}
        languageId={languageId}
        pages={pages}
        activePagePath={activePagePath}
        fallbackLanguageId={fallbackLanguageId}
        menuItems={menuItems}
        iconType={leftOverlayNavIcons}
      />

      <div className="LeftHeaderOverlay__Box">
        <HeaderLogo
          isPreview={isPreview}
          logo={logo}
          mainPageUrl={mainPageUrl}
        />
        <div className="FullHeaderOverlay__Box LeftHeaderOverlay__BoxInfo">
          <HeaderActionLinks
            accommodation={accommodation}
            actionLinks={actionLinks}
            isPreview={isPreview}
            languageId={languageId}
          />

          <LanguageMenu
            isPreview={isPreview}
            languageId={languageId}
            scheme={undefined}
            pageId={pageId}
            variant="vertical"
          />
        </div>
      </div>
    </div>
  </div>
);

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { pages, sites, accommodation, mediaLibrary: { pictures, logoId } },
  { languageId, pageId, isPreview }
) => {
  const site = getActiveSite(sites);
  const fallbackLanguageId = getFallbackLanguage(site, languageId);
  return {
    menuItems: getTopLevelMainMenu({
      pages,
      languageId,
      isPreview,
      fallbackLanguageId,
    }),
    pages,
    accommodation: accommodation[languageId],
    logo: getPictureById(pictures, logoId, { height: 100 }),
    activePagePath: getActivePagePath(pageId, pages.byParentId),
    fallbackLanguageId,
  };
};

const connector = connect(mapStateToProps);

export default connector(HeaderWithLeftOverlayNav);
