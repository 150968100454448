import * as React from "react";
import { Component } from "react";
import {
  ColorScheme,
  PageState,
  Language,
  MainMenuItem,
  ActionLinkItem,
} from "../types";
import HamburgerMenu from "./HamburgerMenu";
import { style } from "typestyle";
import * as ClassNames from "classnames";

interface Props {
  scheme: ColorScheme;
  isPreview: boolean;
  pages: PageState;
  languageId: Language;
  topLevelMainMenuItems: MainMenuItem[];
  activePagePath: string[];
  actionLinks: ActionLinkItem[];
  fallbackLanguageId: Language | undefined;
}

interface State {
  isOpen: boolean;
}

class MainMenuHamburger extends Component<Props, State> {
  render() {
    const {
      scheme,
      isPreview,
      pages,
      languageId,
      topLevelMainMenuItems,
      activePagePath,
      fallbackLanguageId,
    } = this.props;

    return (
      <div
        className={ClassNames(
          "HeaderModule__MainMenuHamburger",
          style({ background: scheme.main.background })
        )}
      >
        <HamburgerMenu
          activePagePath={activePagePath}
          isPreview={isPreview}
          languageId={languageId}
          pages={pages}
          scheme={scheme}
          topLevelMainMenuItems={topLevelMainMenuItems}
          fallbackLanguageId={fallbackLanguageId}
        />
      </div>
    );
  }
}

export default MainMenuHamburger;
