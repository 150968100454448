import * as React from "react";
import { FunctionComponent } from "react";
import {
  TranslatedModule,
  ImagesModuleSettings,
  ContainerQueries,
  ColorScheme,
  Picture,
  TranslatedPartnerLogo,
  StoreState,
  Language,
} from "../types";
import ImagesModule from "./Modules/ImagesModule";
import {
  getImageModuleWithFixedAspectRatio,
  getMainPageUrl,
} from "../utils/utils";
import { connect, ConnectedProps, MapStateToProps } from "react-redux";
import { getPictureById } from "../selectors/pictures";
import HeaderLogo from "./HeaderLogo";
import * as ClassNames from "classnames";
import { getSouthTyrolLogo } from "../selectors/partnerLogos";
import PartnerLogo from "./PartnerLogo";

interface Props {
  imagesModule: TranslatedModule<ImagesModuleSettings>;
  pageId: string;
  queries: ContainerQueries;
  scheme: ColorScheme;
  isPreview: boolean;
  languageId: Language;
  showSouthTyrolLogo: boolean;
}

interface StateProps {
  logo: Picture;
  southTyrolLogo: TranslatedPartnerLogo | undefined;
  mainPageUrl: string | undefined;
}

type ReduxProps = ConnectedProps<typeof connector>;

const FullHeaderWithLogo: FunctionComponent<Props & ReduxProps> = ({
  imagesModule,
  isPreview,
  pageId,
  queries,
  scheme,
  logo,
  mainPageUrl,
  southTyrolLogo,
}) => (
  <div
    className={ClassNames("HeaderModule__Inner", "HeaderModule__Inner--wide", {
      "HeaderModule__Inner--full-height": !isPreview,
    })}
  >
    <div
      className={ClassNames("HeaderModule__LogosWrapper", {
        "HeaderModule__LogosWrapper--with-partner-logo": !!southTyrolLogo,
      })}
    >
      <HeaderLogo logo={logo} mainPageUrl={mainPageUrl} isPreview={isPreview} />

      {southTyrolLogo && (
        <div className="HeaderModule__LogosWrapperPartner">
          <PartnerLogo partnerLogo={southTyrolLogo} size="big" />
        </div>
      )}
    </div>

    <ImagesModule
      translatedModule={getImageModuleWithFixedAspectRatio(imagesModule)}
      isPreview={isPreview}
      isLayoutPreview={false}
      pageId={pageId}
      queries={queries}
      isActive={false}
      isInsideHeader={true}
      isFirstOnPage={false}
      parentScheme={scheme}
      activeModuleId={undefined}
      fullHeight={!isPreview}
    />
  </div>
);

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  state,
  { isPreview, languageId, showSouthTyrolLogo }
): StateProps => {
  const {
    mediaLibrary: { logoId, pictures },
  } = state;
  return {
    logo: getPictureById(pictures, logoId, { height: 90 }),
    southTyrolLogo: showSouthTyrolLogo
      ? getSouthTyrolLogo(state, languageId)
      : undefined,
    mainPageUrl: getMainPageUrl(state.pages, languageId, isPreview),
  };
};

const connector = connect(mapStateToProps);

export default connector(FullHeaderWithLogo);
