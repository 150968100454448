import * as React from "react";
import { FunctionComponent } from "react";
import { AnchorAttrs, Language } from "../types";
import SmartLink from "./SmartLink";
import { getTranslations } from "../utils/utils";

interface Props {
  links: AnchorAttrs[];
  isPreview: boolean;
  languageId: Language;
}

const translations = {
  de: {
    location: "Sie sind hier:",
  },
  it: {
    location: "Voi siete qui:",
  },
  en: {
    location: "You are here:",
  },
  fr: {
    location: "Vous êtes ici:",
  },
};

const Breadcrumb: FunctionComponent<Props> = ({
  links,
  isPreview,
  languageId,
}) => {
  const i18n = getTranslations(languageId, translations);

  return (
    <nav aria-label="breadcrumb" className="Breadcrumb">
      <div className="Module__Wrapper">
        {i18n.location}
        <ol className="Nav">
          {links.map((link, index) => {
            const isLast = index === links.length - 1;

            return isLast ? (
              <li aria-current="page" key={index}>
                {link.children}
              </li>
            ) : (
              <li key={index}>
                <SmartLink anchorAttrs={link} isPreview={isPreview} />
              </li>
            );
          })}
        </ol>
      </div>
    </nav>
  );
};

export default Breadcrumb;
