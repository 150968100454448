import * as React from "react";
import { FunctionComponent, MouseEvent } from "react";
import { ColorScheme, AspectRatio } from "../types";
import * as ClassNames from "classnames";
import SliderArrow from "./SliderArrow";

interface Props {
  scheme: ColorScheme;
  slidesPerView: number;
  imagesAspectRatio: AspectRatio;
  type: "prev" | "next";
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
}

const MultiBoxSliderArrow: FunctionComponent<Props> = ({
  slidesPerView,
  scheme,
  type,
  onClick,
}) => {
  return (
    <div
      className={ClassNames(
        `MultiBox__NavigationWrapper--${slidesPerView}`,
        `MultiBox__NavigationWrapper--${type}`
      )}
    >
      <SliderArrow
        className={`MultiBox__Navigation MultiBox__Navigation--${type}`}
        scheme={scheme}
        onClick={onClick}
        direction={type === "prev" ? "left" : "right"}
      />
    </div>
  );
};

export default MultiBoxSliderArrow;
