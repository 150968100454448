import { AllActions } from "../actions";
import { StoreState } from "../types";

const reducer = (
  state: StoreState,
  action: AllActions,
  initialState: StoreState
): StoreState => {
  switch (action.type) {
    case "RESET_STORE":
      return initialState;

    default:
      return state;
  }
};

export default reducer;
