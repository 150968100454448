import { Accommodation, Language, ThunkAction } from "../types";
import { fetch } from "../utils/utils";
import { showAlert } from "./Alerts";

export type Action =
  | {
      type: "GET_ACCOMMODATION_START";
      languageId: Language;
    }
  | {
      type: "GET_ACCOMMODATION_SUCCESS";
      languageId: Language;
      accommodation: Accommodation;
    }
  | { type: "GET_ACCOMMODATION_ERROR"; error: Error };

export const getAccommodation = (
  siteId: string,
  languageId: Language
): ThunkAction<Promise<void>> => async (dispatch, getState) => {
  const loadState = getState().loadStates.accommodation[languageId];
  if (loadState !== "unloaded" && loadState !== undefined) {
    return Promise.resolve();
  }
  dispatch(getAccommodationStart(languageId));
  try {
    const { data } = await fetch(dispatch, languageId).get<Accommodation>(
      `sites/${siteId}/accommodation`
    );
    dispatch(getAccommodationSuccess(languageId, data));
  } catch (error) {
    dispatch(
      showAlert("Beim Laden der Betriebsdaten ist ein Fehler aufgetreten!")
    );
    throw error;
  }
};

const getAccommodationStart = (languageId: Language): Action => ({
  type: "GET_ACCOMMODATION_START",
  languageId,
});

export const getAccommodationSuccess = (
  languageId: Language,
  accommodation: Accommodation
): Action => ({
  type: "GET_ACCOMMODATION_SUCCESS",
  languageId,
  accommodation,
});
