import { FunctionComponent, useRef, useState, useEffect } from "react";
import * as React from "react";
import { MapStateToProps, connect, ConnectedProps } from "react-redux";
import {
  StoreState,
  ColorScheme,
  LoadStatus,
  BaseModuleProps,
  VideoModuleSettings,
  WindowState,
} from "../../types";
import { getActiveSite, getPrimaryDomain } from "../../selectors/sites";
import {
  getActiveColorScheme,
  getYouTubeVideoId,
  convertTimeToSeconds,
} from "../../utils/utils";
import { injectScript } from "../../actions/LoadStates";
import ModuleWithHeadings from "../ModuleWithHeadings";
import * as ClassNames from "classnames";
import ModuleHeadings from "../ModuleHeadings";
import LazyloadWrapper from "../LazyloadWrapper";
import UsercentricsCMPWrapper from "../UsercentricsCMPWrapper";
import * as deepEqual from "fast-deep-equal";

const youTubeScriptUrl = "https://www.youtube.com/iframe_api";

interface Props extends BaseModuleProps<VideoModuleSettings> {}
interface StateProps {
  scheme: ColorScheme;
  loadStatus: LoadStatus;
  domain: string;
}
type ReduxProps = ConnectedProps<typeof connector>;

type YouTubeSettings = Omit<VideoModuleSettings["global"], "textAlign">;

const VideoModule: FunctionComponent<Props & ReduxProps> = ({
  translatedModule: {
    id,
    settings,
    settings: { textAlign, ...youTubeSettings },
    translation: {
      languageId,
      settings: { title, subtitle },
    },
  },
  scheme,
  isActive,
  isPreview,
  isFirstOnPage,
  injectScript,
  loadStatus,
  domain,
}) => {
  const iFrameRef = useRef<HTMLDivElement>(null);
  const playerRef = useRef<YT.Player>();
  const playerReadyRef = useRef(false);
  const youTubeSettingsRef = useRef<YouTubeSettings>(youTubeSettings);
  youTubeSettingsRef.current = youTubeSettings;
  const prevYouTubeSettingsRef = useRef<YouTubeSettings>(youTubeSettings);

  const [isLazyloaded, setIsLazyloaded] = useState(false);
  const [isUsercentricsAccepted, setIsUsercentricsAccepted] = useState(
    isPreview
  );

  /**
   * Play the video if all prerequisites are met
   */
  const attemptPlayVideo = () => {
    const { mute, autoPlay } = youTubeSettingsRef.current;
    const shouldPlay = playerReadyRef.current && autoPlay;

    if (!shouldPlay) return;

    if (playerRef.current) {
      mute && playerRef.current.mute();
      playerRef.current.playVideo();
    }

    // If the video settings change, the player won’t be ready next time.
    // Therefore this needs to be set to false after playing
    playerReadyRef.current = false;
  };

  const initVideo = () => {
    const windowState = (window as unknown) as WindowState;

    if (!iFrameRef.current) return;
    if (!windowState.YT || !windowState.YT.Player) return;

    const {
      videoUrl,
      startAt,
      showInfo,
      controls,
    } = youTubeSettingsRef.current;

    const videoId = getYouTubeVideoId(videoUrl);

    if (!videoId) return;

    playerRef.current = new windowState.YT.Player(iFrameRef.current, {
      videoId,
      height: 390,
      width: 640,
      playerVars: {
        start: convertTimeToSeconds(startAt),

        modestbranding: 1,
        showinfo: +showInfo,
        controls: +controls,
        rel: 0,
        iv_load_policy: 3,
        // TODO: still throws an error about invalid origin
        origin: `https://${domain}`,
      },
      events: {
        onReady: () => {
          playerReadyRef.current = true;
          attemptPlayVideo();
        },
      },
    });
  };

  // Youtube API takes some time after script loading to be available
  const isApiReady = () => {
    const windowState = (window as unknown) as WindowState;

    const interval = setInterval(() => {
      if (windowState.YT && windowState.YT.Player) {
        initVideo();
        clearInterval(interval);
      }
    }, 100);
  };

  const loadYouTube = () => {
    injectScript(youTubeScriptUrl);
    loadStatus === "loaded" && isApiReady();
  };

  useEffect(() => {
    isPreview && isActive && loadYouTube();
  }, [isPreview, isActive]);

  useEffect(() => {
    !isPreview &&
      !isActive &&
      isUsercentricsAccepted &&
      isLazyloaded &&
      loadYouTube();
  }, [isPreview, isActive, isLazyloaded, isUsercentricsAccepted]);

  // On unmount
  useEffect(
    () => () => {
      playerRef.current?.destroy();
    },
    []
  );

  useEffect(() => {
    loadStatus === "loaded" && isApiReady();
  }, [loadStatus]);

  useEffect(() => {
    if (!deepEqual(youTubeSettings, prevYouTubeSettingsRef.current)) {
      playerRef.current?.destroy();
      initVideo();
    }

    prevYouTubeSettingsRef.current = youTubeSettings;
  }, [youTubeSettings]);

  return (
    <ModuleWithHeadings
      title={title}
      subtitle={subtitle}
      id={id}
      className="VideoModule"
      style={{ backgroundColor: scheme.main.separator }}
    >
      <div
        className={ClassNames("VideoModule__Wrapper Module__Wrapper", {
          "VideoModule__Wrapper--small": settings.size === "small",
          "VideoModule__Wrapper--full-width": settings.size === "fullWidth",
          "VideoModule__Wrapper--no-click": !isActive && isPreview,
        })}
      >
        <div className="Module__Wrapper">
          <ModuleHeadings
            className="VideoModule__Title"
            scheme={scheme}
            isFirstOnPage={isFirstOnPage}
            textAlign={textAlign}
            title={title}
            subtitle={subtitle}
          />
        </div>

        <LazyloadWrapper onLoad={setIsLazyloaded}>
          <UsercentricsCMPWrapper
            languageId={languageId}
            consentName="YouTube Video"
            onAccepted={setIsUsercentricsAccepted}
            isPreview={isPreview}
          >
            <div className="VideoModule__Wrapper__Inner">
              <div className="AspectRatioContainer VideoModule__AspectRatioContainer">
                <div
                  ref={iFrameRef}
                  className="VideoModule__Video AspectRatioContainer__Content"
                />
              </div>
            </div>
          </UsercentricsCMPWrapper>
        </LazyloadWrapper>
      </div>
    </ModuleWithHeadings>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { colorSchemes, loadStates, sites },
  { translatedModule: module }
): StateProps => {
  const site = getActiveSite(sites);

  return {
    domain: getPrimaryDomain(site),
    scheme: getActiveColorScheme(colorSchemes, site, module),
    loadStatus: loadStates.scripts[youTubeScriptUrl] || "unloaded",
  };
};

const mapDispatchToProps = {
  injectScript,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(VideoModule);
