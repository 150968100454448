import { combineReducers, Reducer } from "redux";
import byId from "./byId";
import byParentId from "./byParentId";
import byPageId from "./byPageId";
import bySiteModuleType from "./bySiteModuleType";
import actionModules from "./actionModules";
import modulesReducer from "./modules";
import { Modules } from "../../types";
import { AllActions } from "../../actions";

export const combined = combineReducers<Modules>({
  byId,
  byParentId,
  byPageId,
  actionModules,
  bySiteModuleType,
});

const reducer: Reducer<Modules, AllActions> = (state, action) => {
  const stateFromAll = modulesReducer(state, action);
  return combined(stateFromAll, action);
};

export default reducer;
