import * as React from "react";
import { FunctionComponent } from "react";
import { spriteData } from "../../dist/svg-sprite/icons-data";
import { IconName } from "../types";
import { injectSprite, runOnce } from "../utils/utils";
import SvgUseIcon from "./SvgUseIcon";

const { spriteFileName, viewBoxes, symbolIdPrefix } = spriteData;

interface Props {
  className?: string;
  glyph: IconName;
}

const injectOnce = runOnce(() => injectSprite(spriteFileName));

const Icon: FunctionComponent<Props> = ({ glyph, className }) => (
  <SvgUseIcon
    onIE11={injectOnce}
    className={className}
    viewBox={viewBoxes[glyph]}
    symbolIdPrefix={symbolIdPrefix}
    glyph={glyph}
    spriteFileName={spriteFileName}
  />
);

export default Icon;
