import * as React from "react";
import { FunctionComponent, useEffect, useState } from "react";
import { getTranslations, isBrowser } from "../utils/utils";
import { Language, UsercentricsConsentName, WindowState } from "../types";
import { style } from "typestyle";
import * as ClassNames from "classnames";
import {
  registerOnInitialized,
  usercentricsServiceMap,
} from "../utils/usercentrics";

const getUserCentricsStyles = () => {
  if (!isBrowser) return undefined;
  const { usercentrics } = (window as unknown) as WindowState;
  return usercentrics?.settings?.styles;
};

interface Props {
  languageId: Language;
  consentName: UsercentricsConsentName | undefined;
}

const translations = {
  de: {
    title: "Wir brauchen Ihr Einverständnis!",
    text: (
      consentName: string
    ) => `Wir benutzen Drittanbieter (${consentName}), um Inhalte einzubinden.
      Diese können persönliche Daten über Ihre Aktivitäten sammeln.
      Bitte beachten Sie die Details und geben Sie Ihre Einwilligung.`,
    moreInfo: "Mehr Informationen",
    accept: "Akzeptieren",
  },
  en: {
    title: "We need your consent!",
    text: (
      consentName: string
    ) => `We rely on third party providers (${consentName}) to integrate the content.
      They may collect personal information about your activities.
      Please note the details and give your consent.`,
    moreInfo: "More information",
    accept: "Accept",
  },
  it: {
    title: "Abbiamo bisogno del Suo consenso!",
    text: (
      consentName: string
    ) => `Per l’integrazione dei contenuti ci avvaliamo di fornitori terzi (${consentName})
      che possono raccogliere dati personali sulle Sue attività.
      La preghiamo di prendere nota dei dettagli e di dare il Suo consenso.`,
    moreInfo: "Ulteriori informazioni",
    accept: "Accettare/acconsentire",
  },
};

const ModuleConsentBox: FunctionComponent<Props> = ({
  languageId,
  consentName,
}) => {
  const i18n = getTranslations(languageId, translations);
  const [styles, setStyles] = useState(getUserCentricsStyles());

  useEffect(() => {
    const onUnmount = registerOnInitialized(() =>
      setStyles(getUserCentricsStyles())
    );

    return () => {
      onUnmount();
    };
  }, []);

  return (
    <div className="ModuleConsentBox">
      <div
        className={ClassNames(
          "ModuleConsentBox__Modal",
          "TextAlign--center",
          style({
            backgroundColor: styles?.bannerBgColor,
            color: styles?.txtOptInMsgColor,
          })
        )}
      >
        <h2 className="ModuleConsentBox__Title">{i18n.title}</h2>
        <p className="ModuleConsentBox__Text">
          {consentName && i18n.text(consentName)}
        </p>

        <div className="ButtonGroup ButtonGroup--align-center">
          <a
            className={ClassNames(
              "ModuleConsentBox__Button",
              "ModuleConsentBox__Button--more",
              "Button",
              "Btn--bare",
              style({
                backgroundColor: styles?.btnMoreInfoBgColor,
                borderColor: styles?.btnMoreInfoBgColor,
                color: styles?.btnMoreInfoTextColor,
              })
            )}
            href="#uc-info-modal-show"
          >
            {i18n.moreInfo}
          </a>
          <button
            type="button"
            className={ClassNames(
              "ModuleConsentBox__Button",
              "ModuleConsentBox__Button--accept",
              "Button",
              "Btn--bare",
              style({
                backgroundColor: styles?.btnAcceptBgColor,
                borderColor: styles?.btnAcceptBgColor,
                color: styles?.btnAcceptTextColor,
              })
            )}
            onClick={() => {
              if (!consentName) return;
              const templateId = usercentricsServiceMap[consentName];
              if (!templateId) return;
              const { usercentrics } = (window as unknown) as WindowState;
              usercentrics?.updateConsents([
                {
                  templateId,
                  status: true,
                },
              ]);
            }}
          >
            {i18n.accept}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModuleConsentBox;
