import { SiteState, SitesById } from "../types";
import { AllActions } from "../actions";
import { PatchSite, APISite } from "../../server/types";
import { getActiveSite } from "../selectors/sites";

const initialState: SiteState = {
  activeSiteId: undefined,
  noMoreItems: false,
  allItems: [],
  byId: {},
};

const getSitesSuccess = (
  state: SiteState,
  sites: APISite[],
  keepExisting: boolean
): SiteState => {
  const allItems = sites.map((site) => site.id);

  const byId = sites.reduce<SitesById>((byId, site) => {
    return {
      ...byId,
      [site.id]: site,
    };
  }, state.byId);

  return {
    ...state,
    allItems: keepExisting ? [...state.allItems, ...allItems] : allItems,
    noMoreItems: sites.length !== 10,
    byId: byId,
  };
};

const getSiteSuccess = (state: SiteState, site: APISite): SiteState => {
  return {
    ...state,
    allItems: [site.id],
    activeSiteId: site.id,
    byId: {
      ...state.byId,
      [site.id]: site,
    },
  };
};

const patchSiteStart = (
  state: SiteState,
  siteRequest: PatchSite
): SiteState => {
  const site = getActiveSite(state);

  return {
    ...state,
    byId: {
      ...state.byId,
      [site.id]: { ...site, ...siteRequest },
    },
  };
};

const reducer = (state = initialState, action: AllActions): SiteState => {
  switch (action.type) {
    case "GET_SITES_SUCCESS":
      return getSitesSuccess(state, action.sites, action.keepExisting);

    case "GET_SITE_SUCCESS":
      return getSiteSuccess(state, action.site);

    case "PATCH_SITE_START":
      return patchSiteStart(state, action.siteRequest);

    default:
      return state;
  }
};

export default reducer;
