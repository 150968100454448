import * as React from "react";
import { FunctionComponent, MutableRefObject, useEffect, useRef } from "react";
import { Item } from "photoswipe";

interface Props {
  items: Item[];
  openPhotoSwipeRef: MutableRefObject<
    ((startIndex: number) => void) | undefined
  >;
}

interface PhotoSwipeOptions {
  index: number;
}

const PhotoSwipeWrapper: FunctionComponent<Props> = ({
  items,
  openPhotoSwipeRef,
}) => {
  const photoSwipeElRef = useRef<HTMLDivElement>(null);
  const photoSwipeRef = useRef<PhotoSwipe<PhotoSwipeOptions>>();

  const openPhotoSwipe = async (startIndex: number) => {
    if (!photoSwipeElRef.current) return;

    const { PhotoSwipe, PhotoswipeUIDefault } = await import(
      "../utils/photoswipe"
    );

    photoSwipeRef.current = new PhotoSwipe<PhotoSwipeOptions>(
      photoSwipeElRef.current,
      PhotoswipeUIDefault,
      items,
      { index: startIndex }
    );

    photoSwipeRef.current.init();
  };

  openPhotoSwipeRef.current = openPhotoSwipe;

  useEffect(() => {
    return () => {
      photoSwipeRef.current?.close();
    };
  }, []);

  return (
    <div
      className="pswp"
      ref={photoSwipeElRef}
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="pswp__bg" />

      <div className="pswp__scroll-wrap">
        <div className="pswp__container">
          <div className="pswp__item" />
          <div className="pswp__item" />
          <div className="pswp__item" />
        </div>

        <div className="pswp__ui pswp__ui--hidden">
          <div className="pswp__top-bar">
            <div className="pswp__counter" />

            <button
              className="pswp__button pswp__button--close"
              title="Close (Esc)"
            />

            <button
              className="pswp__button pswp__button--share"
              title="Share"
            />

            <button
              className="pswp__button pswp__button--fs"
              title="Toggle fullscreen"
            />

            <button
              className="pswp__button pswp__button--zoom"
              title="Zoom in/out"
            />

            <div className="pswp__preloader">
              <div className="pswp__preloader__icn">
                <div className="pswp__preloader__cut">
                  <div className="pswp__preloader__donut" />
                </div>
              </div>
            </div>
          </div>

          <div className="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
            <div className="pswp__share-tooltip" />
          </div>

          <button
            className="pswp__button pswp__button--arrow--left"
            title="Previous (arrow left)"
          />

          <button
            className="pswp__button pswp__button--arrow--right"
            title="Next (arrow right)"
          />

          <div className="pswp__caption">
            <div className="pswp__caption__center" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhotoSwipeWrapper;
