import HeaderModule from "../components/Modules/HeaderModule";
import FooterModule from "../components/Modules/FooterModule";
import ButtonModule from "../components/Modules/ButtonModule";
import BookingModule from "../components/Modules/BookingModule";
import EnquiryModule from "../components/Modules/EnquiryModule";
import HighlightsModule from "../components/Modules/HighlightsModule";
import ImagesModule from "../components/Modules/ImagesModule";
import ImageGalleryModule from "../components/Modules/ImageGalleryModule";
import MapModule from "../components/Modules/MapModule";
import PreviewModule from "../components/Modules/PreviewModule";
import PriceModule from "../components/Modules/PriceModule";
import RoomsModule from "../components/Modules/RoomsModule";
import SpecialsModule from "../components/Modules/SpecialsModule";
import TextModule from "../components/Modules/TextModule";
import VideoModule from "../components/Modules/VideoModule";
import EasiPayModule from "../components/Modules/EasiPayModule";
import EasiFastCheckInModule from "../components/Modules/EasiFastCheckInModule";
import HTMLModule from "../components/Modules/HTMLModule";
import SeparatorModule from "../components/Modules/SeparatorModule";
import NotFoundTextModule from "../components/Modules/NotFoundTextModule";
import MTSToursModule from "../components/Modules/MTSToursModule";
import WeatherWebcamModule from "../components/Modules/WeatherWebcamModule";
import WeatherModule from "../components/Modules/WeatherModule";
import WebcamModule from "../components/Modules/WebcamModule";
import SentresModule from "../components/Modules/SentresModule";
import Google360VirtualTourModule from "../components/Modules/Google360VirtualTourModule";
import PopUpModule from "../components/Modules/PopUpModule";
import GastroPoolInsuranceModule from "../components/Modules/GastroPoolInsuranceModule";
import NewsletterModule from "../components/Modules/NewsletterModule";
import PeerTvModule from "../components/Modules/PeerTvModule";
import { Component } from "react";
import { AllModulesByType, SystemPageModuleType } from "../types";

export const mostModulesByType: Omit<AllModulesByType, SystemPageModuleType> = {
  HeaderModule,
  FooterModule,
  ButtonModule,
  BookingModule,
  EnquiryModule,
  HighlightsModule,
  HighlightModule: Component, // Dummy, as it can't be imported
  ImagesModule,
  ImageModule: Component, // Dummy, as it can't be imported
  OverlayModule: Component, // Dummy, as it can't be imported
  ImageGalleryModule,
  MapModule,
  PreviewModule,
  PriceModule,
  RoomsModule,
  SpecialsModule,
  TextModule,
  VideoModule,
  EasiPayModule,
  EasiFastCheckInModule,
  HTMLModule,
  SeparatorModule,
  NotFoundTextModule,
  MTSToursModule,
  WeatherWebcamModule,
  WeatherModule,
  WebcamModule,
  SentresModule,
  Google360VirtualTourModule,
  GastroPoolInsuranceModule,
  PopUpModule,
  NewsletterModule,
  PeerTvModule,
};
