import {
  Modules,
  ModulesByPageId,
  Module,
  ModuleSection,
  ImageModuleSettings,
  ModuleType,
  HeaderModuleSettings,
  EnquiryModuleSettings,
  BookingModuleSettings,
  Language,
  TranslatedModule,
  EnquiryWidgetFieldSettings,
  BookingWidgetFieldSettings,
  NewsletterModuleFieldSettings,
  NewsletterModuleSettings,
} from "../types";
import { keys, getTranslatedModule } from "../utils/utils";
import { defaultFieldSettings } from "../reducers/modules/byId";

const getModulesById = (modules: Modules, moduleIds: string[]) =>
  moduleIds.map((moduleId) => modules.byId[moduleId]);

export const arePageModulesLoaded = (
  byPageId: ModulesByPageId,
  pageId: string
): boolean => getPageModuleIds(byPageId, pageId) !== undefined;

export const getPageModuleIds = (
  byPageId: ModulesByPageId,
  pageId: string | null
): string[] | undefined => {
  return byPageId[String(pageId)];
};

export const getPageModules = (modules: Modules, pageId: string): Module[] => {
  const pageModuleIds = getPageModuleIds(modules.byPageId, pageId) || [];
  return getModulesById(modules, pageModuleIds);
};

export const getSiteModules = (
  modules: Modules,
  moduleTypes?: ModuleType[]
): Module[] => {
  const ids = (moduleTypes ? moduleTypes : keys(modules.bySiteModuleType))
    .map((key) => modules.bySiteModuleType[key] ?? [])
    // Flatten the array
    .reduce((carry, value) => carry.concat(value), []);

  return getModulesById(modules, ids);
};

export const getIsHeaderWithFixedSidebar = (modules: Modules): boolean => {
  const moduleId = modules.bySiteModuleType.HeaderModule?.[0];
  if (moduleId === undefined) return false;

  const headerModule = <Module<HeaderModuleSettings> | undefined>(
    modules.byId[moduleId]
  );

  return headerModule?.settings.layoutVariant === "fixed-sidebar";
};

export const createModuleSections = ({
  modules,
  pageId,
  popUpModuleIds,
}: {
  modules: Modules;
  pageId: string;
  popUpModuleIds: string[];
}): ModuleSection[] => {
  const headerModuleId = modules.bySiteModuleType.HeaderModule?.[0];
  const footerModuleId = modules.bySiteModuleType.FooterModule?.[0];

  const contentSection: ModuleSection = {
    isSortable: true,
    items: getPageModules(modules, pageId),
  };

  if (!headerModuleId || !footerModuleId) return [contentSection];

  return [
    {
      isSortable: false,
      items: [
        ...getModulesById(modules, [headerModuleId]),
        ...getModulesById(modules, popUpModuleIds),
      ],
    },
    contentSection,
    {
      isSortable: false,
      items: getModulesById(modules, [footerModuleId]),
    },
  ];
};

export const isModuleWithImages = (
  module: Module
): module is Module<ImageModuleSettings> =>
  !!(<Module<ImageModuleSettings>>module).settings.pictureId;

export const getSiteModule = <
  S extends
    | EnquiryModuleSettings
    | BookingModuleSettings
    | NewsletterModuleSettings
>({
  modules,
  languageId,
  moduleType,
}: {
  modules: Modules;
  languageId: Language;
  moduleType: ModuleType;
}): TranslatedModule<S> | undefined => {
  const [siteModule] = getSiteModules(modules, [moduleType]) as (
    | Module<S>
    | undefined
  )[];

  if (siteModule) {
    return getTranslatedModule(siteModule, languageId);
  }

  return undefined;
};

export const getEnquiryFieldSettings = (
  modules: Modules,
  languageId: Language
): EnquiryWidgetFieldSettings =>
  getSiteModule<EnquiryModuleSettings>({
    modules,
    languageId,
    moduleType: "EnquiryModule",
  })?.settings.fieldSettings ?? defaultFieldSettings.EnquiryModule;

export const getBookingFieldSettings = (
  modules: Modules,
  languageId: Language
): BookingWidgetFieldSettings =>
  getSiteModule<BookingModuleSettings>({
    modules,
    languageId,
    moduleType: "BookingModule",
  })?.settings.fieldSettings ?? defaultFieldSettings.BookingModule;

export const getNewsletterFieldSettings = (
  modules: Modules,
  languageId: Language
): NewsletterModuleFieldSettings =>
  getSiteModule<NewsletterModuleSettings>({
    modules,
    languageId,
    moduleType: "NewsletterModule",
  })?.settings.fieldSettings ?? defaultFieldSettings.NewsletterModule;
