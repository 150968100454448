import {
  ModulesById,
  TextAligns,
  Module,
  ModuleSettingsTypes,
  Language,
  TitleAndSubtitleTextAlign,
  ModuleTranslations,
  ModuleTranslation,
  ButtonModuleSettings,
  DefaultModuleSettings,
  PartialModuleSettings,
  ImageCropSettings,
  PostModuleParams,
  Page,
  FooterModuleSettings,
  LogoCategory,
  OfferingsModuleSettings,
  BookingWidgetFieldSettings,
  EnquiryWidgetFieldSettings,
  NewsletterModuleFieldSettings,
} from "../../types";
import * as deepExtend from "deep-extend";
import { AllActions } from "../../actions";
import {
  keys,
  translateModuleSettings,
  getModulesFromAllPages,
  moveArrayElement,
} from "../../utils/utils";

export const initialState: ModulesById = {};

const isFooterModule = (
  module: Module
): module is Module<FooterModuleSettings> => module.type === "FooterModule";

const buttonModuleSettings: ButtonModuleSettings = {
  global: {
    layout: "flat",
    corner: "square",
    type: "primary",
  },
  language: {
    title: "Button",
    linkData: {
      url: null,
      moduleId: null,
      moduleType: null,
      pageId: null,
      languageId: null,
    },
  },
};

export const defaultFieldSettings: {
  BookingModule: BookingWidgetFieldSettings;
  EnquiryModule: EnquiryWidgetFieldSettings;
  NewsletterModule: NewsletterModuleFieldSettings;
} = {
  BookingModule: {
    gender: [true, false],
    phone: [true, false],
    street: [false, false],
    zipcode: [false, false],
    city: [false, false],
    country: [true, false],
    note: [true, false],
  },
  EnquiryModule: {
    stay: [true, true],
    occupancies: [true, true],
    gender: [true, false],
    phone: [true, false],
    street: [false, false],
    zipcode: [false, false],
    city: [false, false],
    country: [true, false],
    note: [true, false],
  },
  NewsletterModule: {
    gender: [true, false],
    firstName: [true, false],
    lastName: [true, false],
    country: [true, false],
  },
};

const textAlign: TextAligns = {
  title: "center",
  subtitle: "center",
  description: "center",
  buttons: "center",
};

const highlightTextAlign: TextAligns = {
  title: "left",
  subtitle: "left",
  description: "left",
  buttons: "right",
};

const titleAndSubtitleTextAlign: TitleAndSubtitleTextAlign = {
  title: textAlign.title,
  subtitle: textAlign.subtitle,
};

const offeringModuleSettings: OfferingsModuleSettings = {
  language: {
    title: "",
    subtitle: "",
  },
  global: {
    textAlign: titleAndSubtitleTextAlign,
    layout: "layout-1",
    displayType: "tiles",
    imageAspectRatio: 0.75,
    maxColumns: 4,
    pinnedItems: [],
    maxItems: undefined,
    enquiryLayout: "layout-1",
    bookingLayout: "compact",
  },
};

export const defaultImageCropSettings: ImageCropSettings = {
  x: 0.5,
  y: 0.5,
  scale: 1,
  croppingRect: {
    x: 0,
    y: 0,
    width: 1,
    height: 1,
  },
};

export const defaultSettings: DefaultModuleSettings = {
  HeaderModule: {
    language: {
      activeActionModuleIds: {},
    },
    global: {
      layoutVariant: "all-in-header",
      showBreadcrumb: false,
      sticky: false,
      menuVariant: "bottom-bar",
      topHeaderVariant: "hamburger-nav",
      leftOverlayNavIcons: "arrow",
      showMenuSeparators: false,
      showSouthTyrolLogo: true,
    },
  },
  FooterModule: {
    language: {
      title: "Kontaktieren Sie uns",
      description: undefined,
    },
    global: {
      logoCategories: [["047c323d-c032-4a27-ada5-a67d6aebb507"], []],
      layoutVariant: "big",
      logoSize: "big",
      showTrustYou: true,
      textAlign: {
        ...textAlign,
        title: "left",
        description: "left",
      },
    },
  },
  ButtonModule: buttonModuleSettings,
  TextModule: {
    language: {
      title: "Lorem ipsum dolor sit amet",
      subtitle:
        "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam",
      description: undefined,
    },
    global: {
      imagesAspectRatio: 0.5625,
      textAlign,
      imageAlignment: "image-left",
      width: "container-width",
      columns: 1,
      boxAlign: "center",
    },
  },
  OverlayModule: {
    language: {
      title: "",
      subtitle: "",
    },
    global: {
      fontSize: "medium",
      horizontalAlign: "left",
      textStyle: "plain",
      verticalAlign: "bottom",
    },
  },
  ImagesModule: {
    language: {
      title: "",
      subtitle: "",
    },
    global: {
      autoPlay: false,
      autoPlayDelay: 3,
      columnsCount: 1,
      width: 6,
      imagesAspectRatio: 0.5625,
      imagesType: "separator",
      sliderEffect: "slide",
      textAlign: titleAndSubtitleTextAlign,
    },
  },
  ImageModule: {
    global: {
      crop: defaultImageCropSettings,
      pictureId: "",
    },
    language: {},
  },
  ImageGalleryModule: {
    language: {
      title: "",
      subtitle: "",
    },
    global: {
      imagesAspectRatio: 0.5625,
      textAlign: titleAndSubtitleTextAlign,
    },
  },
  RoomsModule: {
    ...offeringModuleSettings,
    global: {
      ...offeringModuleSettings.global,
      layout: "layout-4",
    },
  },
  MapModule: {
    language: {},
    global: {
      zoom: 15,
    },
  },
  BookingModule: {
    language: {
      title: "",
      subtitle: "",
    },
    global: {
      license: "extended",
      defaultOfferList: "rooms",
      layout: "one-pager",
      textAlign: titleAndSubtitleTextAlign,
      fieldSettings: defaultFieldSettings.BookingModule,
    },
  },
  NotFoundTextModule: {
    language: {},
    global: {},
  },
  SpecialsModule: offeringModuleSettings,
  PriceModule: {
    language: {
      title: "Preisliste",
      subtitle: "",
      description: undefined,
    },
    global: {
      layout: "layout-1",
      grouping: "board",
      offerIds: [],
      roomIds: [],
      boardIds: [],
      textAlign,
    },
  },
  EnquiryModule: {
    language: {
      title: "Unverbindliche Anfrage",
      subtitle: "",
    },
    global: {
      textAlign: titleAndSubtitleTextAlign,
      fieldSettings: defaultFieldSettings.EnquiryModule,
      layout: "layout-1",
    },
  },
  HighlightsModule: {
    language: {
      title: "",
      subtitle: "",
    },
    global: {
      textAlign: titleAndSubtitleTextAlign,
      layoutVariant: "vertical",
      displayType: "list",
      imagesAspectRatio: 0.75,
      maxColumnsCount: 2,
      collapsedLinesCount: undefined,
    },
  },
  HighlightModule: {
    language: {
      title: "Lorem ipsum",
      subtitle: "",
      description: undefined,
    },
    global: {
      textAlign: highlightTextAlign,
    },
  },
  ImprintModule: {
    language: {},
    global: {},
  },
  PrivacyModule: {
    language: {
      title: "Datenschutz",
      description: undefined,
    },
    global: {
      textAlign,
    },
  },
  TermsModule: {
    language: {},
    global: {},
  },
  VideoModule: {
    language: {
      title: "",
      subtitle: "",
    },
    global: {
      textAlign: titleAndSubtitleTextAlign,
      videoUrl: "",
      controls: true,
      showInfo: false,
      autoPlay: false,
      startAt: "00:00",
      size: "fullWidth",
      mute: false,
    },
  },
  EasiPayModule: {
    language: {
      title: "",
      subtitle: "",
    },
    global: {
      textAlign: titleAndSubtitleTextAlign,
      authId: "",
    },
  },
  EasiFastCheckInModule: {
    language: {
      title: "",
      subtitle: "",
    },
    global: {
      url: "",
      textAlign: titleAndSubtitleTextAlign,
    },
  },
  HTMLModule: {
    language: {
      title: "Lorem ipsum dolor sit amet",
      subtitle: "",
      html: "",
    },
    global: {
      textAlign: titleAndSubtitleTextAlign,
    },
  },
  MTSToursModule: {
    language: {
      title: "",
      subtitle: "",
    },
    global: {
      textAlign: titleAndSubtitleTextAlign,
      archive: "",
      user: "",
    },
  },
  GastroPoolInsuranceModule: {
    language: {
      title: "",
      subtitle: "",
      key: "",
    },
    global: {
      textAlign: titleAndSubtitleTextAlign,
    },
  },
  SentresModule: {
    language: {
      title: "",
      subtitle: "",
    },
    global: {
      textAlign: titleAndSubtitleTextAlign,
      mapId: undefined,
      mapCode: undefined,
    },
  },
  SeparatorModule: {
    language: {},
    global: {
      icon: "tree-1",
      width: "container-width",
      height: "medium",
      iconCount: 1,
      iconSize: "medium",
      lineStyle: "straight",
      lineWeight: "light",
    },
  },
  WeatherWebcamModule: {
    language: {},
    global: {
      layoutVariant: "vertical",
    },
  },
  WeatherModule: {
    language: { title: "Lorem ipsum", subtitle: "" },
    global: {
      districtId: undefined,
      iconStyle: "colored",
      textAlign,
    },
  },
  WebcamModule: {
    language: {
      title: "",
      subtitle: "",
      description: undefined,
      imageCaption: "",
    },
    global: {
      url: "",
      textAlign,
      layoutVariant: "image-first",
      size: "big",
    },
  },
  Google360VirtualTourModule: {
    language: {
      title: "Lorem ipsum dolor sit amet",
      subtitle: "",
    },
    global: {
      textAlign,
      url: undefined,
    },
  },
  PopUpModule: {
    language: {
      title: "Lorem ipsum dolor sit amet",
      description: undefined,
      subtitle: "",
    },
    global: {
      imagesAspectRatio: 0.5625,
      textAlign,
      imageAlign: "top",
      boxAlign: "center",
      darkBackground: true,
      onlyShowWhenUserInactive: false,
    },
  },
  NewsletterModule: {
    language: {
      title: "Newsletter",
      subtitle: "",
      description: undefined,
    },
    global: {
      textAlign,
      fieldSettings: defaultFieldSettings.NewsletterModule,
      layout: "layout-1",
    },
  },
  PeerTvModule: {
    language: {
      title: "",
      subtitle: "",
      caption: "",
      scriptUrl: "",
      websiteUrl: "",
    },
    global: {
      textAlign,
      size: "big",
    },
  },
  PreviewModule: { language: {}, global: {} },
};

const postModuleStart = (
  state: ModulesById,
  action: PostModuleParams
): ModulesById => {
  const {
    languageIds,
    moduleType,
    moduleId,
    pageId,
    siteId,
    parentId,
    settings = {},
  } = action;

  const newTranslations = languageIds.reduce<ModuleTranslations>(
    (acc, languageId) => {
      const translation: ModuleTranslation = {
        settings: deepExtend(
          {},
          defaultSettings[moduleType].language,
          settings.language
        ),
      };
      return { ...acc, [languageId]: translation };
    },
    {}
  );

  const newModule: Module = {
    colorSchemeId: null,
    id: moduleId,
    pageId,
    siteId,
    type: moduleType,
    parentId,
    translations: newTranslations,
    settings: deepExtend(
      {},
      defaultSettings[moduleType].global,
      settings.global
    ),
  };

  return { ...state, [moduleId]: newModule };
};

const getModules = (state: ModulesById, modules: Module[]): ModulesById => {
  const modulesById = modules.reduce<ModulesById>((modules, module) => {
    const { global, language } = defaultSettings[module.type];
    const translations = keys(module.translations).reduce<ModuleTranslations>(
      (acc, languageId) => {
        const translation = module.translations[languageId];
        const settingsbyLanguage = deepExtend(
          {},
          language,
          translation ? translation.settings : {}
        );

        return {
          ...acc,
          [languageId]: {
            settings: settingsbyLanguage,
          },
        };
      },
      {}
    );

    const currentModule: Module = {
      ...module,
      translations,
      settings: { ...global, ...module.settings },
    };

    return {
      ...modules,
      [module.id]: currentModule,
    };
  }, state);

  return modulesById;
};

const getModulesFromPages = (state: ModulesById, pages: Page[]) =>
  getModules(state, getModulesFromAllPages(pages));

const changeModule = (
  state: ModulesById,
  moduleId: string,
  changeset: Partial<Module>
): ModulesById => {
  const module: Module = { ...state[moduleId], ...changeset };
  return { ...state, [moduleId]: module };
};

const translateModule = (
  state: ModulesById,
  moduleId: string,
  languageId: Language,
  sourceLanguageId: Language
) => {
  const currentModule = state[moduleId];
  const translation = currentModule.translations[sourceLanguageId];
  const translationAlreadyExists = !!currentModule.translations[languageId];

  if (!translation || translationAlreadyExists) {
    return state;
  }

  const newTranslation: ModuleTranslation = {
    settings: translateModuleSettings(translation.settings, languageId),
  };

  return changeModule(state, moduleId, {
    translations: {
      ...currentModule.translations,
      [languageId]: newTranslation,
    },
  });
};

const deleteModuleTranslation = (
  state: ModulesById,
  moduleId: string,
  languageId: Language,
  isUntranslated: boolean
): ModulesById => {
  const currentModule = state[moduleId];

  // Delete the entire module
  if (isUntranslated) {
    const newState = { ...state };
    delete newState[moduleId];
    return newState;
  }

  // Delete only one translation translation
  const newTranslations = { ...currentModule.translations };
  delete newTranslations[languageId];

  const updatedModule: Module = {
    ...currentModule,
    translations: newTranslations,
  };

  return { ...state, [moduleId]: updatedModule };
};

const setModuleSettings = (
  state: ModulesById,
  moduleId: string,
  languageId: Language,
  settings: PartialModuleSettings<ModuleSettingsTypes>
): ModulesById => {
  const currentModule = state[moduleId];
  const translation: ModuleTranslation = currentModule.translations[
    languageId
  ] || {
    settings: {},
  };

  const newModuleTranslation: ModuleTranslation = {
    settings: {
      ...translation.settings,
      ...(settings.language || {}),
    },
  };

  const updatedModule: Module = {
    ...currentModule,
    translations: {
      ...currentModule.translations,
      [languageId]: newModuleTranslation,
    },
    settings: {
      ...currentModule.settings,
      ...(settings.global || {}),
    },
  };

  return { ...state, [moduleId]: updatedModule };
};

const deleteModulesColorScheme = (
  state: ModulesById,
  colorSchemeId: string
): ModulesById => {
  return Object.keys(state).reduce<ModulesById>((accumulator, moduleId) => {
    const updatedModule: Module = {
      ...state[moduleId],
      colorSchemeId:
        state[moduleId].colorSchemeId === colorSchemeId
          ? null
          : state[moduleId].colorSchemeId,
    };

    return {
      ...accumulator,
      [moduleId]: updatedModule,
    };
  }, initialState);
};

const updateLogoCategory = (
  state: ModulesById,
  moduleId: string,
  logoCategory: LogoCategory,
  callback: (logoIds: string[]) => string[]
) => {
  const module = state[moduleId];
  if (!isFooterModule(module)) return state;

  const { logoCategories } = module.settings;

  return changeModule(state, moduleId, {
    settings: {
      ...module.settings,
      logoCategories: Object.assign([], logoCategories, {
        [logoCategory]: callback([...logoCategories[logoCategory]]),
      }),
    },
  });
};

const moveLogoStart = (
  state: ModulesById,
  moduleId: string,
  logoCategory: LogoCategory,
  dragIndex: number,
  hoverIndex: number
): ModulesById => {
  return updateLogoCategory(state, moduleId, logoCategory, (logoIds) => {
    return moveArrayElement(logoIds, dragIndex, hoverIndex);
  });
};

const deleteLogoStart = (
  state: ModulesById,
  moduleId: string,
  logoCategory: LogoCategory,
  logoId: string
): ModulesById => {
  return updateLogoCategory(state, moduleId, logoCategory, (logoIds) => {
    return logoIds.filter((id) => id !== logoId);
  });
};

const upsertLogoStart = (
  state: ModulesById,
  moduleId: string,
  logoCategory: LogoCategory,
  newLogoId: string,
  previousLogoId: string | undefined
): ModulesById => {
  return updateLogoCategory(state, moduleId, logoCategory, (logoIds) => {
    if (!previousLogoId) {
      return [...logoIds, newLogoId];
    }

    const index = logoIds.indexOf(previousLogoId);

    if (index === -1) {
      return [...logoIds, newLogoId];
    }

    return Object.assign([], logoIds, { [index]: newLogoId });
  });
};

const reducer = (state = initialState, action: AllActions): ModulesById => {
  switch (action.type) {
    case "POST_MODULE_START":
      return postModuleStart(state, action);

    case "CHANGE_MODULE":
      return changeModule(state, action.moduleId, action.changeset);

    case "TRANSLATE_MODULE_START":
      return translateModule(
        state,
        action.moduleId,
        action.languageId,
        action.sourceLanguageId
      );

    case "DELETE_MODULE_TRANSLATION_START":
      return deleteModuleTranslation(
        state,
        action.moduleId,
        action.languageId,
        action.deleteAllTranslations
      );

    case "SET_MODULE_SETTINGS":
      return setModuleSettings(
        state,
        action.moduleId,
        action.languageId,
        action.settings
      );

    case "GET_PAGES_SUCCESS":
      return getModulesFromPages(state, action.pages);

    case "GET_SITE_MODULES_SUCCESS":
    case "GET_MODULES_SUCCESS":
      return getModules(state, action.modules);

    case "DELETE_MODULES_COLOR_SCHEME":
      return deleteModulesColorScheme(state, action.colorSchemeId);

    case "MOVE_LOGO_START":
      return moveLogoStart(
        state,
        action.moduleId,
        action.logoCategory,
        action.dragIndex,
        action.hoverIndex
      );

    case "DELETE_LOGO_START":
      return deleteLogoStart(
        state,
        action.moduleId,
        action.logoCategory,
        action.logoId
      );

    case "UPSERT_LOGO_START":
      return upsertLogoStart(
        state,
        action.moduleId,
        action.logoCategory,
        action.newLogoId,
        action.previousLogoId
      );

    default:
      return state;
  }
};

export default reducer;
