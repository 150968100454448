import { Modules, Language } from "../../types";
import {
  getModulesByParentId,
  isItemUntranslated,
  creatableGlobalModules,
} from "../../utils/utils";
import { combined } from "./";
import { AllActions } from "../../actions";
import {
  deleteModuleTranslationStart,
  DeleteModuleTranslationStartAction,
  translateModuleStart,
  TranslateModuleStartAction,
} from "../../actions/Modules";
import { getPageModules, getSiteModules } from "../../selectors/modules";
import { Reducer } from "redux";
import { initialState as actionModulesInitialState } from "./actionModules";
import { initialState as byIdInitialState } from "./byId";
import { initialState as byPageIdInitialState } from "./byPageId";
import { initialState as byParentIdInitialState } from "./byParentId";
import { initialState as bySiteModuleTypeInitialState } from "./bySiteModuleType";

const initialState: Modules = {
  actionModules: actionModulesInitialState,
  byId: byIdInitialState,
  byPageId: byPageIdInitialState,
  byParentId: byParentIdInitialState,
  bySiteModuleType: bySiteModuleTypeInitialState,
};

const postPageTranslation = (
  state: Modules,
  pageId: string,
  languageId: Language,
  sourceLanguageId: Language
) => {
  const siteModules = getSiteModules(state);
  const modules = getPageModules(state, pageId);

  return [...siteModules, ...modules].reduce((newState, module) => {
    const action = translateModuleStart(
      module.id,
      languageId,
      sourceLanguageId,
      pageId
    );

    return translateSubModules(newState, action);
  }, state);
};

const deletePageTranslation = (
  state: Modules,
  pageId: string,
  languageId: Language
) => {
  const modules = getPageModules(state, pageId);

  return modules.reduce((newState, module) => {
    const action = deleteModuleTranslationStart({
      pageId,
      languageId,
      moduleId: module.id,
      moduleType: module.type,
      parentId: module.parentId,
      deleteAllTranslations: isItemUntranslated(module),
    });
    return deleteSubModuleTranslation(newState, action);
  }, state);
};

const deleteSubModuleTranslation = (
  state: Modules,
  action: DeleteModuleTranslationStartAction
): Modules => {
  const modules = getModulesByParentId(
    state,
    action.moduleId,
    action.pageId,
    action.moduleType
  );
  state = combined(state, action);

  return modules.reduce<Modules>((newState, module) => {
    return deleteSubModuleTranslation(
      newState,
      deleteModuleTranslationStart({
        pageId: action.pageId,
        languageId: action.languageId,
        moduleId: module.id,
        moduleType: module.type,
        parentId: module.parentId,
        deleteAllTranslations: isItemUntranslated(module),
      })
    );
  }, state);
};

const translateSubModules = (
  state: Modules,
  action: TranslateModuleStartAction
): Modules => {
  const {
    moduleId,
    languageId,
    sourceLanguageId,
    pageId: actionPageId,
  } = action;
  const currentModule = state.byId[moduleId];
  const pageId = creatableGlobalModules.includes(currentModule.type)
    ? currentModule.pageId
    : actionPageId;
  const modules = getModulesByParentId(state, moduleId, pageId);
  state = combined(state, action);

  return modules.reduce<Modules>(
    (newState, module) =>
      translateSubModules(
        newState,
        translateModuleStart(module.id, languageId, sourceLanguageId, pageId)
      ),
    state
  );
};

const reducer: Reducer<Modules, AllActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "POST_PAGE_TRANSLATION_START":
      return postPageTranslation(
        state,
        action.pageId,
        action.languageId,
        action.sourceLanguageId
      );

    case "DELETE_PAGE_TRANSLATION_START":
      return deletePageTranslation(state, action.pageId, action.languageId);

    case "DELETE_MODULE_TRANSLATION_START":
      return deleteSubModuleTranslation(state, action);

    case "TRANSLATE_MODULE_START":
      return translateSubModules(state, action);

    default:
      return state;
  }
};

export default reducer;
