import * as React from "react";
import { FunctionComponent } from "react";
import { APICountry } from "../types";

interface Props {
  label: string;
  countries: APICountry[];
}

const CountryCategory: FunctionComponent<Props> = ({ label, countries }) => (
  <optgroup label={label}>
    {countries.map(({ id, title }) => (
      <option value={id} key={id}>
        {title}
      </option>
    ))}
  </optgroup>
);

export default CountryCategory;
