import * as React from "react";
import { FunctionComponent, CSSProperties, RefObject } from "react";
import * as ClassNames from "classnames";

interface Props {
  id: string;
  title: string | undefined;
  subtitle: string | undefined;
  style: CSSProperties | undefined;
  className: string;
  innerRef?: RefObject<HTMLDivElement>;
}

const getModuleTag = ({
  title,
  subtitle,
}: {
  title: string | undefined;
  subtitle: string | undefined;
}) => (title || subtitle ? "section" : "div");

const ModuleWithHeadings: FunctionComponent<Props> = ({
  children,
  title,
  subtitle,
  id,
  style,
  className,
  innerRef,
}) => {
  const TagName = getModuleTag({ title, subtitle });
  return (
    <TagName
      id={id}
      ref={innerRef}
      className={ClassNames("Module", className)}
      style={style}
    >
      {children}
    </TagName>
  );
};

export default ModuleWithHeadings;
