import * as React from "react";
import { FunctionComponent, MouseEvent } from "react";
import { ColorScheme } from "../types";
import Icon from "./Icon";

interface Props {
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
  scheme: ColorScheme;
  className: string;
  direction: "left" | "right";
}

const SliderArrow: FunctionComponent<Props> = ({
  onClick,
  scheme,
  direction,
  className,
}) => {
  return (
    <button
      className={className}
      onClick={onClick}
      style={{
        background: scheme.primary.background,
        fill: scheme.primary.text,
      }}
    >
      <Icon
        className="MultiBox__NavigationIcon"
        glyph={direction === "left" ? "arrow-left" : "arrow-right"}
      />
    </button>
  );
};

export default SliderArrow;
