import * as React from "react";
import { FunctionComponent } from "react";
import { connect, ConnectedProps, MapStateToProps } from "react-redux";
import {
  StoreState,
  ImagesModuleSettings,
  BaseModuleProps,
  ColorScheme,
  ImageModuleSettings,
  TranslatedModule,
  OverlayModuleSettings,
  Modules,
} from "../../types";
import ImageSlider from "../ImageSlider";
import ImagesSeparator from "../ImagesSeparator";
import {
  getActiveColorScheme,
  getModulesByParentId,
  getPlaceholderImage,
  getTranslatedModule,
  getRecursiveOverlayModule,
} from "../../utils/utils";
import { getActiveSite } from "../../selectors/sites";
import { getPictureById, isPlaceholder } from "../../selectors/pictures";
import * as ClassNames from "classnames";
import ModuleWithHeadings from "../ModuleWithHeadings";
import ModuleHeadings from "../ModuleHeadings";

interface Props extends BaseModuleProps<ImagesModuleSettings> {
  isInsideHeader?: boolean;
  parentScheme?: ColorScheme;
  fullHeight?: boolean;
  showArrows?: boolean;
}

interface StateProps {
  images: TranslatedModule<ImageModuleSettings>[];
  mediaLibraryLoaded: boolean;
  scheme: ColorScheme;
  overlayModule: TranslatedModule<OverlayModuleSettings> | undefined;
  subModuleIsActive: boolean;
}

type ReduxProps = ConnectedProps<typeof connector>;

const ImagesModule: FunctionComponent<Props & ReduxProps> = ({
  images,
  translatedModule,
  translatedModule: {
    settings: { imagesType, columnsCount, textAlign },
    translation: {
      settings: { title, subtitle },
    },
  },
  mediaLibraryLoaded,
  isPreview,
  isInsideHeader = false,
  scheme,
  pageId,
  overlayModule,
  activeModuleId,
  subModuleIsActive,
  fullHeight = false,
  showArrows = true,
  isFirstOnPage,
}) => {
  if (!mediaLibraryLoaded) return null;

  return (
    <ModuleWithHeadings
      className={ClassNames("ImagesModule", {
        "ImagesModule--full-height": fullHeight,
      })}
      id={translatedModule.id}
      style={{ backgroundColor: scheme.main.background }}
      title={!isInsideHeader ? title : undefined}
      subtitle={!isInsideHeader ? subtitle : undefined}
    >
      {!isInsideHeader && (title || subtitle) && (
        <div className="ImagesModule__HeadingsWrapper Module__Wrapper Module__Wrapper--small-padding">
          <ModuleHeadings
            textAlign={textAlign}
            scheme={scheme}
            isFirstOnPage={isFirstOnPage}
            title={title}
            subtitle={subtitle}
          />
        </div>
      )}

      {imagesType === "separator" ? (
        <ImagesSeparator
          pageId={pageId}
          images={images}
          settings={translatedModule.settings}
          isInsideHeader={isInsideHeader}
          scheme={scheme}
          isPreview={isPreview}
        />
      ) : (
        <ImageSlider
          pageId={pageId}
          isPreview={isPreview}
          images={images}
          translatedModule={translatedModule}
          isInsideHeader={isInsideHeader}
          scheme={scheme}
          overlayModule={overlayModule}
          activeModuleId={activeModuleId}
          subModuleIsActive={subModuleIsActive}
          slidesToShow={columnsCount}
          showArrows={showArrows}
        />
      )}
    </ModuleWithHeadings>
  );
};

const getHasOverlaySubmodules = (
  modules: Modules,
  images: TranslatedModule<ImageModuleSettings>[],
  pageId: string
): boolean => {
  const hasOverlaySubmodules: boolean = !!images.reduce<number>(
    (count, { id }) => {
      const currentCount = getModulesByParentId<OverlayModuleSettings>(
        modules,
        id,
        pageId,
        "OverlayModule"
      );

      return count + currentCount.length;
    },
    0
  );

  return hasOverlaySubmodules;
};

/**
 * Check if a module or its submodules are active
 */
const checkModuleActive = (
  activeId: string | undefined,
  currentId: string,
  modules: Modules
): boolean => {
  if (activeId === currentId) return true;
  if (!activeId) {
    return false;
  }

  const module = modules.byId[activeId];
  if (!module) return false;

  return checkModuleActive(module.parentId || undefined, currentId, modules);
};

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { mediaLibrary, modules, loadStates, colorSchemes, sites },
  {
    translatedModule,
    translatedModule: {
      pageId,
      id: moduleId,
      translation: { languageId },
    },
    parentScheme,
    pageId: modulePageId,
    activeModuleId,
  }
): StateProps => {
  const site = getActiveSite(sites);

  const subModuleIsActive = checkModuleActive(
    activeModuleId,
    moduleId,
    modules
  );

  const images = !pageId
    ? []
    : getModulesByParentId<ImageModuleSettings>(
        modules,
        moduleId,
        pageId,
        "ImageModule"
      ).reduce<TranslatedModule<ImageModuleSettings>[]>((images, module) => {
        const image = getTranslatedModule(module, languageId);
        const picture = getPictureById(
          mediaLibrary.pictures,
          image.settings.pictureId
        );
        // // If the are multiple placeholder images (e.g. due to deleted EasyChannel images)
        // // filter them out
        return isPlaceholder(picture) ? images : [...images, image];
      }, []);

  const hasOverlaySubmodules = getHasOverlaySubmodules(
    modules,
    images,
    modulePageId
  );

  const overlayModule = hasOverlaySubmodules
    ? undefined
    : getRecursiveOverlayModule({
        languageId,
        modules,
        pageId: modulePageId,
        parentId: moduleId,
      });

  return {
    images:
      images.length || !pageId
        ? images
        : [getPlaceholderImage(site.id, pageId, languageId)],
    mediaLibraryLoaded: loadStates.mediaLibrary.pictures === "loaded",
    scheme:
      parentScheme ||
      getActiveColorScheme(colorSchemes, site, translatedModule),
    overlayModule,
    subModuleIsActive,
  };
};

const connector = connect(mapStateToProps);

export default connector(ImagesModule);
