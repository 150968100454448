import { Fonts, Font, FontsById } from "../types";
import { AllActions } from "../actions";

const initialState: Fonts = {
  byId: {},
  allIds: [],
};

const getFontsSuccess = (state: Fonts, fonts: Font[]): Fonts => {
  const allIds = fonts.map(({ id }) => id);
  const byId: FontsById = fonts.reduce<FontsById>(
    (acc, font) => ({ ...acc, [font.id]: font }),
    {}
  );

  return { ...state, byId, allIds };
};

const reducer = (state: Fonts = initialState, action: AllActions) => {
  switch (action.type) {
    case "GET_FONTS_SUCCESS":
      return getFontsSuccess(state, action.fonts);

    default:
      return state;
  }
};

export default reducer;
