import * as React from "react";
import { FunctionComponent, useRef, MutableRefObject } from "react";
import HeaderBar from "./HeaderBar";
import PriorityNavigation from "./PriorityNavigation";
import ImagesModule from "./Modules/ImagesModule";
import ActionModuleNavigation from "./ActionModuleNavigation";
import {
  ColorScheme,
  ContainerQueries,
  Accommodation,
  Language,
  StoreState,
  PageState,
  Picture,
  TranslatedModule,
  ImagesModuleSettings,
  MainMenuItem,
  TopHeaderVariant,
  ActionLinkItem,
} from "../types";
import { ConnectedProps, MapStateToProps, connect } from "react-redux";
import { getActiveSite } from "../selectors/sites";
import { getPictureById } from "../selectors/pictures";
import { getTopLevelMainMenu, getActionLinkList } from "../utils/utils";
import * as ClassNames from "classnames";

interface Props {
  scheme: ColorScheme;
  isPreview: boolean;
  queries: ContainerQueries;
  accommodation: Accommodation | undefined;
  fallbackLanguageId: Language | undefined;
  languageId: Language;
  topHeaderVariant: TopHeaderVariant;
  imagesModule: TranslatedModule<ImagesModuleSettings> | undefined;
  mainPageUrl: string | undefined;
  activePagePath: string[];
  pageId: string;
  activeModuleId: string | undefined;
  className?: string;
  intersectionTriggerRef: MutableRefObject<HTMLElement | null>;
}

type ReduxProps = ConnectedProps<typeof connector>;

interface StateProps {
  pages: PageState;
  logo: Picture;
  topLevelMainMenuItems: MainMenuItem[];
  actionLinks: ActionLinkItem[];
}

const HeaderWithOriginalLayouts: FunctionComponent<Props & ReduxProps> = ({
  isPreview,
  queries,
  scheme,
  accommodation,
  fallbackLanguageId,
  languageId,
  pages,
  logo,
  topHeaderVariant,
  imagesModule,
  mainPageUrl,
  activePagePath,
  pageId,
  topLevelMainMenuItems,
  actionLinks,
  activeModuleId,
  className,
  intersectionTriggerRef,
}) => {
  const headerBarRef = useRef<HTMLDivElement | null>(null);

  return (
    <div className={ClassNames("HeaderModule__Inner", className)}>
      <HeaderBar
        onRef={(el) => (headerBarRef.current = el)}
        onIntersectionTriggerRef={(el) => (intersectionTriggerRef.current = el)}
        accommodation={accommodation}
        actionLinks={actionLinks}
        activePagePath={activePagePath}
        hasHeaderImage={!!imagesModule}
        isPreview={isPreview}
        languageId={languageId}
        topHeaderVariant={topHeaderVariant}
        logo={logo}
        mainPageUrl={mainPageUrl}
        pages={pages}
        topLevelMainMenuItems={topLevelMainMenuItems}
        scheme={scheme}
        isStaticHeader={true}
        fallbackLanguageId={fallbackLanguageId}
        pageId={pageId}
      />
      <div className="HeaderModule__BottomBlock">
        {topHeaderVariant === "below-header-nav" && (
          <PriorityNavigation
            className="MainMenu__Wrapper--below-header-nav MainMenu__Wrapper--horizontal"
            isPreview={isPreview}
            languageId={languageId}
            menuItems={topLevelMainMenuItems}
            pages={pages}
            scheme={scheme}
            showIcons={false}
            topHeaderVariant={topHeaderVariant}
            activePagePath={activePagePath}
            hasHeaderImage={!!imagesModule}
            absolutePositioning={!!imagesModule}
            fallbackLanguageId={fallbackLanguageId}
          />
        )}
        {imagesModule && imagesModule.pageId && (
          <ImagesModule
            translatedModule={imagesModule}
            isPreview={isPreview}
            isLayoutPreview={false}
            pageId={pageId}
            queries={queries}
            isActive={false}
            isInsideHeader={true}
            isFirstOnPage={false}
            parentScheme={scheme}
            activeModuleId={activeModuleId}
          />
        )}
        <ActionModuleNavigation
          className="ActionModuleNavigation--on-narrow"
          isPreview={isPreview}
          actionLinks={actionLinks}
          scheme={scheme}
        />
      </div>
    </div>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { pages, sites, modules, mediaLibrary: { pictures, logoId } },
  { languageId, isPreview, fallbackLanguageId }
): StateProps => {
  const site = getActiveSite(sites);
  return {
    pages,
    logo: getPictureById(pictures, logoId, { height: 120 }),
    topLevelMainMenuItems: getTopLevelMainMenu({
      pages,
      languageId,
      isPreview,
      fallbackLanguageId,
    }),
    actionLinks: getActionLinkList({
      modules,
      pages,
      languageId,
      site,
      isPreview,
    }),
  };
};

const connector = connect(mapStateToProps);

export default connector(HeaderWithOriginalLayouts);
