import { AllActions } from "../../actions";
import { SeoCrawler, SeoUrlRequest, SeoUrl } from "../../types";

const initialState: SeoCrawler = {
  status: "uninitialized",
  crawledAt: null,
  errors: 0,
};

const setCrawlerStatus = (
  state: SeoCrawler,
  status: SeoCrawler["status"]
): SeoCrawler => {
  if (status === "ready") {
    state = { ...state, crawledAt: new Date().toJSON() };
  }

  return {
    ...state,
    status,
  };
};

const updateErrorCount = (
  state: SeoCrawler,
  url: SeoUrl,
  request: SeoUrlRequest
) => {
  let { errors } = state;

  if (!url.pageTranslationId && request.pageTranslationId) {
    errors--;
  } else if (!url.isArchived && request.isArchived) {
    errors--;
  } else if (url.isArchived && !request.isArchived) {
    errors++;
  } else if (url.pageTranslationId && !request.pageTranslationId) {
    errors++;
  }

  if (errors < 0) {
    return state;
  }

  return { ...state, errors };
};

const reducer = (state = initialState, action: AllActions) => {
  switch (action.type) {
    case "SET_SEO_CRAWLER_STATUS":
      return setCrawlerStatus(state, action.status);

    case "GET_SEO_CRAWLER_SUCCESS":
    case "POST_SEO_CRAWLER_SUCCESS":
      return action.crawler;

    case "PATCH_SEO_URL_START":
      return updateErrorCount(state, action.url, action.request);

    default:
      return state;
  }
};

export default reducer;
