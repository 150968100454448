import { Publish } from "../types";
import { AllActions } from "../actions";

const initialState: Publish = {
  status: null,
};

const setPublish = (publish: Publish): Publish => {
  return publish;
};

const reducer = (state = initialState, action: AllActions) => {
  switch (action.type) {
    case "POST_PUBLISH_SUCCESS":
      return setPublish(action.publish);

    case "GET_PUBLISH_SUCCESS":
      return setPublish(action.publish);

    default:
      return state;
  }
};

export default reducer;
