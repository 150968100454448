import { SiteState } from "../types";
import { APISite } from "../../server/types";

export const getActiveSite = (sites: SiteState) => {
  const { activeSiteId, byId } = sites;

  if (!activeSiteId || !byId[activeSiteId]) {
    throw Error("No active site found. This is most likely an error!");
  }

  return byId[activeSiteId];
};

export const getPrimaryDomain = (site: APISite) => site.domains[0];

export const getAliasDomains = (site: APISite) => site.domains.slice(1);
