import * as React from "react";
import { FunctionComponent, useRef, useEffect, useState } from "react";
import {
  ColorScheme,
  TranslatedModule,
  ImagesModuleSettings,
  ImageModuleSettings,
  OverlayModuleSettings,
} from "../types";
import Slider from "./Slider";
import ImageModule from "./Modules/ImageModule";
import OverlayModule from "./Modules/OverlayModule";
import ReactSlick from "react-slick";

interface Props {
  pageId: string;
  images: TranslatedModule<ImageModuleSettings>[];
  isPreview: boolean;
  isInsideHeader: boolean;
  scheme: ColorScheme;
  translatedModule: TranslatedModule<ImagesModuleSettings>;
  overlayModule: TranslatedModule<OverlayModuleSettings> | undefined;
  activeModuleId: string | undefined;
  subModuleIsActive: boolean;
  slidesToShow: number;
  showArrows?: boolean;
}

const ImageSlider: FunctionComponent<Props> = ({
  images,
  isPreview,
  translatedModule: {
    settings: {
      imagesAspectRatio,
      autoPlay,
      autoPlayDelay,
      sliderEffect,
      columnsCount,
    },
  },
  scheme,
  pageId,
  overlayModule,
  activeModuleId,
  subModuleIsActive,
  slidesToShow,
  showArrows = true,
  isInsideHeader,
}) => {
  const sliderRef = useRef<ReactSlick | null>(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (!sliderRef.current || !activeModuleId) {
      return;
    }

    const index = images.map(({ id }) => id).indexOf(activeModuleId);
    if (index === -1) {
      return;
    }

    sliderRef.current.slickGoTo(index);
  }, [activeModuleId]);

  return (
    <div className="SliderModule">
      <Slider
        sliderRef={sliderRef}
        settings={{
          arrows: showArrows,
          autoplay: subModuleIsActive ? false : autoPlay,
          autoplaySpeed: autoPlayDelay * 1000,
          fade: sliderEffect === "fade",
          slidesToShow,
          afterChange: (index) =>
            index > currentIndex && setCurrentIndex(index),
        }}
        isPreview={isPreview}
        showNavigationButtons={true}
      >
        {images.map((image, index) => (
          <div className="SliderModule__Slide" key={image.id}>
            {index <= currentIndex + columnsCount && (
              <ImageModule
                key={image.id}
                aspectRatio={imagesAspectRatio}
                translatedModule={image}
                width={1920}
                // don’t lazy load the first header image
                lazyLoad={!(index === 0 && isInsideHeader)}
                sizes="100vw"
                isPreview={isPreview}
                isSlider={true}
                pageId={pageId}
                scheme={scheme}
                showOverlay={!overlayModule}
              />
            )}
          </div>
        ))}
      </Slider>

      {overlayModule && (
        <OverlayModule
          isPreview={isPreview}
          scheme={scheme}
          isSlider={true}
          translatedModule={overlayModule}
          pageId={pageId}
        />
      )}
    </div>
  );
};

export default ImageSlider;
