import * as React from "react";
import { FunctionComponent, useEffect, RefObject } from "react";
import * as ClassNames from "classnames";
import { ColorScheme, PageState, Language, MainMenuItem } from "../types";
import MainMenuList from "./MainMenuList";
import { style } from "typestyle";
import { passiveEventIfSupported, getTranslations } from "../utils/utils";
import i18n from "../i18n";

interface Props {
  scheme: ColorScheme;
  isPreview: boolean;
  pages: PageState;
  languageId: Language;
  topLevelMainMenuItems: MainMenuItem[];
  activePagePath: string[];
  fallbackLanguageId: Language | undefined;
  isOpen: boolean;
  onClose: () => void;
  outerRef: RefObject<HTMLDivElement>;
  className?: string;
  showMenuCaption: boolean;
}

const HamburgerMenuList: FunctionComponent<Props> = ({
  scheme,
  isPreview,
  pages,
  languageId,
  topLevelMainMenuItems,
  activePagePath,
  fallbackLanguageId,
  isOpen,
  onClose,
  outerRef,
  className,
  showMenuCaption,
}) => {
  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      // Cast the target to HTMLElement,
      // see https://typescript.codeplex.com/discussions/432211
      const target = event.target as HTMLElement;

      // Close the menu if an area outside it was clicked
      const clickedOutside = !outerRef.current?.contains(target);
      clickedOutside && onClose();
    };

    document.addEventListener("click", handleClick, passiveEventIfSupported);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      event.keyCode === 27 && onClose();
    };

    document.addEventListener("keydown", onKeyDown, passiveEventIfSupported);

    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, []);

  return (
    <div
      className={ClassNames(
        style({
          backgroundColor: scheme.primary.background,
          color: scheme.primary.text,
        }),
        "HeaderModule__MainMenuHamburger__Box",
        {
          "HeaderModule__MainMenuHamburger__Box--open": isOpen,
        },
        className
      )}
    >
      {showMenuCaption && (
        <div className="HeaderModule__MainMenuHamburgerCaption">
          {getTranslations(languageId, i18n).menu}
        </div>
      )}
      <MainMenuList
        isPreview={isPreview}
        languageId={languageId}
        onMenuItemClick={onClose}
        menuItems={topLevelMainMenuItems}
        pages={pages}
        showIcons
        scheme={scheme}
        topHeaderVariant={"hamburger-nav"}
        activePagePath={activePagePath}
        fallbackLanguageId={fallbackLanguageId}
        repeatFirstLevelItem={false}
      />
    </div>
  );
};

export default HamburgerMenuList;
