import { User } from "../types";
import { getUserFromStorage } from "../utils/utils";
import { AllActions } from "../actions";

const initialState = getUserFromStorage();

const postLoginSuccess = (user: User): User => {
  return user;
};

const logoutUser = (state: User): User => {
  const newUser = { ...state, token: undefined };
  window.localStorage.setItem("user", JSON.stringify(newUser));
  return newUser;
};

const reducer = (state = initialState, action: AllActions): User => {
  switch (action.type) {
    case "POST_LOGIN_SUCCESS":
      return postLoginSuccess(action.user);

    case "LOGOUT_USER":
      return logoutUser(state);

    default:
      return state;
  }
};

export default reducer;
