import { LegalNavId } from "../types";

export type Action = {
  type: "TOGGLE_LEGAL_NAV";
  legalNavId: LegalNavId | undefined;
};

export const toggleLegalNav = (legalNavId: LegalNavId | undefined): Action => ({
  type: "TOGGLE_LEGAL_NAV",
  legalNavId,
});
