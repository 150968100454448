import * as React from "react";
import {
  FunctionComponent,
  MutableRefObject,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
  useRef,
} from "react";
import { MapStateToProps, connect, ConnectedProps } from "react-redux";
import {
  StoreState,
  Language,
  TranslatedModule,
  ImagesModuleSettings,
  MainMenuItem,
  Picture,
  ColorScheme,
  PageState,
  TranslatedPartnerLogo,
  HeaderMenuVariant,
} from "../types";
import { getActiveSite } from "../selectors/sites";
import {
  getFallbackLanguage,
  getNearestHeaderImagesModule,
  getTopLevelMainMenu,
  getMainPageUrl,
} from "../utils/utils";
import { throttle } from "throttle-debounce";
import HeaderLogo from "./HeaderLogo";
import { getPictureById } from "../selectors/pictures";
import SplitMainMenuList from "./SplitMainMenuList";
import LanguageMenu from "./LanguageMenu";
import { getSouthTyrolLogo } from "../selectors/partnerLogos";
import PartnerLogo from "./PartnerLogo";
import * as ClassNames from "classnames";
import { style } from "typestyle";
import { ResizeObserver } from "@juggle/resize-observer";

interface Props {
  languageId: Language;
  pageId: string;
  isPreview: boolean;
  scheme: ColorScheme;
  activePagePath: string[];
  variant: HeaderMenuVariant;
  outerWrapperRef: MutableRefObject<HTMLElement | null>;
  showSouthTyrolLogo: boolean;
}
interface StateProps {
  fallbackLanguageId: Language | undefined;
  imagesModule: TranslatedModule<ImagesModuleSettings> | undefined;
  topLevelMainMenuItems: MainMenuItem[];
  logo: Picture;
  mainPageUrl: string | undefined;
  pages: PageState;
  southTyrolLogo: TranslatedPartnerLogo | undefined;
}

type ReduxProps = ConnectedProps<typeof connector>;

const onLIRef = (
  refs: MutableRefObject<HTMLLIElement[]>,
  index: number,
  el: HTMLLIElement | null
) => el && (refs.current[index] = el);

interface ItemsDistribution {
  left: number;
  right: number;
}

const getSum = (input: number[]) =>
  input.reduce<number>((sum, value) => sum + value, 0);

const getFitsInWidth = (widths: number[], containerWidth: number): number => {
  const { amount } = widths.reduce<{ amount: number; sum: number }>(
    (carry, width) => {
      const newSum = carry.sum + width;
      return {
        sum: newSum,
        amount: carry.amount + (newSum + 5 < containerWidth ? 1 : 0),
      };
    },
    { amount: 0, sum: 0 }
  );
  return amount;
};

const calcItemsDistribution = ({
  rightMenuWrapper,
  leftMenuWrapper,
  menuItemEls,
}: {
  rightMenuWrapper: HTMLUListElement;
  leftMenuWrapper: HTMLUListElement;
  menuItemEls: HTMLLIElement[];
}): ItemsDistribution => {
  const menuItemsLength = menuItemEls.length;
  const leftWidth = leftMenuWrapper.scrollWidth;
  const rightWidth = rightMenuWrapper.scrollWidth;
  const menuItemWidths = menuItemEls.map(({ scrollWidth }) => scrollWidth);

  // Attempt to distribute the menu items evenly (or one more on the left
  // in clase of an odd amount of menu items)
  const evenlyRoundedLeft = Math.ceil(menuItemsLength / 2);
  const fitsEvenlyRounded =
    leftWidth > getSum(menuItemWidths.slice(0, evenlyRoundedLeft)) &&
    rightWidth > getSum(menuItemWidths.slice(evenlyRoundedLeft));

  if (fitsEvenlyRounded) {
    return {
      left: evenlyRoundedLeft,
      right: menuItemsLength - evenlyRoundedLeft,
    };
  }

  const leftAmount = getFitsInWidth(menuItemWidths, leftWidth);
  const availableRightMenuWidths = menuItemWidths.slice(leftAmount);
  const preliminaryRightAmount = getFitsInWidth(
    availableRightMenuWidths,
    rightWidth
  );
  const rightAmount =
    leftAmount + preliminaryRightAmount < menuItemsLength
      ? getFitsInWidth(
          availableRightMenuWidths,
          rightWidth
          // Shouldn’t be 0, otherwise it triggers an infinite render loop
        ) - 1 || 1
      : preliminaryRightAmount;

  return {
    left: leftAmount,
    right: rightAmount,
  };
};

const calcAndSetItemsDistribution = ({
  rightMenuWrapper,
  leftMenuWrapper,
  menuItemsRefs,
  itemsDistribution,
  setItemsDistribution,
}: {
  rightMenuWrapper: HTMLUListElement;
  leftMenuWrapper: HTMLUListElement;
  menuItemsRefs: MutableRefObject<HTMLLIElement[]>;
  itemsDistribution: ItemsDistribution;
  setItemsDistribution: Dispatch<SetStateAction<ItemsDistribution>>;
}) => {
  if (!menuItemsRefs.current) return;
  const { left, right } = calcItemsDistribution({
    leftMenuWrapper,
    menuItemEls: menuItemsRefs.current,
    rightMenuWrapper,
  });

  (itemsDistribution.left !== left || itemsDistribution.right !== right) &&
    setItemsDistribution({ left, right });
};

const initialItemsDistribution: ItemsDistribution = {
  left: 0,
  right: 0,
};

const SplitMainMenu: FunctionComponent<Props & ReduxProps> = ({
  topLevelMainMenuItems,
  logo,
  mainPageUrl,
  isPreview,
  fallbackLanguageId,
  languageId,
  scheme,
  pages,
  activePagePath,
  southTyrolLogo,
  variant,
  pageId,
  outerWrapperRef,
}) => {
  const prevItemsDistribution = useRef<ItemsDistribution>(
    initialItemsDistribution
  );
  const [itemsDistribution, setItemsDistribution] = useState<ItemsDistribution>(
    initialItemsDistribution
  );

  const [areItemsDistributed, setAreItemsDistributed] = useState(false);

  const currentAreItemsDistributed =
    (itemsDistribution.left !== 0 || itemsDistribution.right !== 0) &&
    itemsDistribution.left === prevItemsDistribution.current.left &&
    itemsDistribution.right === prevItemsDistribution.current.right;

  currentAreItemsDistributed !== areItemsDistributed &&
    setAreItemsDistributed(currentAreItemsDistributed);

  prevItemsDistribution.current = {
    left: itemsDistribution.left,
    right: itemsDistribution.right,
  };

  const [leftMenuWrapper, setLeftMenuWrapper] = useState<HTMLUListElement>();
  const [rightMenuWrapper, setRightMenuWrapper] = useState<HTMLUListElement>();
  const menuItemsRefs = useRef<HTMLLIElement[]>([]);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const observer = useRef<ResizeObserver>();

  // Handle resizing
  useEffect(() => {
    if (
      !wrapperRef.current ||
      observer.current ||
      !leftMenuWrapper ||
      !rightMenuWrapper
    ) {
      return;
    }

    const onResize = () =>
      calcAndSetItemsDistribution({
        leftMenuWrapper,
        menuItemsRefs,
        rightMenuWrapper,
        itemsDistribution,
        setItemsDistribution,
      });

    const onResizeThrottled = throttle(250, onResize);

    observer.current = new ResizeObserver(onResizeThrottled);
    observer.current?.observe(wrapperRef.current);
  }, [leftMenuWrapper, rightMenuWrapper, itemsDistribution]);

  // On unmounting the component
  useEffect(() => () => observer.current?.disconnect(), []);

  // Calculate the items distribution for the first time
  useEffect(() => {
    leftMenuWrapper &&
      rightMenuWrapper &&
      calcAndSetItemsDistribution({
        leftMenuWrapper,
        menuItemsRefs,
        rightMenuWrapper,
        itemsDistribution,
        setItemsDistribution,
      });
  }, [Boolean(leftMenuWrapper && rightMenuWrapper)]);

  return (
    <div className={`SplitMainMenu SplitMainMenu--${variant}`}>
      <div className="SplitMainMenu__FakeWrapper">
        <SplitMainMenuList
          className="SplitMainMenu__FakeList SplitMainMenu__List MainMenu__ListLevel0"
          isPreview={isPreview}
          languageId={languageId}
          pages={pages}
          menuItems={topLevelMainMenuItems}
          scheme={scheme}
          activePagePath={activePagePath}
          fallbackLanguageId={fallbackLanguageId}
          ulRef={undefined}
          liRef={(el, index) => onLIRef(menuItemsRefs, index, el)}
        />
      </div>

      <div
        className={ClassNames(
          "SplitMainMenu__Container",
          style({
            backgroundColor: scheme.main.background,
          })
        )}
      >
        <div className="SplitMainMenu__Side SplitMainMenu__Side--left">
          <LanguageMenu
            isPreview={isPreview}
            languageId={languageId}
            scheme={scheme}
            variant="horizontal"
            pageId={pageId}
          />
        </div>

        <div
          className={ClassNames("SplitMainMenu__OuterWrapper", {
            "SplitMainMenu__OuterWrapper--is-distributed": areItemsDistributed,
          })}
          ref={(el) => {
            wrapperRef.current = el;
            outerWrapperRef.current = el;
          }}
        >
          <div className="SplitMainMenu__Wrapper">
            <SplitMainMenuList
              className="SplitMainMenu__List SplitMainMenu__List--left MainMenu__ListLevel0"
              isPreview={isPreview}
              languageId={languageId}
              pages={pages}
              menuItems={topLevelMainMenuItems.slice(0, itemsDistribution.left)}
              scheme={scheme}
              activePagePath={activePagePath}
              fallbackLanguageId={fallbackLanguageId}
              ulRef={(el) => el && setLeftMenuWrapper(el)}
            />
          </div>
          <HeaderLogo
            logo={logo}
            mainPageUrl={mainPageUrl}
            isPreview={isPreview}
          />
          <div className="SplitMainMenu__Wrapper">
            <SplitMainMenuList
              className="SplitMainMenu__List SplitMainMenu__List--right MainMenu__ListLevel0"
              isPreview={isPreview}
              languageId={languageId}
              pages={pages}
              menuItems={topLevelMainMenuItems.slice(itemsDistribution.left)}
              scheme={scheme}
              activePagePath={activePagePath}
              fallbackLanguageId={fallbackLanguageId}
              ulRef={(el) => el && setRightMenuWrapper(el)}
            />
          </div>
        </div>
        <div className="SplitMainMenu__Side SplitMainMenu__Side--right">
          {southTyrolLogo && (
            <PartnerLogo
              className="SplitMainMenu__PartnerLogo"
              partnerLogo={southTyrolLogo}
              size="big"
            />
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  state,
  { languageId, pageId, isPreview, showSouthTyrolLogo }
) => {
  const {
    sites,
    pages,
    modules,
    mediaLibrary: { logoId, pictures },
  } = state;
  const site = getActiveSite(sites);

  const fallbackLanguageId = getFallbackLanguage(site, languageId);
  const imagesModule = getNearestHeaderImagesModule({
    pages,
    modules,
    currentPageId: pageId,
    languageId,
  });

  const topLevelMainMenuItems = getTopLevelMainMenu({
    pages,
    languageId,
    isPreview,
    fallbackLanguageId,
  });

  return {
    fallbackLanguageId,
    imagesModule,
    topLevelMainMenuItems,
    logo: getPictureById(pictures, logoId, { height: 100 }),
    mainPageUrl: getMainPageUrl(pages, languageId, isPreview),
    pages,
    siteLanguages: site.languages,
    southTyrolLogo: showSouthTyrolLogo
      ? getSouthTyrolLogo(state, languageId)
      : undefined,
  };
};

const connector = connect(mapStateToProps);

export default connector(SplitMainMenu);
