import * as React from "react";
import { FunctionComponent } from "react";
import { ColorScheme, MultiBoxItemData, AspectRatio, Language } from "../types";
import ConditionalLink from "./ConditionalLink";
import ImageWithCrop from "./ImageWithCrop";
import { isPlaceholder } from "../selectors/pictures";
import MultilineText from "./MultilineText";
import ReadMoreCollapse from "./ReadMoreCollapse";

interface Props {
  itemData: MultiBoxItemData;
  scheme: ColorScheme;
  imagesAspectRatio: AspectRatio;
  isPreview: boolean;
  isPlaceholderItem: boolean;
  languageId: Language;
  collapsedLinesCount: number | undefined;
  isEditing: boolean;
}

const MultiBoxItem: FunctionComponent<Props> = ({
  itemData: {
    title,
    textAlign,
    subtitle,
    picture,
    link = {},
    text,
    additionalInfo,
    imageSettings,
    buttons,
  },
  scheme,
  imagesAspectRatio,
  isPreview,
  isPlaceholderItem,
  languageId,
  collapsedLinesCount,
  isEditing,
}) => {
  const showPicture = !isPlaceholder(picture) || isPlaceholderItem;

  return (
    <section
      className="MultiBox__Item"
      style={{
        background: scheme.main.background,
        color: scheme.main.text,
      }}
    >
      <div className="MultiBox__Item__Inner">
        {showPicture && (
          <div className="MultiBox__Item__Picture">
            <ConditionalLink anchorAttrs={link} isPreview={isPreview}>
              <ImageWithCrop
                aspectRatio={imagesAspectRatio}
                picture={picture}
                width={1280}
                imageSettings={imageSettings}
                sizes="(min-width: 640px) 50vw, (min-width: 1024px) 33vw, (min-width: 1600px) 500px, 100vw"
              />
            </ConditionalLink>
          </div>
        )}

        <div className="MultiBox__Item__Content">
          <div>
            <header className="MultiBox__Item__Header">
              <ConditionalLink
                anchorAttrs={link}
                className="MultiBox__Item__HeaderLink"
                isPreview={isPreview}
              >
                <div className="MultiBox__Item__TitleOuter">
                  <h3
                    className={`MultiBox__Item__Title TextAlign--${textAlign.title}`}
                    style={{ color: scheme.main.title }}
                  >
                    {title}
                  </h3>
                </div>
              </ConditionalLink>
              {!!subtitle && (
                <div
                  className={`MultiBox__Item__Subtitle TextAlign--${textAlign.subtitle}`}
                >
                  {typeof subtitle === "string" ? (
                    <MultilineText text={subtitle} />
                  ) : (
                    subtitle
                  )}
                </div>
              )}
            </header>
            {text && (
              <div className="MultiBox__Item__Description">
                {collapsedLinesCount !== undefined ? (
                  <ReadMoreCollapse
                    scheme={scheme}
                    languageId={languageId}
                    collapsedLinesCount={collapsedLinesCount}
                    isEditing={isEditing}
                  >
                    {text}
                  </ReadMoreCollapse>
                ) : (
                  text
                )}
              </div>
            )}
          </div>
          <div className="MultiBox__Item__Bar">
            {additionalInfo}
            {buttons && (
              <div
                className={`ButtonGroup ButtonGroup--full-width ButtonGroup--align-${textAlign.buttons}`}
              >
                {buttons}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default MultiBoxItem;
