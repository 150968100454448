import { StoreState, GeneratorStoreState } from "../types";
import { combineReducers } from "redux";

import user from "./user";
import login from "./login";
import modules from "./modules";
import pages from "./pages";
import sites from "./sites";
import accommodation from "./accommodation";
import rooms from "./rooms";
import prices from "./prices";
import specials from "./specials";
import mediaLibrary from "./mediaLibrary";
import colorSchemes from "./colorSchemes";
import fonts from "./fonts";
import alerts from "./alerts";
import languages from "./languages";
import linkSelect from "./linkSelect";
import partners from "./partners";
import logos from "./logos";
import publish from "./publish";
import richEditor from "./richEditor";
import legalNav from "./legalNav";
import loadStates from "./loadStates";
import revisions from "./revisions";
import seo from "./seo";
import issues from "./issues";

export const adminReducer = combineReducers<StoreState>({
  accommodation,
  alerts,
  colorSchemes,
  fonts,
  languages,
  legalNav,
  linkSelect,
  login,
  partners,
  logos,
  mediaLibrary,
  modules,
  pages,
  publish,
  richEditor,
  rooms,
  prices,
  sites,
  specials,
  user,
  loadStates,
  revisions,
  seo,
  issues,
});

export const generatorReducer = combineReducers<GeneratorStoreState>({
  accommodation,
  colorSchemes,
  fonts,
  languages,
  legalNav,
  partners,
  logos,
  mediaLibrary,
  modules,
  pages,
  publish,
  richEditor,
  rooms,
  prices,
  sites,
  specials,
  loadStates,
});
