import * as React from "react";
import { FunctionComponent, useRef } from "react";
import { WindowState, WeatherWidgetSettings, WidgetInstance } from "../types";
import { getSupportedLanguage } from "../utils/utils";
import HgvWidgetContainer from "./HgvWidgetContainer";

interface Props {
  settings: WeatherWidgetSettings;
  className?: string;
}

const WeatherWidget: FunctionComponent<Props> = ({ className, settings }) => {
  const ref = useRef<HTMLDivElement>(null);
  const widgetInstanceRef = useRef<WidgetInstance>();

  const initWidget = () => {
    const { BookingSüdtirol } = (window as unknown) as WindowState;
    if (!ref.current) return;

    widgetInstanceRef.current = new BookingSüdtirol.Widgets.Weather(
      ref.current,
      {
        ...settings,
        lang: getSupportedLanguage(settings.lang, ["de", "it", "en"]),
      }
    );
  };

  return (
    <HgvWidgetContainer
      widgetInstance={widgetInstanceRef.current}
      initWidget={initWidget}
      settings={settings}
      enableLazyload={true}
    >
      <div
        className={className}
        style={{
          background: settings.colorScheme.main.background,
        }}
      >
        <div className="WeatherModule__Container" ref={ref} />
      </div>
    </HgvWidgetContainer>
  );
};

export default WeatherWidget;
