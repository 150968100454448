import {
  UsercentricsServicesMap,
  WindowState,
  UsercentricsConsentsChangedFinishedEvent,
  UsercentricsConsentName,
  UsercentricsInitEvent,
} from "../types";
import { isBrowser } from "./utils";

const eventName = "UsercentricsCustomEvent";

export const usercentricsDataProcessingServices = <const>[
  "Promotion",
  "Google Maps",
  "YouTube Video",
];

const getUsercentricsServiceMap = (): UsercentricsServicesMap => {
  if (!isBrowser) return {};

  const { usercentrics } = (window as unknown) as WindowState;
  const consents = usercentrics?.getConsents() ?? [];

  return consents.reduce<UsercentricsServicesMap>(
    (acc, { templateId, dataProcessingService }) => ({
      ...acc,
      [dataProcessingService]: templateId,
    }),
    {}
  );
};

export let usercentricsServiceMap: UsercentricsServicesMap = getUsercentricsServiceMap();

const getIsUsercentricsInitEvent = (
  event: Event
): event is UsercentricsInitEvent =>
  (<UsercentricsInitEvent>event)?.data?.event === "consents_initialized";

export const registerOnInitialized = (fn: () => void) => {
  const onUnmount = () => {
    window.removeEventListener(eventName, onInitialized);
  };

  const onInitialized = (event: Event) => {
    if (!getIsUsercentricsInitEvent(event)) return;
    fn();
    onUnmount();
  };

  isBrowser && window.addEventListener(eventName, onInitialized);

  return onUnmount;
};

registerOnInitialized(() => {
  // Save the consents once it was initialized
  usercentricsServiceMap = getUsercentricsServiceMap();
});

const getIsUsercentricsConsentsChangedFinishedEvent = (
  event: Event
): event is UsercentricsConsentsChangedFinishedEvent =>
  (<UsercentricsConsentsChangedFinishedEvent>event)?.data?.event ===
  "consents_changed_finished";

export const getUsercentricsConsent = (
  consentName: UsercentricsConsentName
): boolean => {
  const templateId = usercentricsServiceMap[consentName];
  if (!isBrowser || !templateId) return false;

  const { usercentrics } = (window as unknown) as WindowState;

  const consentStatus =
    usercentrics?.getConsents(templateId).consentStatus ?? false;

  return consentStatus;
};

export const registerUserCentricsListener = (
  key: UsercentricsConsentName,
  onConsented: () => void
) => {
  const onEvent = (event: Event) => {
    (getIsUsercentricsConsentsChangedFinishedEvent(event) ||
      getIsUsercentricsInitEvent(event)) &&
      event.data[key] &&
      onConsented();
  };

  window.addEventListener(eventName, onEvent);

  return {
    unmount: () => {
      window.removeEventListener(eventName, onEvent);
    },
  };
};
