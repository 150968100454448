import { Rooms, Language, Room, RoomsOfLanguage } from "../types";
import { AllActions } from "../actions";

const initialState: Rooms = {};

const getRoomsSuccess = (
  state: Rooms,
  languageId: Language,
  rooms: Room[]
): Rooms => {
  const roomsOfLanguage = rooms.reduce<RoomsOfLanguage>(
    (acc, room) => {
      const allIds: number[] = [...acc.allIds, room.id];
      const byId: RoomsOfLanguage["byId"] = {
        ...acc.byId,
        [room.id]: room,
      };
      return {
        allIds,
        byId,
      };
    },
    {
      allIds: [],
      byId: {},
    }
  );

  return { ...state, [languageId]: roomsOfLanguage };
};

const reducer = (state = initialState, action: AllActions) => {
  switch (action.type) {
    case "GET_ROOMS_SUCCESS":
      return getRoomsSuccess(state, action.languageId, action.rooms);

    default:
      return state;
  }
};

export default reducer;
