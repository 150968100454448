import { Accommodation, AccommodationState, Language } from "../types";
import { AllActions } from "../actions";
import { Reducer } from "redux";

const initialState: AccommodationState = {};

const getAccommodationSuccess = (
  state: AccommodationState,
  languageId: Language,
  accommodation: Accommodation
): AccommodationState => {
  return { ...state, [languageId]: accommodation };
};

const reducer: Reducer<AccommodationState, AllActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "GET_ACCOMMODATION_SUCCESS":
      return getAccommodationSuccess(
        state,
        action.languageId,
        action.accommodation
      );

    default:
      return state;
  }
};

export default reducer;
