import { LoadStatus, ThunkAction } from "../types";
import { insertScriptIntoHead } from "../utils/utils";

export type Action = {
  type: "LOAD_SCRIPT";
  id: string;
  status: LoadStatus;
};

/**
 * Injects a script into <head> and resolve the promise as soon as it’s loaded
 * @param url The script url
 * @param id An unique id for the script. The url becomes the id if omitted
 */
export const injectScript = (
  url: string,
  id = url
): ThunkAction<Promise<void>> => async (dispatch, getState) => {
  const loadState = getState().loadStates.scripts[id];
  if (loadState === "loaded" || loadState === "loading") {
    return;
  }
  dispatch(loadScript(id, "loading"));
  try {
    await insertScriptIntoHead(url, document);
    dispatch(loadScript(id, "loaded"));
  } catch (error) {
    dispatch(loadScript(id, "unloaded"));
  }
};

export const loadScript = (id: string, status: LoadStatus): Action => ({
  type: "LOAD_SCRIPT",
  id,
  status,
});
