import * as React from "react";
import { FunctionComponent, useEffect, useRef } from "react";
import * as ClassNames from "classnames";

/**
 * Sets up an event listener for lazy loading with lazysizes.
 * @returns A function to remove the event listener. To be called on component unmount
 */
const onLazyload = (el: Element | null, callback: () => void) => {
  const onLoaded = () => {
    el?.removeEventListener("lazybeforeunveil", onLoaded);
    callback();
  };

  if (!el || el.classList.contains("lazyloaded")) {
    callback();
  } else {
    el.addEventListener("lazybeforeunveil", onLoaded);
  }

  return {
    unmount: () => el?.removeEventListener("lazybeforeunveil", onLoaded),
  };
};

interface Props {
  onLoad: (isLazyloaded: true) => void;
  className?: string;
}

const LazyloadWrapper: FunctionComponent<Props> = ({
  children,
  onLoad,
  className,
}) => {
  const elRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const lazyRef = onLazyload(elRef.current, () => onLoad(true));

    return () => {
      lazyRef.unmount();
    };
  }, []);

  return (
    <div ref={elRef} className={ClassNames(className, "lazyload")}>
      {children}
    </div>
  );
};

export default LazyloadWrapper;
