import { ModulesBySiteModuleType, ModuleType } from "../../types";
import { AllActions } from "../../actions";
import { APIModule } from "../../../server/types";
import { Reducer } from "redux";
import { creatableGlobalModules, removeFromArray } from "../../utils/utils";

export const initialState: ModulesBySiteModuleType = {};

const getSiteModulesSuccess = (modules: APIModule[]): ModulesBySiteModuleType =>
  modules.reduce<ModulesBySiteModuleType>((newState, module) => {
    // Don’t include submodules
    if (module.parentId !== null) return newState;

    const clone = { ...newState };
    clone[module.type] = [...(clone[module.type] ?? []), module.id];

    return clone;
  }, {});

const postModuleStart = ({
  state,
  moduleId,
  moduleType,
  pageId,
}: {
  state: ModulesBySiteModuleType;
  moduleId: string;
  moduleType: ModuleType;
  pageId: string | null;
}): ModulesBySiteModuleType => {
  const isTopLevelGlobal =
    pageId === null && creatableGlobalModules.includes(moduleType);
  if (!isTopLevelGlobal) return state;

  const newState = { ...state };
  newState[moduleType] = [...(newState[moduleType] ?? []), moduleId];

  return newState;
};

const deleteModuleTranslation = ({
  state,
  moduleType,
  isUntranslated,
  moduleId,
}: {
  state: ModulesBySiteModuleType;
  moduleType: ModuleType;
  isUntranslated: boolean;
  moduleId: string;
}): ModulesBySiteModuleType => {
  if (!isUntranslated || !creatableGlobalModules.includes(moduleType)) {
    return state;
  }

  const newState = { ...state };
  const newModuleIds = removeFromArray<string>(
    newState[moduleType] ?? [],
    moduleId
  );

  if (newModuleIds.length) {
    newState[moduleType] = newModuleIds;
    return newState;
  }

  delete newState[moduleType];
  return newState;
};

const reducer: Reducer<ModulesBySiteModuleType, AllActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "GET_SITE_MODULES_SUCCESS":
      return getSiteModulesSuccess(action.modules);

    case "POST_MODULE_START":
      return postModuleStart({
        state,
        moduleId: action.moduleId,
        pageId: action.pageId,
        moduleType: action.moduleType,
      });

    case "DELETE_MODULE_TRANSLATION_START":
      return deleteModuleTranslation({
        state,
        moduleType: action.moduleType,
        isUntranslated: action.deleteAllTranslations,
        moduleId: action.moduleId,
      });

    default:
      return state;
  }
};

export default reducer;
