import * as React from "react";
import { FunctionComponent, useRef, memo } from "react";
import * as ClassNames from "classnames";
import { LazyImageProps } from "../types";

const LazyImage: FunctionComponent<LazyImageProps> = ({
  src,
  alt,
  className,
  sizes,
  srcSet,
}) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const isLazyLoaded =
    imgRef.current?.classList.contains("lazyloaded") ?? false;

  return (
    <img
      ref={imgRef}
      className={ClassNames(className, "lazyload")}
      src={isLazyLoaded ? src : "/transparent-pixel.png"}
      data-src={src}
      srcSet={isLazyLoaded ? srcSet : undefined}
      data-srcset={srcSet}
      sizes={sizes}
      alt={alt ?? ""}
    />
  );
};

export default memo(LazyImage);
