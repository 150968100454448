import * as React from "react";
import {
  createElement,
  FunctionComponent,
  useRef,
  useEffect,
  useState,
} from "react";
import Icon from "./Icon";
import {
  ColorScheme,
  StoreState,
  Module,
  Language,
  BaseModuleProps,
  ContainerQueries,
  ThunkDispatch,
  AllModulesByType,
} from "../types";
import { connect, ConnectedProps, MapStateToProps } from "react-redux";
import { getTranslatedModule, scrollToElSingle } from "../utils/utils";
import { toggleLegalNav } from "../actions/LegalNav";
import { getPageModules } from "../selectors/modules";
import { mostModulesByType } from "../utils/mostModulesByType";

interface Props {
  colorScheme: ColorScheme;
  languageId: Language;
  isPreview: boolean;
  isLayoutPreview: boolean;
  queries: ContainerQueries;
  isActive: boolean;
  isFirstOnPage: boolean;
  siteId: string;
}

interface StateProps {
  pageId: string | undefined;
  isOpen: boolean;
  modules: Module[];
}

type ReduxProps = ConnectedProps<typeof connector>;

type DispatchProps = {
  onClose: () => void;
};

const getModulesByType = async (): Promise<AllModulesByType> => {
  const { systemPageModulesByType } = await import(
    "../utils/systemPageModulesByType"
  );

  return {
    ...mostModulesByType,
    ...systemPageModulesByType,
  };
};

const InlineTerms: FunctionComponent<Props & ReduxProps> = ({
  modules,
  onClose,
  colorScheme,
  queries,
  isOpen,
  isPreview,
  pageId,
  languageId,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [modulesByType, setModulesByType] = useState<AllModulesByType>();

  useEffect(() => {
    pageId && getModulesByType().then((result) => setModulesByType(result));
  }, [pageId]);

  useEffect(() => {
    pageId &&
      ref.current &&
      modulesByType &&
      scrollToElSingle(ref.current, {
        block: "start",
        inline: "start",
      });
  }, [pageId, modulesByType]);

  if (!pageId || !isOpen) return null;

  return (
    <div
      className="InlineTerms"
      ref={ref}
      style={{
        borderColor: colorScheme.primary.background,
      }}
    >
      <div className="InlineTerms__Content">
        {modulesByType &&
          modules.map((module) =>
            createElement<BaseModuleProps>(modulesByType[module.type], {
              key: module.id,
              translatedModule: getTranslatedModule(module, languageId),
              pageId,
              queries,
              isPreview,
              isLayoutPreview: false,
              isActive: false,
              isFirstOnPage: false,
              activeModuleId: undefined,
            })
          )}
      </div>
      <button
        className="InlineTerms__Close"
        onClick={onClose}
        style={{
          background: colorScheme.primary.background,
          fill: colorScheme.primary.text,
          color: colorScheme.primary.text,
        }}
      >
        <Icon glyph="close" />
      </button>
    </div>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = ({
  pages,
  modules,
  legalNav,
}): StateProps => {
  const pageId = legalNav.id ? pages.byAlias[legalNav.id] : undefined;
  return {
    pageId,
    isOpen: !!legalNav.id,
    modules: pageId ? getPageModules(modules, pageId) : [],
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
  onClose: () => dispatch(toggleLegalNav(undefined)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(InlineTerms);
