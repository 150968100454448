import * as React from "react";
import { FunctionComponent, useState } from "react";
import {
  BaseModuleProps,
  NewsletterModuleSettings,
  StoreState,
  ColorScheme,
  FormSubmitState,
  NewsletterModuleFieldSettings,
} from "../../types";
import ModuleWithHeadings from "../ModuleWithHeadings";
import { ConnectedProps, MapStateToProps, connect } from "react-redux";
import {
  getActiveColorScheme,
  getTranslations,
  scrollToElSingle,
} from "../../utils/utils";
import { getActiveSite } from "../../selectors/sites";
import ModuleHeadings from "../ModuleHeadings";
import i18n from "../../i18n";
import RichEditorWrapper from "../RichEditorWrapper";
import NewsletterForm from "../NewsletterForm";
import { getNewsletterFieldSettings } from "../../selectors/modules";

type Props = BaseModuleProps<NewsletterModuleSettings>;

type ReduxProps = ConnectedProps<typeof connector>;

interface StateProps {
  scheme: ColorScheme;
  fieldSettings: NewsletterModuleFieldSettings;
}

const NewsletterModule: FunctionComponent<Props & ReduxProps> = ({
  translatedModule,
  translatedModule: {
    id,
    siteId,
    settings: { textAlign, layout },
    translation: {
      languageId,
      settings: { title, subtitle },
    },
  },
  isFirstOnPage,
  scheme,
  isPreview,
  pageId,
  fieldSettings,
  queries,
}) => {
  const [submitState, setSubmitState] = useState<FormSubmitState>("initial");

  const trans = getTranslations(languageId, i18n);
  const formId = `newsletter-${id}`;

  const showFieldBackgrounds = layout === "layout-2" || layout === "layout-4";
  const isWideLayout = layout === "layout-3" || layout === "layout-4";

  return (
    <ModuleWithHeadings
      title={title}
      subtitle={subtitle}
      id={id}
      className="NewsletterModule"
      style={{
        background: scheme.main.background,
        color: scheme.main.text,
      }}
    >
      <div className="Module__Wrapper">
        <ModuleHeadings
          scheme={scheme}
          isFirstOnPage={isFirstOnPage}
          textAlign={textAlign}
          title={title}
          subtitle={subtitle}
        />

        <RichEditorWrapper
          module={translatedModule}
          pageId={pageId}
          languageId={languageId}
          isPreview={isPreview}
          scheme={scheme}
          className={`TextAlign--${textAlign.description}`}
        />
      </div>
      <div className="Module__Wrapper">
        {submitState !== "submitted" && (
          <NewsletterForm
            formId={formId}
            languageId={languageId}
            siteId={siteId}
            trans={trans}
            submitState={submitState}
            setSubmitState={setSubmitState}
            isPreview={isPreview}
            fieldSettings={fieldSettings}
            scheme={scheme}
            showFieldBackgrounds={showFieldBackgrounds}
            isWideLayout={isWideLayout}
            queries={queries}
          />
        )}
        {submitState === "submitted" && (
          <p
            ref={(el) => el && scrollToElSingle(el)}
            className="NewsletterModule__Message"
          >
            {trans.newsletterMessages.success}
          </p>
        )}
        {submitState === "error" && (
          <p className="NewsletterModule__Message">
            {trans.newsletterMessages.error}
          </p>
        )}
      </div>
    </ModuleWithHeadings>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { colorSchemes, sites, modules },
  { translatedModule }
): StateProps => ({
  scheme: getActiveColorScheme(
    colorSchemes,
    getActiveSite(sites),
    translatedModule
  ),
  fieldSettings: getNewsletterFieldSettings(
    modules,
    translatedModule.translation.languageId
  ),
});

const connector = connect(mapStateToProps);

export default connector(NewsletterModule);
