import * as React from "react";
import { FunctionComponent } from "react";
import { ColorScheme, NavLinkWithIcon } from "../types";
import Icon from "./Icon";
import SmartLink from "./SmartLink";
import * as ClassNames from "classnames";
import { style } from "typestyle";

interface Props {
  scheme: ColorScheme;
  navLinks: NavLinkWithIcon[];
  isPreview: boolean;
}

const HeaderSidebarIconNav: FunctionComponent<Props> = ({
  scheme,
  navLinks,
  isPreview,
}) => {
  if (!navLinks.length) return null;

  return (
    <ul
      className={ClassNames(
        "HeaderSidebarIconNav",
        style({
          color: scheme.primary.text,
        })
      )}
    >
      {navLinks.map(({ href, icon, children }, index) => (
        <li key={index} className={"HeaderSidebarIconNav__ListItem"}>
          <SmartLink
            anchorAttrs={{
              className: "HeaderSidebarIconNav__Item",
              href,
            }}
            isPreview={isPreview}
          >
            <Icon
              className={ClassNames(
                "HeaderSidebarIconNav__Icon",
                style({
                  fill: scheme.main.text,
                })
              )}
              glyph={icon}
            />
            <span
              className={ClassNames(
                "HeaderSidebarIconNav__Text",
                style({
                  backgroundColor: scheme.primary.background,
                  $nest: {
                    "&::before": {
                      borderRightColor: scheme.primary.background,
                    },
                  },
                })
              )}
            >
              {children}
            </span>
          </SmartLink>
        </li>
      ))}
    </ul>
  );
};

export default HeaderSidebarIconNav;
