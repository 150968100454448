import * as React from "react";
import { FunctionComponent, MutableRefObject } from "react";
import { Language } from "../types";
import Icon from "./Icon";
import { getTranslations } from "../utils/utils";
import i18n from "../i18n";

interface Props {
  languageId: Language;
  isOpen: boolean;
  onClick: () => void;
  innerRef?: MutableRefObject<HTMLElement | null>;
}

const FullMenuButton: FunctionComponent<Props> = ({
  languageId,
  isOpen,
  onClick,
  innerRef,
}) => {
  const { menu } = getTranslations(languageId, i18n);

  return (
    <button
      ref={(el) => {
        if (!innerRef) return;
        innerRef.current = el;
      }}
      onClick={onClick}
      type="button"
      className="Btn--bare FullHeaderOverlay__MenuButton"
    >
      <span className="FullHeaderOverlay__MenuButtonInner">
        <Icon
          className="FullHeaderOverlay__MenuButtonIcon"
          glyph={isOpen ? "close" : "view-headline"}
        />
      </span>
      <span className="FullHeaderOverlay__MenuButtonText">{menu}</span>
    </button>
  );
};

export default FullMenuButton;
