import * as React from "react";
import { FunctionComponent } from "react";
import {
  AspectRatio,
  Picture,
  ImageModuleSettings,
  StoreState,
  TranslatedModule,
  OverlayModuleSettings,
  ColorScheme,
} from "../../types";
import { connect } from "react-redux";
import { getPictureById } from "../../selectors/pictures";
import ImageWithCrop from "../ImageWithCrop";
import { getRecursiveOverlayModule } from "../../utils/utils";
import OverlayModule from "./OverlayModule";

interface Props {
  aspectRatio: AspectRatio;
  width: number;
  lazyLoad?: boolean;
  sizes: string;
  translatedModule: TranslatedModule<ImageModuleSettings>;
  scheme: ColorScheme;
  pageId: string;
  isSlider: boolean;
  isPreview: boolean;
  showOverlay: boolean;
}

interface StateProps {
  picture: Picture;
  overlayModule: TranslatedModule<OverlayModuleSettings> | undefined;
}

const ImageModule: FunctionComponent<Props & StateProps> = ({
  aspectRatio,
  width,
  translatedModule: { settings },
  picture,
  sizes,
  lazyLoad = true,
  scheme,
  overlayModule,
  pageId,
  isSlider,
  isPreview,
  showOverlay,
}) => (
  <>
    <ImageWithCrop
      picture={picture}
      aspectRatio={aspectRatio}
      sizes={sizes}
      lazyLoad={lazyLoad}
      width={width}
      imageSettings={settings}
    />
    {showOverlay && overlayModule && (
      <OverlayModule
        isPreview={isPreview}
        isSlider={isSlider}
        translatedModule={overlayModule}
        pageId={pageId}
        scheme={scheme}
      />
    )}
  </>
);

const mapStateToProps = (
  { mediaLibrary, modules }: StoreState,
  {
    translatedModule: {
      settings: { pictureId },
      id,
      translation: { languageId },
    },
    pageId,
  }: Props
): StateProps => {
  const overlayModule = getRecursiveOverlayModule({
    languageId,
    modules,
    pageId,
    parentId: id,
  });

  return {
    picture: getPictureById(mediaLibrary.pictures, pictureId),
    overlayModule,
  };
};

export default connect(mapStateToProps)(ImageModule);
