import * as React from "react";
import { FunctionComponent } from "react";
import * as ClassNames from "classnames";
import {
  PageState,
  Language,
  MainMenuItem,
  LeftOverlayNavIcons,
  IconName,
} from "../types";
import { getMenuItems } from "../utils/utils";
import SmartLink from "./SmartLink";
import Icon from "./Icon";

const getIcon = (iconType: LeftOverlayNavIcons): IconName => {
  switch (iconType) {
    case "arrow":
      return "arrow-right";
    case "plus":
      return "plus-sign";
  }
};

interface Props {
  isPreview: boolean;
  languageId: Language;
  parentId: string;
  pages: PageState;
  currentMenuItem: MainMenuItem;
  activePagePath: string[];
  fallbackLanguageId: Language | undefined;
  iconType: LeftOverlayNavIcons;
  onOpen: () => void;
}

const LeftOverlayNavItemTopLevel: FunctionComponent<Props> = ({
  isPreview,
  languageId,
  parentId,
  pages,
  currentMenuItem,
  activePagePath,
  fallbackLanguageId,
  iconType,
  onOpen,
}) => {
  const { title, href, id, isFolder, target } = currentMenuItem;
  const menuItems = getMenuItems({
    pages,
    pageId: parentId,
    languageId,
    isPreview,
    fallbackLanguageId,
  });

  const hasSubpages = !!menuItems.length;
  const isActive = activePagePath.includes(id);

  const linkItemClassName = ClassNames(
    "MainMenu__Item",
    "MainMenu__ItemLevel0",
    {
      "MainMenu__Item--active": isActive,
    }
  );

  const link = (
    <SmartLink
      isPreview={isPreview}
      anchorAttrs={{
        href: hasSubpages || (!isPreview && isFolder) ? undefined : href,
        target,
        className: linkItemClassName,
      }}
    >
      <span className="MainMenu__Item__Text">
        {title}
        {hasSubpages && (
          <Icon className="LeftOverlayNav__Icon" glyph={getIcon(iconType)} />
        )}
      </span>
    </SmartLink>
  );

  return (
    <li className="MainMenu__ListItem MainMenu__ListItemLevel0">
      <div className="MainMenu__ItemWrap">
        {hasSubpages ? (
          <>
            <button
              type="button"
              className="Btn--bare LeftOverlayNav__Btn"
              onClick={onOpen}
            >
              {link}
            </button>
          </>
        ) : (
          link
        )}
      </div>
    </li>
  );
};

export default LeftOverlayNavItemTopLevel;
