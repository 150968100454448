import { ColorScheme, ColorSchemeCategory } from "../types";
import { AllActions } from "../actions";
import { ColorSchemesAPI } from "../../server/types";

export const defaultColorScheme: ColorScheme = {
  main: {
    background: "#ffffff",
    title: "#9b4341",
    text: "#5e6d82",
    separator: "#b79f73",
  },
  primary: {
    background: "#9b4341",
    text: "#ffffff",
  },
  secondary: {
    background: "#b79f73",
    text: "#ffffff",
  },
};

const initialState: ColorSchemesAPI = {
  defaultColorScheme,
  schemes: {},
};

const changeColor = (
  state: ColorSchemesAPI,
  schemeId: string,
  categoryId: ColorSchemeCategory,
  colorType: string,
  color: string
) => {
  const category = {
    ...state.schemes[schemeId][categoryId],
    [colorType]: color,
  };

  return {
    ...state,
    schemes: {
      ...state.schemes,
      [schemeId]: {
        ...state.schemes[schemeId],
        [categoryId]: category,
      },
    },
  };
};

const duplicateColorSchemeStart = (
  state: ColorSchemesAPI,
  schemeId: string,
  newId: string
): ColorSchemesAPI => {
  const newScheme = state.schemes[schemeId];

  return { ...state, schemes: { ...state.schemes, [newId]: newScheme } };
};

const getColorSchemesSuccess = (
  state: ColorSchemesAPI,
  serverSchemes: ColorSchemesAPI
): ColorSchemesAPI => {
  return { ...state, ...serverSchemes };
};

const putColorSchemeStart = (
  state: ColorSchemesAPI,
  colorSchemeId: string,
  scheme: ColorScheme
): ColorSchemesAPI => {
  return {
    ...state,
    schemes: { ...state.schemes, [colorSchemeId]: scheme },
  };
};

const deleteColorSchemeStart = (
  state: ColorSchemesAPI,
  colorSchemeId: string
): ColorSchemesAPI => {
  const newSchemes = { ...state.schemes };
  delete newSchemes[colorSchemeId];

  return { ...state, schemes: newSchemes };
};

const updateColorScheme = (
  state: ColorSchemesAPI,
  colorSchemeId: string,
  scheme: ColorScheme
): ColorSchemesAPI => {
  if (!state.schemes[colorSchemeId]) return state;

  return {
    ...state,
    schemes: {
      ...state.schemes,
      [colorSchemeId]: scheme,
    },
  };
};

const deleteColorSchemeError = (
  prevState: ColorSchemesAPI
): ColorSchemesAPI => {
  return prevState;
};

const reducer = (state = initialState, action: AllActions) => {
  switch (action.type) {
    case "CHANGE_COLOR":
      return changeColor(
        state,
        action.schemeId,
        action.categoryId,
        action.colorType,
        action.color
      );

    case "DUPLICATE_COLOR_SCHEME_START":
      return duplicateColorSchemeStart(state, action.schemeId, action.newId);

    case "GET_COLOR_SCHEMES_SUCCESS":
      return getColorSchemesSuccess(state, action.serverSchemes);

    case "PUT_COLOR_SCHEME_START":
      return putColorSchemeStart(state, action.colorSchemeId, action.scheme);

    case "DELETE_COLOR_SCHEME_START":
      return deleteColorSchemeStart(state, action.colorSchemeId);

    case "DELETE_COLOR_SCHEME_ERROR":
      return deleteColorSchemeError(action.prevState);

    case "UPDATE_COLOR_SCHEME":
      return updateColorScheme(state, action.colorSchemeId, action.scheme);

    default:
      return state;
  }
};

export default reducer;
