import * as React from "react";
import { createElement, FunctionComponent, useEffect, useRef } from "react";
import { connect, MapStateToProps, ConnectedProps } from "react-redux";
import {
  Language,
  TranslatedPage,
  BaseModuleProps,
  GeneratorStoreState,
  ModuleSection,
  TranslatedModule,
  ModuleSettingWithTitle,
  WindowState,
  AllModulesByType,
  Modules,
  ModuleType,
  Module,
} from "../types";
import ContainerQueries from "./ContainerQueries";
import {
  getTranslatedModule,
  setCookie,
  getFallbackLanguage,
  checkIsHomePage,
  passiveEventIfSupported,
  getSubModulesIds,
} from "../utils/utils";
import * as ClassNames from "classnames";

import {
  createModuleSections,
  getIsHeaderWithFixedSidebar,
} from "../selectors/modules";
import { getActiveSite } from "../selectors/sites";
import {
  getUsercentricsConsent,
  registerUserCentricsListener,
} from "../utils/usercentrics";

const setPromotionCookie = (searchParams: URLSearchParams) => {
  const utm_source = searchParams.get("utm_source");
  const utm_medium = searchParams.get("utm_medium");
  const utm_campaign = searchParams.get("utm_campaign");

  utm_source &&
    utm_medium &&
    setCookie(
      "bs_widget_promotion",
      [utm_medium, utm_source, utm_campaign].filter(Boolean).join(","),
      30
    );
};

interface Props {
  page: TranslatedPage;
  modulesByType: Partial<AllModulesByType>;
}

interface StateProps {
  moduleSections: ModuleSection[];
  languageId: Language;
  fallbackLanguageId: Language | undefined;
  isHomePage: boolean;
  isHeaderWithSidebar: boolean;
}

type ReduxProps = ConnectedProps<typeof connector>;

const Generator: FunctionComponent<Props & ReduxProps> = ({
  page: { id: pageId },
  moduleSections,
  languageId,
  fallbackLanguageId,
  isHomePage,
  isHeaderWithSidebar,
  modulesByType,
}) => {
  const dummy100vhEl = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const currentWindow = (window as unknown) as WindowState;
    currentWindow.BookingSüdtirolTrackingConsent = false;

    const isConsented = getUsercentricsConsent("Promotion");
    const searchParams = new URLSearchParams(window.location.search);

    isConsented && setPromotionCookie(searchParams);

    // Fire a Google Tag Manager (GTM) event after the cookie is set
    // (or not set due to empty values).
    // This is necessary for GTM tags which need to read the value
    // of the bs_widget_promotion cookie, e.g. the ReGuest Messenger Widget.
    ((window as unknown) as WindowState).dataLayer?.push({
      event: "BsPromotionSet",
    });

    const usercentricsHandler = isConsented
      ? undefined
      : registerUserCentricsListener("Promotion", () =>
          setPromotionCookie(searchParams)
        );

    return () => {
      usercentricsHandler?.unmount();
    };
  }, []);

  useEffect(() => {
    if (!dummy100vhEl.current) return;
    const height = dummy100vhEl.current.scrollHeight;
    const windowHeight = window.innerHeight;
    if (height === windowHeight) return;

    const vhProperty = "--vh";
    const vh = windowHeight * 0.01;
    document.documentElement.style.setProperty(vhProperty, `${vh}px`);

    const onResize = () => {
      document.documentElement.style.removeProperty(vhProperty);
      document.removeEventListener("resize", onResize);
    };

    document.addEventListener("resize", onResize, passiveEventIfSupported);
  }, []);

  return (
    <ContainerQueries
      className={ClassNames("Site", { "Site--is-home": isHomePage })}
      useViewportWidth={true}
    >
      {(queries) => (
        <div
          className={ClassNames("Site__Inner", {
            "Site__Inner--with-sidebar": isHeaderWithSidebar,
          })}
        >
          <div ref={dummy100vhEl} className="dummy-100vh"></div>

          {moduleSections.map((moduleSection) => {
            const translatedModules = moduleSection.items.map((currentModule) =>
              getTranslatedModule(currentModule, languageId, fallbackLanguageId)
            );

            const firstTitleIndex = getFirstModuleWithTitleIndex(
              translatedModules
            );

            return translatedModules.map((translatedModule, index) => {
              if (!translatedModule.translation.exists) return null;
              const pageModule = modulesByType[translatedModule.type];

              return !pageModule
                ? null
                : createElement<BaseModuleProps>(pageModule, {
                    key: translatedModule.id + languageId,
                    translatedModule,
                    isPreview: false,
                    isLayoutPreview: false,
                    queries,
                    pageId,
                    isActive: false,
                    isFirstOnPage: firstTitleIndex === index,
                    activeModuleId: undefined,
                  });
            });
          })}
        </div>
      )}
    </ContainerQueries>
  );
};

/**
 * Check if module types that make no sense
 * without submodules have at least one submodule
 */
const checkHasSubmodules = (
  modules: Modules,
  pageId: string,
  { id, type }: Module
): boolean => {
  const moduleTypes: ModuleType[] = [
    "HighlightsModule",
    "ImageGalleryModule",
    "ImagesModule",
    "WeatherWebcamModule",
  ];

  if (!moduleTypes.includes(type)) return true;

  const subModuleIds = getSubModulesIds({
    byParentId: modules.byParentId,
    pageId,
    parentId: id,
  });

  return !!subModuleIds.length;
};

/**
 * Filter out modules which must be hidden on the published website
 */
const filterModuleSections = ({
  modules,
  moduleSections,
  pageId,
}: {
  modules: Modules;
  moduleSections: ModuleSection[];
  pageId: string;
}): ModuleSection[] =>
  moduleSections.map((moduleSection) => {
    const filteredItems = moduleSection.items.filter((currentModule) =>
      checkHasSubmodules(modules, pageId, currentModule)
    );

    return { ...moduleSection, items: filteredItems };
  });

const checkIsNotNull = (value: string | null): value is string =>
  value !== null;

const mapStateToProps: MapStateToProps<
  StateProps,
  Props,
  GeneratorStoreState
> = ({ modules, sites, pages }, { page }): StateProps => {
  const { languageId } = page.translation;
  const site = getActiveSite(sites);
  const moduleSections = createModuleSections({
    modules,
    pageId: page.id,
    popUpModuleIds: [page.popUpModuleId, site.popUpModuleId]
      .filter(checkIsNotNull)
      .slice(0, 1),
  });
  const fallbackLanguageId = getFallbackLanguage(site, languageId);

  return {
    moduleSections: filterModuleSections({
      modules,
      moduleSections,
      pageId: page.id,
    }),
    languageId,
    fallbackLanguageId,
    isHomePage: checkIsHomePage(pages, page.id),
    isHeaderWithSidebar: getIsHeaderWithFixedSidebar(modules),
  };
};

const checkHasModuleTitle = (
  translatedModule: TranslatedModule
): translatedModule is TranslatedModule<ModuleSettingWithTitle> =>
  !!(translatedModule as TranslatedModule<ModuleSettingWithTitle>).translation
    .settings.title;

const getFirstModuleWithTitleIndex = (translatedModules: TranslatedModule[]) =>
  translatedModules.reduce<number | undefined>(
    (savedIndex, translatedModule, index) => {
      if (checkHasModuleTitle(translatedModule) && savedIndex === undefined) {
        return index;
      }

      return savedIndex;
    },
    undefined
  );

const connector = connect(mapStateToProps);

export default connector(Generator);
