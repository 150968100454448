import * as React from "react";
import { FunctionComponent } from "react";
import Button from "./Button";
import { ColorScheme, ActionLinkItem } from "../types";
import * as ClassNames from "classnames";

interface Props {
  scheme: ColorScheme | undefined;
  isPreview: boolean;
  actionLinks: ActionLinkItem[];
  className?: string;
  onItemClick?: () => void;
}

const ActionModuleNavigation: FunctionComponent<Props> = ({
  scheme,
  isPreview,
  actionLinks,
  className,
  onItemClick,
}) => (
  <div className={ClassNames("ActionModuleNavigation", className)}>
    {actionLinks.map(({ children, href, type }, index) => (
      <Button
        className="ActionModuleNavigation__Item"
        key={index}
        type={type === "enquiry" ? "secondary" : "primary"}
        anchorAttrs={{
          href,
          onClick: onItemClick,
        }}
        scheme={scheme}
        isPreview={isPreview}
      >
        {children}
      </Button>
    ))}
  </div>
);

export default ActionModuleNavigation;
