import * as React from "react";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import {
  ColorScheme,
  StoreState,
  BaseModuleProps,
  HTMLModuleSettings,
} from "../../types";
import { connect, ConnectedProps, MapStateToProps } from "react-redux";
import { getActiveColorScheme } from "../../utils/utils";
import { getActiveSite } from "../../selectors/sites";
import * as ClassNames from "classnames";
import ModuleHeadings from "../ModuleHeadings";
import { injectScript } from "../../actions/LoadStates";
import ModuleWithHeadings from "../ModuleWithHeadings";
import LazyloadWrapper from "../LazyloadWrapper";

const processScriptsAndGetHTML = (
  html: string,
  injectScript: (url: string) => Promise<void>
): string => {
  const div = document.createElement("div");
  div.innerHTML = html;

  const scriptElements = Array.from(div.querySelectorAll("script"));

  if (!scriptElements.length) return html;

  scriptElements.forEach((script) => {
    const scriptText = script.innerText;
    const scriptSrc = script.src;

    scriptSrc && injectScript(scriptSrc);

    if (scriptText) {
      const blob = new Blob([scriptText], { type: "text/javascript" });
      // TODO: revoke object urls to prevent memory leaks?
      const blobScriptUrl = URL.createObjectURL(blob);
      injectScript(blobScriptUrl);
    }

    script.parentNode?.removeChild(script);
  });

  return div.innerHTML;
};

type Props = BaseModuleProps<HTMLModuleSettings>;

interface StateProps {
  scheme: ColorScheme;
}

type ReduxProps = ConnectedProps<typeof connector>;

const HTMLModule: FunctionComponent<Props & ReduxProps> = ({
  scheme,
  translatedModule: {
    id,
    settings: { textAlign },
    translation: {
      settings: { title, subtitle, html },
    },
  },
  isFirstOnPage,
  injectScript,
}) => {
  const [isLazyloaded, setIsLazyloaded] = useState(false);
  const [processedHTML, setProcessedHTML] = useState("");
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    html &&
      isLazyloaded &&
      setProcessedHTML(processScriptsAndGetHTML(html, injectScript));
  }, [html, isLazyloaded]);

  return (
    <ModuleWithHeadings
      title={title}
      subtitle={subtitle}
      id={id}
      className="HTMLModule"
      style={{
        background: scheme.main.background,
        color: scheme.main.text,
      }}
    >
      <div className="Module__Wrapper">
        <ModuleHeadings
          scheme={scheme}
          isFirstOnPage={isFirstOnPage}
          textAlign={textAlign}
          title={title}
          subtitle={subtitle}
        />
      </div>
      <LazyloadWrapper onLoad={setIsLazyloaded}>
        <div
          ref={contentRef}
          className={ClassNames("Module__Wrapper HTMLModule__Content", {
            "HTMLModule__Content--empty": !html,
          })}
          dangerouslySetInnerHTML={{
            __html: processedHTML,
          }}
        />
      </LazyloadWrapper>
    </ModuleWithHeadings>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { colorSchemes, sites },
  { translatedModule }
): StateProps => ({
  scheme: getActiveColorScheme(
    colorSchemes,
    getActiveSite(sites),
    translatedModule
  ),
});

const mapDispatchToProps = {
  injectScript,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(HTMLModule);
