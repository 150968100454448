import {
  StoreState,
  TranslatedPartnerLogo,
  Language,
  TranslatedPartner,
  FooterModuleSettings,
  Module,
  LogoCategory,
} from "../types";
import { getTranslations, isDefined } from "../utils/utils";

export const getTranslatedPartner = (
  state: StoreState,
  {
    partnerId,
    languageId,
    includeLogos = false,
  }: {
    partnerId: string;
    languageId: Language;
    includeLogos: boolean;
  }
): TranslatedPartner | undefined => {
  const partner = state.partners[partnerId];
  if (!partner) return;

  const partnerTranslation = getTranslations(languageId, partner.translations);
  if (!partnerTranslation) return;

  const translatedPartner: TranslatedPartner = {
    id: partner.id,
    logos: includeLogos
      ? getTranslatedPartnerLogos(state, {
          logoIds: partner.logos,
          languageId,
        })
      : [],
    translation: partnerTranslation,
  };

  return translatedPartner;
};

export const getTranslatedPartners = (
  state: StoreState,
  {
    partnerIds,
    languageId,
  }: {
    partnerIds?: string[];
    languageId: Language;
  }
): TranslatedPartner[] => {
  return (partnerIds ? partnerIds : Object.keys(state.partners))
    .map((partnerId) => {
      return getTranslatedPartner(state, {
        partnerId,
        languageId,
        includeLogos: true,
      });
    })
    .filter(isDefined);
};

export const getTranslatedPartnerLogo = (
  state: StoreState,
  {
    logoId,
    languageId,
  }: {
    logoId: string;
    languageId: Language;
  }
): TranslatedPartnerLogo | undefined => {
  const logo = state.logos[logoId];
  if (!logo) return;

  const partner = state.partners[logo.partnerId];
  if (!partner) return;

  const translatedPartner = getTranslatedPartner(state, {
    partnerId: logo.partnerId,
    languageId,
    includeLogos: false,
  });

  const logoTranslation = getTranslations(languageId, logo.translations);

  if (!translatedPartner || !logoTranslation) return;

  return {
    id: logoId,
    background: logo.background,
    translation: logoTranslation,
    partner: translatedPartner,
  };
};

export const getTranslatedPartnerLogos = (
  state: StoreState,
  {
    logoIds,
    languageId,
  }: {
    logoIds: string[];
    languageId: Language;
  }
): TranslatedPartnerLogo[] => {
  return logoIds
    .map((logoId) => {
      const partnerLogo = getTranslatedPartnerLogo(state, {
        logoId,
        languageId,
      });

      return partnerLogo;
    })
    .filter(isDefined);
};

export const getSelectedLogoIds = (
  state: StoreState,
  {
    logoCategory,
    moduleId,
  }: {
    moduleId: string;
    logoCategory: LogoCategory;
  }
): string[] => {
  const currentModule = state.modules.byId[moduleId];
  const logoIds =
    (currentModule as Module<FooterModuleSettings>).settings.logoCategories[
      logoCategory
    ] || [];

  return logoIds;
};

export const getSouthTyrolLogo = (state: StoreState, languageId: Language) =>
  getTranslatedPartnerLogo(state, {
    languageId,
    logoId: "9eb9e147-0474-48e9-97f5-06ea20c59ade",
  });
