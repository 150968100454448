import * as React from "react";
import { FunctionComponent, useRef } from "react";
import {
  ContainerQueries,
  MultiBoxLayoutVariant,
  MultiBoxDisplayType,
  ColorScheme,
  MultiBoxItemData,
  AspectRatio,
  TitleAndSubtitleTextAlign,
  Language,
  RichEditorState,
} from "../types";
import MultiBoxItem from "./MultiBoxItem";
import * as ClassNames from "classnames";
import MultiBoxTile from "./MultiBoxTile";
import Slider from "./Slider";
import MultiBoxSliderArrow from "./MultiBoxSliderArrow";
import ModuleHeadings from "./ModuleHeadings";
import ReactSlick from "react-slick";

const calcSlidesPerView = (
  queries: ContainerQueries | undefined,
  layoutVariant: MultiBoxLayoutVariant,
  maxColumnsCount: number
): number => {
  if (layoutVariant === "horizontal") {
    return queries && queries["Query--large"] ? maxColumnsCount : 1;
  }

  if (!queries) return maxColumnsCount;
  if (queries["Query--xxlarge"]) return Math.min(4, maxColumnsCount);
  if (queries["Query--large"]) return Math.min(3, maxColumnsCount);
  if (queries["Query--medium"]) return Math.min(2, maxColumnsCount);

  return 1;
};

const isTileLayout = (layoutVariant: MultiBoxLayoutVariant): boolean => {
  return layoutVariant === "tiles";
};

interface Props {
  layoutVariant: MultiBoxLayoutVariant;
  scheme: ColorScheme;
  displayType: MultiBoxDisplayType;
  imagesAspectRatio: AspectRatio;
  isLayoutPreview: boolean;
  queries?: ContainerQueries;
  itemsData: MultiBoxItemData[];
  isPreview: boolean;
  isFirstOnPage: boolean;
  contentOpenOnIndex?: number;
  activeId?: string | number;
  title: string;
  subtitle: string;
  textAlign: TitleAndSubtitleTextAlign;
  maxColumnsCount: number;
  placeholdersShown: boolean;
  languageId: Language;
  collapsedLinesCount: number | undefined;
  richEditor: RichEditorState;
}

export const getMaxMultiBoxesColumns = <
  LayoutVariant extends MultiBoxLayoutVariant
>(
  layoutVariant: LayoutVariant
): number => {
  switch (layoutVariant) {
    case "vertical":
      return 4;
    case "horizontal":
      return 2;
    case "tiles":
      return 4;
  }

  return 0;
};

const MultiBox: FunctionComponent<Props> = ({
  itemsData,
  layoutVariant,
  scheme,
  displayType: settingsDisplayType,
  queries,
  isPreview,
  imagesAspectRatio,
  isLayoutPreview,
  title,
  subtitle,
  textAlign,
  isFirstOnPage,
  maxColumnsCount,
  placeholdersShown,
  languageId,
  collapsedLinesCount,
  richEditor,
}) => {
  const sliderRef = useRef<ReactSlick | null>(null);
  const goToPrevSlide = () => sliderRef.current?.slickPrev();
  const goToNextSlide = () => sliderRef.current?.slickNext();

  const slidesPerView = calcSlidesPerView(
    queries,
    layoutVariant,
    maxColumnsCount
  );
  const displayType: MultiBoxDisplayType =
    itemsData.length > slidesPerView && settingsDisplayType === "slider"
      ? "slider"
      : "list";
  const hasSlider = displayType === "slider";

  // Only show one row of items in the preview to prevent content jumping
  const itemsLimit = isLayoutPreview
    ? Math.min(getMaxMultiBoxesColumns(layoutVariant), maxColumnsCount)
    : undefined;

  const multiBoxItems = itemsData.slice(0, itemsLimit).map((itemData) => {
    const { id, title, picture, link, imageSettings } = itemData;

    if (isTileLayout(layoutVariant)) {
      return (
        <MultiBoxTile
          key={id}
          title={title}
          picture={picture}
          link={link}
          imagesAspectRatio={imagesAspectRatio}
          imageSettings={imageSettings}
          isPreview={isPreview}
          tilesCount={itemsData.length}
        />
      );
    }

    return (
      <MultiBoxItem
        key={id}
        itemData={itemData}
        scheme={scheme}
        imagesAspectRatio={imagesAspectRatio}
        isPreview={isPreview}
        isPlaceholderItem={placeholdersShown}
        languageId={languageId}
        collapsedLinesCount={collapsedLinesCount}
        isEditing={!!richEditor[id]}
      />
    );
  });

  return (
    <div
      className={ClassNames(
        "MultiBox",
        `MultiBox--${layoutVariant}`,
        `MultiBox--${displayType}`,
        `MultiBox--count-${itemsData.length}`,
        {
          "MultiBox--center": itemsData.length < 3,
          [`MultiBox--max-columns-count-${
            itemsLimit !== undefined ? itemsLimit : maxColumnsCount
          }`]: displayType === "list",
        }
      )}
      style={{
        background: scheme.main.separator,
      }}
    >
      <div className="MultiBox__Wrapper Module__Wrapper Module__Wrapper--small-padding">
        <div
          className={ClassNames("MultiBox__WrapperOuter", {
            "MultiBox__WrapperOuter--narrow":
              layoutVariant === "vertical" && maxColumnsCount === 2,
          })}
        >
          <ModuleHeadings
            scheme={scheme}
            isFirstOnPage={isFirstOnPage}
            textAlign={textAlign}
            title={title}
            subtitle={subtitle}
          />
          {hasSlider && (
            <>
              <MultiBoxSliderArrow
                scheme={scheme}
                type="prev"
                imagesAspectRatio={imagesAspectRatio}
                slidesPerView={slidesPerView}
                onClick={goToPrevSlide}
              />
              <MultiBoxSliderArrow
                scheme={scheme}
                type="next"
                imagesAspectRatio={imagesAspectRatio}
                slidesPerView={slidesPerView}
                onClick={goToNextSlide}
              />
              <div className="MultiBox__Wrapper__Inner">
                <Slider
                  sliderRef={sliderRef}
                  className="MultiBox__Slider"
                  enabled={!isLayoutPreview}
                  isPreview={isPreview}
                  settings={{
                    slidesToShow: slidesPerView,
                    spaceBetween: layoutVariant === "tiles" ? 0 : 24,
                    arrows: false,
                  }}
                >
                  {multiBoxItems}
                </Slider>
              </div>
            </>
          )}

          {!hasSlider && (
            <div className="MultiBox__Wrapper__Inner">{multiBoxItems}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MultiBox;
