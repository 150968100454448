import { Specials, Special, Language } from "../types";
import { AllActions } from "../actions";

const initialState: Specials = {};

const getSpecialsSuccess = (
  state: Specials,
  languageId: Language,
  specials: Special[]
): Specials => {
  return { ...state, [languageId]: specials };
};

const reducer = (state = initialState, action: AllActions): Specials => {
  switch (action.type) {
    case "GET_SPECIALS_SUCCESS":
      return getSpecialsSuccess(state, action.languageId, action.specials);

    default:
      return state;
  }
};

export default reducer;
