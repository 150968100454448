import { LinkSelect, LinkData } from "../types";
import { normalizeLink } from "../utils/utils";
import { AllActions } from "../actions";

const initialState: LinkSelect = {
  id: undefined,
  linkData: {
    url: null,
    pageId: null,
    languageId: null,
    moduleId: null,
    moduleType: null,
  },
  confirmed: false,
};

const setLinkSelect = (
  state: LinkSelect,
  id: string,
  linkData: LinkData,
  confirm: boolean
): LinkSelect => {
  const { url } = linkData;
  return {
    ...state,
    id,
    linkData: {
      ...linkData,
      url: normalizeLink(url),
    },
    confirmed: confirm,
  };
};

const reducer = (state = initialState, action: AllActions): LinkSelect => {
  switch (action.type) {
    case "SET_LINK_SELECT":
      const { confirm, id, linkData } = action;
      return setLinkSelect(state, id, linkData, confirm);

    case "PRESS_ESCAPE_KEY":
    case "RESET_LINK_SELECT":
      return initialState;

    default:
      return state;
  }
};

export default reducer;
