import * as React from "react";
import { FunctionComponent } from "react";
import SmartLink from "./SmartLink";
import { isPlaceholder } from "../selectors/pictures";
import { Picture } from "../types";

interface Props {
  isPreview: boolean;
  logo: Picture;
  mainPageUrl: string | undefined;
}

const HeaderLogo: FunctionComponent<Props> = ({
  isPreview,
  logo,
  mainPageUrl,
}) => {
  if (isPlaceholder(logo)) return null;
  return (
    <div className="HeaderModule__Logo">
      <SmartLink
        anchorAttrs={{
          href: mainPageUrl || "#",
        }}
        isPreview={isPreview}
      >
        <img src={logo.url} title={logo.title} alt={logo.title} />
      </SmartLink>
    </div>
  );
};

export default HeaderLogo;
