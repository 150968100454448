import { AllActions } from "../../actions";
import { SeoUrls, SeoUrlsById, SeoUrlRequest, SeoUrl } from "../../types";
import { urlToBase64 } from "../../utils/utils";

const initialState: SeoUrls = {
  byId: {},
  allItems: [],
  areLoading: false,
  isImporting: false,
};

const getSeoUrlsStart = (state: SeoUrls, keepExisting: boolean): SeoUrls => {
  // Delete existing urls if page 1 is requested,
  // which means a tab change in the SEO UI
  if (!keepExisting) {
    state = initialState;
  }
  return { ...state, areLoading: true };
};

const getSeoUrlsSuccess = (state: SeoUrls, urls: SeoUrl[]): SeoUrls => {
  const byId = urls.reduce<SeoUrlsById>((byId, url) => {
    const id = urlToBase64(url.url);
    byId[id] = url;
    return byId;
  }, {});

  const initialAllItems = state.allItems;

  // Append the newly recieved URLs and discard duplicates
  const allItems = urls.reduce<string[]>((accumulator, { url }) => {
    const key = urlToBase64(url);
    if (accumulator.indexOf(key) > -1) {
      return accumulator;
    }
    return [...accumulator, key];
  }, initialAllItems);

  return {
    ...state,
    byId: { ...state.byId, ...byId },
    allItems,
    areLoading: false,
  };
};

const patchSeoUrlStart = (
  state: SeoUrls,
  urlId: string,
  request: SeoUrlRequest
): SeoUrls => {
  const newUrl = { ...state.byId[urlId], ...request };

  return { ...state, byId: { ...state.byId, [urlId]: newUrl } };
};

const patchSeoUrlError = (
  state: SeoUrls,
  urlId: string,
  previousUrl: SeoUrl
): SeoUrls => {
  return { ...state, byId: { ...state.byId, [urlId]: previousUrl } };
};

const importUrls = (state: SeoUrls, isImporting: boolean): SeoUrls => {
  return { ...state, isImporting };
};

const reducer = (state = initialState, action: AllActions) => {
  switch (action.type) {
    case "GET_SEO_URLS_START":
      return getSeoUrlsStart(state, action.keepExisting);

    case "GET_SEO_URLS_SUCCESS":
      return getSeoUrlsSuccess(state, action.response);

    case "PATCH_SEO_URL_START":
      return patchSeoUrlStart(state, action.urlId, action.request);

    case "PATCH_SEO_URL_ERROR":
      return patchSeoUrlError(state, action.urlId, action.previousUrl);

    case "POST_SEO_URLS_START":
      return importUrls(state, true);

    case "POST_SEO_URLS_SUCCESS":
      return importUrls(state, false);

    case "POST_SEO_URLS_ERROR":
      return importUrls(state, false);

    case "POST_SEO_CRAWLER_SUCCESS":
      return initialState;

    default:
      return state;
  }
};

export default reducer;
