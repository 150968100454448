import { LegalNavState, LegalNavId } from "../types";
import { AllActions } from "../actions";

const initialState: LegalNavState = {
  id: undefined,
};

const toggleLegalNav = (
  state: LegalNavState,
  id: LegalNavId | undefined
): LegalNavState => {
  return { ...state, id };
};

const reducer = (state = initialState, action: AllActions) => {
  switch (action.type) {
    case "TOGGLE_LEGAL_NAV":
      return toggleLegalNav(state, action.legalNavId);

    default:
      return state;
  }
};

export default reducer;
