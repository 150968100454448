import de from "./de";
import it from "./it";
import en from "./en";
import fr from "./fr";

export default {
  de,
  it,
  en,
  fr,
};
