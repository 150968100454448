import * as React from "react";
import { FunctionComponent, useRef } from "react";
import { WindowState, BookingWidgetSettings, WidgetInstance } from "../types";
import * as ClassNames from "classnames";
import HgvWidgetContainer from "./HgvWidgetContainer";

interface Props {
  settings: BookingWidgetSettings;
  className?: string;
}

const BookingWidget: FunctionComponent<Props> = ({ className, settings }) => {
  const ref = useRef<HTMLDivElement>(null);
  const widgetInstanceRef = useRef<WidgetInstance>();

  const initWidget = () => {
    if (!ref.current) return;

    const { BookingSüdtirol } = (window as unknown) as WindowState;
    widgetInstanceRef.current = new BookingSüdtirol.Widgets.Booking(
      ref.current,
      settings
    );
  };

  return (
    <div
      className={ClassNames("Module__Wrapper", className)}
      style={{
        background: settings.colorScheme.main.background,
      }}
    >
      <HgvWidgetContainer
        widgetInstance={widgetInstanceRef.current}
        initWidget={initWidget}
        settings={settings}
        enableLazyload={true}
      >
        <div className="BookingModule__Container" ref={ref} />
      </HgvWidgetContainer>
    </div>
  );
};

export default BookingWidget;
