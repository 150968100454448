import * as React from "react";
import { FunctionComponent, MouseEvent } from "react";
import {
  MainMenuItem,
  PageState,
  Language,
  ColorScheme,
  TopHeaderVariant,
} from "../types";
import * as tinycolors from "tinycolor2";
import MainMenuList from "./MainMenuList";
import * as ClassNames from "classnames";
import { style } from "typestyle";

interface Props {
  menuItems: MainMenuItem[];
  isPreview: boolean;
  pages: PageState;
  languageId: Language;
  scheme: ColorScheme;
  onMenuItemClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
  showIcons: boolean;
  menuLevel?: number;
  topHeaderVariant: TopHeaderVariant;
  activePagePath: string[];
  hasHeaderImage: boolean;
  className?: string;
  absolutePositioning: boolean;
  fallbackLanguageId: Language | undefined;
}

const PriorityNavigation: FunctionComponent<Props> = ({
  isPreview,
  languageId,
  menuItems,
  pages,
  scheme,
  topHeaderVariant,
  activePagePath,
  hasHeaderImage,
  className,
  absolutePositioning,
  fallbackLanguageId,
}) => {
  const wrapperStyleClass = style({
    color: scheme.main.text,
    backgroundColor:
      topHeaderVariant === "below-header-nav"
        ? hasHeaderImage
          ? tinycolors(scheme.main.background).setAlpha(0.75).toRgbString()
          : tinycolors(scheme.main.background).darken(5).toRgbString()
        : undefined,
  });

  return (
    <div
      className={ClassNames("MainMenu__Wrapper", wrapperStyleClass, className, {
        "MainMenu__Wrapper--absolute": absolutePositioning,
      })}
    >
      <div className="MainMenu__Container">
        <MainMenuList
          isPreview={isPreview}
          languageId={languageId}
          menuItems={menuItems}
          pages={pages}
          scheme={scheme}
          showIcons={false}
          topHeaderVariant={topHeaderVariant}
          activePagePath={activePagePath}
          fallbackLanguageId={fallbackLanguageId}
          lastItemRightAlign={true}
          repeatFirstLevelItem={true}
        />
      </div>
    </div>
  );
};

export default PriorityNavigation;
