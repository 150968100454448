import { v4 as uuidv4 } from "uuid";
import {
  PictureCategory,
  Picture,
  Pictures,
  PictureQueryParams,
} from "../types";
import { appendQueryToUrl } from "../utils/utils";

interface SizeParams {
  width?: number;
  height?: number;
  mode?: 1 | 4;
}

export const getPictureWithSizedUrl = (sizeParams: SizeParams) => (
  picture: Picture
) => {
  const query: PictureQueryParams = {
    w: sizeParams.width,
    h: sizeParams.height,
    m: sizeParams.mode,
  };

  return isPlaceholder(picture)
    ? picture
    : {
        ...picture,
        url: appendQueryToUrl(picture.url, query),
      };
};

export const getPictureById = (
  pictures: Pictures,
  id: string | undefined,
  sizeParams: SizeParams = {}
) => {
  const defaultPicture = getDefaultPicture();
  const picture = id && pictures[id] ? pictures[id] : defaultPicture;

  return getPictureWithSizedUrl(sizeParams)(picture);
};

export const getPicturesById = (
  pictures: Pictures,
  ids: string[],
  sizeParams: SizeParams = {}
) =>
  ids.length
    ? // If there are images defined in the module
      ids.map((pictureId) => getPictureById(pictures, pictureId, sizeParams))
    : // If there are no images, get the placeholder picture
      [getDefaultPicture()];

const getDefaultPicture = (): Picture => ({
  id: uuidv4(),
  url: "/image.svg",
  title: "",
  time: 0,
  category: "placeholder",
  copyright: "",
});

export const isPlaceholder = ({ category }: { category: PictureCategory }) =>
  category === "placeholder";
