import * as React from "react";
import {
  Children,
  ReactNode,
  MouseEvent,
  useEffect,
  MutableRefObject,
} from "react";
import ReactSlick, { Settings as ReactSlickSettings } from "react-slick";
import { FunctionComponent } from "react";
import Icon from "./Icon";
import * as ClassNames from "classnames";

interface ArrowStyle {
  fill: string;
  backgroundColor: string;
}

interface Props {
  settings?: ReactSlickSettings;
  enabled?: boolean;
  className?: string;
  sliderRef: MutableRefObject<ReactSlick | null>;
  showNavigationButtons?: boolean;
  arrowStyle?: ArrowStyle;
  isPreview: boolean;
}

const addSlickSlideDiv = (child: ReactNode, slidesToShow: number) => (
  <div style={{ width: 100 / slidesToShow + "%" }} className="slick-slide">
    {child}
  </div>
);

type Arrow = FunctionComponent<{
  arrowStyle: ArrowStyle;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
}>;

const PrevArrow: Arrow = ({ arrowStyle, onClick }) => (
  <button
    onClick={onClick}
    className="ContentSlider__Control--prev ContentSlider__Control"
    style={arrowStyle}
  >
    <Icon className="ContentSlider__Control__Icon" glyph="arrow-left" />
  </button>
);

const NextArrow: Arrow = ({ arrowStyle, onClick }) => (
  <button
    onClick={onClick}
    className="ContentSlider__Control--next ContentSlider__Control"
    style={arrowStyle}
  >
    <Icon className="ContentSlider__Control__Icon" glyph="arrow-right" />
  </button>
);

const createDummySlider = (children: ReactNode, count: number = 1) =>
  Children.toArray(
    Children.map(children, (child) => addSlickSlideDiv(child, count))
  ).slice(0, count);

const isServer = typeof navigator === "undefined";

const Slider: FunctionComponent<Props> = ({
  settings = {},
  enabled = true,
  children,
  className,
  showNavigationButtons = false,
  arrowStyle = {
    backgroundColor: "transparent",
    fill: "#fff",
  },
  isPreview,
  sliderRef,
}) => {
  useEffect(() => {
    if (!settings.autoplay) {
      sliderRef.current?.slickPause();
      return;
    }

    sliderRef.current?.slickPause();
    sliderRef.current?.slickPlay();
  }, [settings.autoplay]);

  const { slidesToShow } = settings;

  if (!enabled || isServer) {
    return (
      <div className={ClassNames("slick-slider", className)}>
        <div className="slick-track">
          {createDummySlider(children, slidesToShow)}
        </div>
      </div>
    );
  }

  const arrows: ReactSlickSettings = showNavigationButtons
    ? {
        prevArrow: <PrevArrow arrowStyle={arrowStyle} />,
        nextArrow: <NextArrow arrowStyle={arrowStyle} />,
        arrows: true,
      }
    : { arrows: false };

  const { speed, autoplaySpeed, swipe } = settings;
  const mergedSettings: ReactSlickSettings = {
    ...arrows,
    ...settings,
    speed: speed ? speed : 750,
    autoplaySpeed: autoplaySpeed ? autoplaySpeed : 750,
    swipe: swipe === false ? false : !isPreview,
    pauseOnHover: false,
  };

  return (
    <ReactSlick className={className} ref={sliderRef} {...mergedSettings}>
      {children}
    </ReactSlick>
  );
};

export default Slider;
