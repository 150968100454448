import * as React from "react";
import { FunctionComponent, useState } from "react";
import { Language } from "../types";
import { getSupportedLanguage, insertScriptIntoHead } from "../utils/utils";
import Iframe from "./Iframe";
import * as ClassNames from "classnames";

interface Props {
  mapId: number;
  mapCode: string;
  languageId: Language;
  loadErrorMessage: string;
  noClick: boolean;
}

const SentresMap: FunctionComponent<Props> = ({
  mapId,
  mapCode,
  languageId,
  loadErrorMessage,
  noClick,
}) => {
  const [hasError, setHasError] = useState(false);

  const lang = getSupportedLanguage(languageId, ["de", "it", "en"]);

  const onIframeLoaded = async (iframe: HTMLIFrameElement) => {
    const iframeDocument = iframe.contentDocument;

    if (!iframeDocument) return;

    const style = iframeDocument.createElement("style");
    style.textContent = `
      .SentresModule__MapElement {
        width: 100% !important;
        height: 800px !important;
      }
    `;
    iframeDocument.head.appendChild(style);

    try {
      await insertScriptIntoHead(
        `https://www.alpinemap.it/embed/${mapCode}/${mapId}.js?locale=${lang}`,
        iframeDocument
      );
    } catch (error) {
      setHasError(true);
    }
  };

  if (hasError) {
    return (
      <div className="Module__Wrapper Module__Wrapper--default-padding">
        <p>{loadErrorMessage}</p>
      </div>
    );
  }

  return (
    <Iframe
      className={ClassNames("SentresModule__Iframe", {
        "SentresModule__Iframe--no-click": noClick,
      })}
      allowFullScreen={true}
      onIframeLoaded={onIframeLoaded}
    >
      <div
        className="SentresModule__MapElement"
        id={`sentres-map-${mapId}-${lang}`}
      ></div>
    </Iframe>
  );
};

export default SentresMap;
