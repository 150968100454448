import * as React from "react";
import { FunctionComponent, useState } from "react";
import {
  BaseModuleProps,
  SentresModuleSettings,
  StoreState,
  ColorScheme,
} from "../../types";
import { MapStateToProps, connect, ConnectedProps } from "react-redux";
import ModuleHeadings from "../ModuleHeadings";
import { getActiveSite } from "../../selectors/sites";
import {
  getActiveColorScheme,
  getTranslations,
  isIE11,
} from "../../utils/utils";
import ModuleWithHeadings from "../ModuleWithHeadings";
import { style } from "typestyle";
import SentresMap from "../SentresMap";
import LazyloadWrapper from "../LazyloadWrapper";

type Props = BaseModuleProps<SentresModuleSettings>;

interface StateProps {
  scheme: ColorScheme;
}

type ReduxProps = ConnectedProps<typeof connector>;

const translations = {
  de: {
    mapLink: "https://www.sentres.com/de/suedtirol/karte",
    map: "Südtirol Karte",
    description: "Südtirols #1 Tourenportal und",
    appLink: "https://www.sentres.com/de/suedtirol/sentres-suedtirol-app",
    mapError: "Beim Laden der Sentres Alpinemap ist ein Fehler aufgetreten.",
  },
  it: {
    mapLink: "https://www.sentres.com/it/alto-adige/mappa",
    map: "Mappa Alto Adige",
    description: "Il Portale Attività Outdoor #1 e",
    appLink: "https://www.sentres.com/it/alto-adige/app-alto-adige",
    mapError:
      "Si è verificato un errore durante il caricamento della Sentres Alpinemap.",
  },
  en: {
    mapLink: "https://www.sentres.com/en/south-tyrol/map",
    map: "South Tyrol Map",
    description: "The Outdoor Activity Portal #1 and",
    appLink: "https://www.sentres.com/en/south-tyrol/sentres-south-tyrol-app",
    mapError: "An error occurred while loading the Sentres Alpinemap.",
  },
};

const SentresModule: FunctionComponent<Props & ReduxProps> = ({
  translatedModule: {
    id: moduleId,
    translation: {
      languageId,
      settings: { title, subtitle },
    },
    settings: { textAlign, mapId, mapCode },
  },
  scheme,
  isFirstOnPage,
  isActive,
  isPreview,
}) => {
  const [isLazyloaded, setIsLazyloaded] = useState(false);

  const i18n = getTranslations(languageId, translations);
  const linkStyleClass = style({
    color: scheme.main.title,
  });

  // Don’t show the Sentres map on IE11 because it’s not compatible with it
  if (isIE11) return null;

  return (
    <ModuleWithHeadings
      title={title}
      subtitle={subtitle}
      id={moduleId}
      className="SentresModule"
      style={{
        background: scheme.main.background,
        color: scheme.main.text,
      }}
    >
      <div className="Module__Wrapper">
        <ModuleHeadings
          scheme={scheme}
          isFirstOnPage={isFirstOnPage}
          textAlign={textAlign}
          title={title}
          subtitle={subtitle}
        />
      </div>
      <div className="SentresModule__Content">
        <LazyloadWrapper onLoad={setIsLazyloaded}>
          {isLazyloaded && !!mapCode && mapId !== undefined ? (
            <div className="SentresModule__ContentInner">
              <SentresMap
                // This key unmounts and remounts the map on each ID and code change
                key={mapId + mapCode}
                noClick={!isActive && isPreview}
                languageId={languageId}
                mapCode={mapCode}
                mapId={mapId}
                loadErrorMessage={i18n.mapError}
              />
            </div>
          ) : (
            <div className="SentresModule__DefaultLogo"></div>
          )}
        </LazyloadWrapper>
        <div className="Module__Wrapper SentresModule__Caption">
          <a className={linkStyleClass} target="_blank" href={i18n.mapLink}>
            {i18n.map}
          </a>{" "}
          powered by{" "}
          <a
            className={linkStyleClass}
            target="_blank"
            href="https://www.sentres.com"
          >
            Sentres
          </a>{" "}
          – {i18n.description}{" "}
          <a className={linkStyleClass} target="_blank" href={i18n.appLink}>
            App
          </a>
        </div>
      </div>
    </ModuleWithHeadings>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { colorSchemes, sites },
  { translatedModule }
): StateProps => {
  const site = getActiveSite(sites);

  return {
    scheme: getActiveColorScheme(colorSchemes, site, translatedModule),
  };
};

const connector = connect(mapStateToProps);

export default connector(SentresModule);
