import * as React from "react";
import { FunctionComponent } from "react";
import {
  OfferingsModuleSettings,
  BaseModuleProps
} from "../../types";
import OfferingsModule from "./OfferingsModule";

interface Props extends BaseModuleProps<OfferingsModuleSettings> {}

const SpecialsModule: FunctionComponent<Props> = props => {
  const moduleId = props.translatedModule.id;
  return <OfferingsModule {...props} type="Specials" urlHashPrefix={`#${moduleId}-special-`} />
}

export default SpecialsModule;
