import * as React from "react";
import { FunctionComponent } from "react";
import { Accommodation } from "../types";
import Icon from "./Icon";
import * as ClassNames from "classnames";
import { getContactLinks } from "../utils/utils";

interface Props {
  accommodation: Accommodation | undefined;
  className?: string;
  iconsOnly: boolean;
}

const ContactNav: FunctionComponent<Props> = ({
  accommodation,
  className,
  iconsOnly,
}) => {
  const contactLinks = getContactLinks(accommodation);
  if (!contactLinks.length) return null;

  return (
    <ul
      className={ClassNames("ContactNav", className, {
        "ContactNav--icons-only": iconsOnly,
      })}
    >
      {contactLinks.map(({ href, children, icon }) => {
        return (
          <li key={href} className="ContactNav__ListItem">
            <a className="ContactNav__Item" href={href}>
              <Icon className="ContactNav__Icon" glyph={icon} />
              <span className="ContactNav__ItemText">{children}</span>
            </a>
          </li>
        );
      })}
    </ul>
  );
};

export default ContactNav;
