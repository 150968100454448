import * as React from "react";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import { connect, ConnectedProps, MapStateToProps } from "react-redux";
import * as ClassNames from "classnames";
import {
  StoreState,
  HeaderModuleSettings,
  ColorScheme,
  Language,
  Accommodation,
  PageState,
  ImagesModuleSettings,
  AnchorAttrs,
  TranslatedModule,
  BaseModuleProps,
  ActionLinkItem,
  MainMenuItem,
  Picture,
} from "../../types";
import {
  getActiveColorScheme,
  getMainPageUrl,
  getActivePagePath,
  buildBreadcrumb,
  getFallbackLanguage,
  getNearestHeaderImagesModule,
  getActionLinkList,
  checkIsOnePager,
  getTopLevelMainMenu,
  getInlineSize,
  getWideMenuBreakpoint,
  getCompactMenuMinWidth,
} from "../../utils/utils";
import { getActionModules } from "../../actions/Modules";
import { SiteLanguage } from "../../../server/types";
import { getActiveSite } from "../../selectors/sites";
import HeaderWithSplitMenu from "../HeaderWithSplitMenu";
import HeaderWithOriginalLayouts from "../HeaderWithOriginalLayouts";
import HeaderWithFullOverlayMenu from "../HeaderWithFullOverlayMenu";
import HeaderWithLeftOverlayNav from "../HeaderWithLeftOverlayNav";
import Breadcrumb from "../Breadcrumb";
import { style } from "typestyle";
import { getPictureById } from "../../selectors/pictures";
import CompactHeaderBar from "../CompactHeaderBar";
import FixedHeader from "../FixedHeader";
import HeaderSidebar from "../HeaderSidebar";
import FullHeaderWithLogo from "../FullHeaderWithLogo";
import { ResizeObserver } from "@juggle/resize-observer";
import { ResizeObserverCallback } from "@juggle/resize-observer/lib/ResizeObserverCallback";
import { throttle } from "throttle-debounce";

interface Props extends BaseModuleProps<HeaderModuleSettings> {}

interface StateProps {
  mainPageUrl: string | undefined;
  accommodation: Accommodation | undefined;
  scheme: ColorScheme;
  siteLanguages: SiteLanguage[];
  pages: PageState;
  imagesModule: TranslatedModule<ImagesModuleSettings> | undefined;
  activePagePath: string[];
  breadcrumb: AnchorAttrs[];
  fallbackLanguageId: Language | undefined;
  actionLinks: ActionLinkItem[];
  isOnePager: boolean;
  menuItems: MainMenuItem[];
  smallLogo: Picture;
  wideMenuBreakpoint: number;
  compactMenuBreakpoint: number;
}

type ReduxProps = ConnectedProps<typeof connector>;

const HeaderModule: FunctionComponent<Props & ReduxProps> = ({
  scheme,
  isPreview,
  imagesModule,
  activePagePath,
  pageId,
  queries,
  translatedModule,
  translatedModule: {
    siteId,
    settings: {
      showBreadcrumb,
      sticky,
      menuVariant,
      showSouthTyrolLogo,
      layoutVariant,
      topHeaderVariant,
    },
    translation: { languageId },
  },
  accommodation,
  fallbackLanguageId,
  mainPageUrl,
  breadcrumb,
  activeModuleId,
  getActionModules,
  actionLinks,
  isOnePager,
  menuItems,
  pages,
  smallLogo,
  wideMenuBreakpoint,
  compactMenuBreakpoint,
}) => {
  const [wideMenuFits, setWideMenuFits] = useState(false);
  const [compactMenuFits, setCompactMenuFits] = useState(false);
  const wideIntersectionRef = useRef<HTMLDivElement>(null);
  const narrowIntersectionRef = useRef<HTMLElement | null>(null);
  const observer = useRef<ResizeObserver>();

  useEffect(() => {
    getActionModules(siteId);

    return () => {
      observer.current?.disconnect();
    };
  }, []);

  useEffect(() => {
    observer.current?.disconnect();

    const onResize: ResizeObserverCallback = (entries) => {
      const width = getInlineSize(entries);
      if (width === undefined) return;
      setWideMenuFits(width >= wideMenuBreakpoint);
      setCompactMenuFits(width >= compactMenuBreakpoint);
    };

    const onResizeThrottled = throttle(200, onResize);
    observer.current = new ResizeObserver(onResizeThrottled);
    // TODO: better get the element the “React way” with refs
    const element =
      document.querySelector(".Page__Preview__Inner") ?? document.body;
    element && observer.current.observe(element);
  }, [wideMenuBreakpoint]);

  const showCompactHeader =
    (!imagesModule || !imagesModule.pageId) &&
    layoutVariant !== "all-in-header" &&
    layoutVariant !== "fixed-sidebar";

  const showFixedHeader = layoutVariant !== "fixed-sidebar";

  return (
    <header
      className={ClassNames(
        "HeaderModule",
        "Module",
        {
          [`HeaderModule--menu-position-${menuVariant}`]:
            layoutVariant === "split-menu",
          "HeaderModule--wide-menu-fits": wideMenuFits,
          "HeaderModule--compact-menu-fits": compactMenuFits,
        },
        style({
          background: scheme.main.background,
          color: scheme.main.text,
        })
      )}
    >
      {showFixedHeader && (
        <FixedHeader
          topHeaderVariant={topHeaderVariant}
          actionLinks={actionLinks}
          activePagePath={activePagePath}
          hasHeaderImage={!!imagesModule}
          isPreview={isPreview}
          languageId={languageId}
          pages={pages}
          topLevelMainMenuItems={menuItems}
          scheme={scheme}
          stickyEnabled={sticky}
          fallbackLanguageId={fallbackLanguageId}
          pageId={pageId}
          mainPageUrl={mainPageUrl}
          smallLogo={smallLogo}
          intersectionTriggerEl={
            wideMenuFits
              ? wideIntersectionRef.current
              : narrowIntersectionRef.current
          }
        />
      )}

      {/*
        Header for narrow viewport. Always rendered,
        hidden or displayed via CSS to prevent content jumping.
      */}
      <HeaderWithOriginalLayouts
        className="HeaderModule__Inner--narrow HeaderModule__Inner--top-hamburger-nav"
        scheme={scheme}
        isPreview={isPreview}
        queries={{
          ...queries,
          "Query--large": false,
          "Query--xlarge": false,
          "Query--xxlarge": false,
        }}
        accommodation={accommodation}
        fallbackLanguageId={fallbackLanguageId}
        languageId={languageId}
        topHeaderVariant="hamburger-nav"
        imagesModule={imagesModule}
        mainPageUrl={mainPageUrl}
        activePagePath={activePagePath}
        pageId={pageId}
        activeModuleId={activeModuleId}
        intersectionTriggerRef={narrowIntersectionRef}
      />

      {layoutVariant === "all-in-header" && (
        <HeaderWithOriginalLayouts
          className={`HeaderModule__Inner--wide HeaderModule__Inner--top-${topHeaderVariant}`}
          scheme={scheme}
          isPreview={isPreview}
          queries={queries}
          accommodation={accommodation}
          fallbackLanguageId={fallbackLanguageId}
          languageId={languageId}
          topHeaderVariant={topHeaderVariant}
          imagesModule={imagesModule}
          mainPageUrl={mainPageUrl}
          activePagePath={activePagePath}
          pageId={pageId}
          activeModuleId={activeModuleId}
          intersectionTriggerRef={wideIntersectionRef}
        />
      )}

      {showCompactHeader && (
        <div
          ref={wideIntersectionRef}
          className="HeaderModule__Inner HeaderModule__Inner--wide"
        >
          <CompactHeaderBar
            actionLinks={actionLinks}
            activePagePath={activePagePath}
            hasHeaderImage={false}
            isPreview={isPreview}
            languageId={languageId}
            pages={pages}
            topLevelMainMenuItems={menuItems}
            scheme={scheme}
            fallbackLanguageId={fallbackLanguageId}
            pageId={pageId}
            mainPageUrl={mainPageUrl}
            smallLogo={smallLogo}
          />
        </div>
      )}

      {layoutVariant === "fixed-sidebar" && queries["Query--large"] && (
        <HeaderSidebar
          scheme={scheme}
          languageId={languageId}
          isPreview={isPreview}
          pageId={pageId}
          actionLinks={actionLinks}
        />
      )}

      {imagesModule && imagesModule.pageId && (
        <>
          {layoutVariant === "split-menu" && (
            <HeaderWithSplitMenu
              imagesModule={imagesModule}
              isPreview={isPreview}
              pageId={pageId}
              queries={queries}
              scheme={scheme}
              languageId={languageId}
              activePagePath={activePagePath}
              accommodation={accommodation}
              variant={menuVariant}
              actionLinks={actionLinks}
              intersectionTriggerRef={wideIntersectionRef}
              showSouthTyrolLogo={showSouthTyrolLogo}
            />
          )}

          {layoutVariant === "fixed-sidebar" && (
            <FullHeaderWithLogo
              imagesModule={imagesModule}
              isPreview={isPreview}
              languageId={languageId}
              pageId={pageId}
              queries={queries}
              scheme={scheme}
              showSouthTyrolLogo={showSouthTyrolLogo}
            />
          )}

          {layoutVariant === "full-overlay-nav" && (
            <HeaderWithFullOverlayMenu
              imagesModule={imagesModule}
              queries={queries}
              scheme={scheme}
              isPreview={isPreview}
              pageId={pageId}
              mainPageUrl={mainPageUrl}
              languageId={languageId}
              actionLinks={actionLinks}
              intersectionTriggerRef={wideIntersectionRef}
            />
          )}

          {layoutVariant === "left-overlay-nav" && (
            <HeaderWithLeftOverlayNav
              imagesModule={imagesModule}
              queries={queries}
              scheme={scheme}
              isPreview={isPreview}
              pageId={pageId}
              mainPageUrl={mainPageUrl}
              languageId={languageId}
              actionLinks={actionLinks}
              translatedModule={translatedModule}
              intersectionTriggerRef={wideIntersectionRef}
            />
          )}
        </>
      )}

      {!isOnePager && showBreadcrumb && (
        <Breadcrumb
          links={breadcrumb}
          isPreview={isPreview}
          languageId={languageId}
        />
      )}
    </header>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  {
    sites,
    accommodation,
    pages,
    modules,
    colorSchemes,
    mediaLibrary: { pictures, logoId },
  },
  {
    isPreview,
    pageId,
    translatedModule: {
      translation: { languageId },
      settings: { topHeaderVariant, layoutVariant },
      colorSchemeId,
    },
  }
): StateProps => {
  const site = getActiveSite(sites);
  const fallbackLanguageId = getFallbackLanguage(site, languageId);
  const imagesModule = getNearestHeaderImagesModule({
    pages,
    modules,
    currentPageId: pageId,
    languageId,
  });

  const menuItems = getTopLevelMainMenu({
    pages,
    languageId,
    isPreview,
    fallbackLanguageId,
  });

  const actionLinks = getActionLinkList({
    isPreview,
    languageId,
    modules,
    pages,
    site,
  });

  return {
    mainPageUrl: getMainPageUrl(pages, languageId, isPreview),
    accommodation: accommodation[languageId],
    scheme: getActiveColorScheme(colorSchemes, site, { colorSchemeId }),
    siteLanguages: site.languages,
    pages,
    breadcrumb: buildBreadcrumb(pages, site, pageId, languageId, isPreview),
    imagesModule,
    activePagePath: getActivePagePath(pageId, pages.byParentId),
    fallbackLanguageId,
    actionLinks,
    isOnePager: checkIsOnePager(site),
    menuItems,
    smallLogo: getPictureById(pictures, logoId, { height: 50 }),
    wideMenuBreakpoint: getWideMenuBreakpoint({
      actionLinks,
      topHeaderVariant,
      layoutVariant,
      menuItems,
      hasHeaderImage: !!imagesModule,
    }),
    compactMenuBreakpoint: getCompactMenuMinWidth(menuItems, actionLinks),
  };
};

const mapDispatchToProps = {
  getActionModules,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(HeaderModule);
