import { FunctionComponent, useRef, useEffect } from "react";
import * as React from "react";
import { UsercentricsConsentName, Language } from "../types";
import ModuleConsentBox from "./ModuleConsentBox";
import {
  registerUserCentricsListener,
  getUsercentricsConsent,
} from "../utils/usercentrics";

interface Props {
  onAccepted: (isAccepted: true) => void;
  consentName: UsercentricsConsentName | undefined;
  languageId: Language;
  isPreview: boolean;
}

const UsercentricsCMPWrapper: FunctionComponent<Props> = ({
  onAccepted,
  children,
  consentName,
  languageId,
  isPreview,
}) => {
  const usercentricsRef = useRef<
    ReturnType<typeof registerUserCentricsListener>
  >();
  const isConsented =
    isPreview ||
    consentName === undefined ||
    getUsercentricsConsent(consentName);

  useEffect(() => {
    if (!consentName) return;

    const isConsented = isPreview || getUsercentricsConsent(consentName);
    isConsented && onAccepted(true);
    usercentricsRef.current = !isConsented
      ? registerUserCentricsListener(consentName, () => onAccepted(true))
      : undefined;

    return () => {
      usercentricsRef.current?.unmount();
    };
  }, []);

  return (
    <>
      {isConsented && children}
      {!isConsented && consentName && (
        <ModuleConsentBox consentName={consentName} languageId={languageId} />
      )}
    </>
  );
};

export default UsercentricsCMPWrapper;
