import * as React from "react";
import { FunctionComponent } from "react";
import FullMenuButton from "./FullMenuButton";
import { Language, MainMenuItem, PageState, ActionLinkItem } from "../types";
import MainMenuListFullOverlayNav from "./MainMenuListFullOverlayNav";
import * as ClassNames from "classnames";
import ActionModuleNavigation from "./ActionModuleNavigation";
import { RemoveScroll } from "react-remove-scroll";

interface Props {
  languageId: Language;
  onClose: () => void;
  isPreview: boolean;
  pages: PageState;
  activePagePath: string[];
  fallbackLanguageId: Language | undefined;
  menuItems: MainMenuItem[];
  isMultiLevel: boolean;
  actionLinks: ActionLinkItem[];
}

const FullHeaderMenu: FunctionComponent<Props> = ({
  languageId,
  onClose,
  isPreview,
  activePagePath,
  fallbackLanguageId,
  menuItems,
  pages,
  isMultiLevel,
  actionLinks,
}) => (
  <RemoveScroll>
    <div className="FullHeaderMenu">
      <div className="FullHeaderMenu__Inner">
        <div className="FullHeaderMenu__Container">
          <FullMenuButton
            isOpen={true}
            languageId={languageId}
            onClick={onClose}
          />
          <div
            className={ClassNames("FullHeaderMenu__MenuWrapper", {
              "FullHeaderMenu__MenuWrapper--multi-level": isMultiLevel,
            })}
          >
            <MainMenuListFullOverlayNav
              isPreview={isPreview}
              languageId={languageId}
              onMenuItemClick={onClose}
              menuItems={menuItems}
              pages={pages}
              activePagePath={activePagePath}
              fallbackLanguageId={fallbackLanguageId}
              menuLevel={0}
            />
          </div>
        </div>
        <ActionModuleNavigation
          actionLinks={actionLinks}
          isPreview={isPreview}
          scheme={undefined}
          onItemClick={onClose}
        />
      </div>
    </div>
  </RemoveScroll>
);

export default FullHeaderMenu;
