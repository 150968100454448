import * as React from "react";
import { FunctionComponent } from "react";
import ConditionalLink from "./ConditionalLink";
import {
  AspectRatio,
  Picture,
  AnchorAttrs,
  ImageSettingsGlobalCropOnly,
} from "../types";
import ImageWithCrop from "./ImageWithCrop";

interface Props {
  title: string;
  link?: AnchorAttrs;
  imagesAspectRatio: AspectRatio;
  picture: Picture;
  imageSettings: ImageSettingsGlobalCropOnly | undefined;
  isPreview: boolean;
  tilesCount: number;
}

const MultiBoxTile: FunctionComponent<Props> = ({
  title,
  link = {},
  picture,
  imagesAspectRatio,
  imageSettings,
  isPreview,
  tilesCount,
}) => {
  return (
    <ConditionalLink
      className="MultiBox__Tile"
      anchorAttrs={link}
      isPreview={isPreview}
      divIfNoLink
    >
      <ImageWithCrop
        aspectRatio={imagesAspectRatio}
        width={1280}
        picture={picture}
        imageSettings={imageSettings}
        sizes={tilesCount > 1 ? "50vw" : "100vw"}
      />
      <div className="MultiBox__Tile__TitleOuter">
        <div className="MultiBox__Tile__Title">{title}</div>
      </div>
    </ConditionalLink>
  );
};

export default MultiBoxTile;
